import {CustomerNotificationPayloadFormatCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerNotificationPayloadFormatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerNotificationPayloadFormatCd ) {
      this._values.push(CustomerNotificationPayloadFormatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerNotificationPayloadFormatCd {

    for ( const obj in CustomerNotificationPayloadFormatCd ) {
      if (CustomerNotificationPayloadFormatCd[obj] === value){
        return CustomerNotificationPayloadFormatCd[obj] as CustomerNotificationPayloadFormatCd;
      }
    }
  }
}

const CustomerNotificationPayloadFormatCdHelper = new CustomerNotificationPayloadFormatCdHelperEnumHelperClass();
export default CustomerNotificationPayloadFormatCdHelper;
