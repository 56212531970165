import {NextBestActionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class NextBestActionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NextBestActionTypeCd ) {
      this._values.push(NextBestActionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NextBestActionTypeCd {

    for ( const obj in NextBestActionTypeCd ) {
      if (NextBestActionTypeCd[obj] === value){
        return NextBestActionTypeCd[obj] as NextBestActionTypeCd;
      }
    }
  }
}

const NextBestActionTypeCdHelper = new NextBestActionTypeCdHelperEnumHelperClass();
export default NextBestActionTypeCdHelper;
