import {CustomerNotificationBroadcastCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerNotificationBroadcastCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerNotificationBroadcastCd ) {
      this._values.push(CustomerNotificationBroadcastCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerNotificationBroadcastCd {

    for ( const obj in CustomerNotificationBroadcastCd ) {
      if (CustomerNotificationBroadcastCd[obj] === value){
        return CustomerNotificationBroadcastCd[obj] as CustomerNotificationBroadcastCd;
      }
    }
  }
}

const CustomerNotificationBroadcastCdHelper = new CustomerNotificationBroadcastCdHelperEnumHelperClass();
export default CustomerNotificationBroadcastCdHelper;
