
export enum ProInUseCd {
	ELECTRONIC = 'Electronic',
	GENERAL_CLAIMS_BUSINESS = 'GeneralClaimsBusiness',
	LABEL = 'Label',
	LEGACY = 'Legacy',
	MOVER_SHIPMENT = 'MoverShipment',
	NOT_USED = 'NotUsed',
	WEB = 'Web'}

