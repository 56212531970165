import {CustOperationsPickupTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustOperationsPickupTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustOperationsPickupTypeCd ) {
      this._values.push(CustOperationsPickupTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustOperationsPickupTypeCd {

    for ( const obj in CustOperationsPickupTypeCd ) {
      if (CustOperationsPickupTypeCd[obj] === value){
        return CustOperationsPickupTypeCd[obj] as CustOperationsPickupTypeCd;
      }
    }
  }
}

const CustOperationsPickupTypeCdHelper = new CustOperationsPickupTypeCdHelperEnumHelperClass();
export default CustOperationsPickupTypeCdHelper;
