
export enum EnsembleExecutionStatusCd {
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	KILLED = 'Killed',
	LOCK_FAILED = 'LockFailed',
	LOCK_WAIT = 'LockWait',
	LOCK_WAIT_FAILED = 'LockWaitFailed',
	NOT_IMPLEMENTED = 'NotImplemented',
	NOT_STARTED = 'NotStarted',
	PAUSED = 'Paused',
	STARTED = 'Started',
	SUBMITTED = 'Submitted',
	SUSPENDED = 'Suspended',
	THREAD_WAIT = 'ThreadWait',
	THREAD_WAIT_FAILED = 'ThreadWaitFailed'}

