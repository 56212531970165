import {BondStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class BondStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BondStatusCd ) {
      this._values.push(BondStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BondStatusCd {

    for ( const obj in BondStatusCd ) {
      if (BondStatusCd[obj] === value){
        return BondStatusCd[obj] as BondStatusCd;
      }
    }
  }
}

const BondStatusCdHelper = new BondStatusCdHelperEnumHelperClass();
export default BondStatusCdHelper;
