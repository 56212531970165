import {LabelShippingStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class LabelShippingStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LabelShippingStatusCd ) {
      this._values.push(LabelShippingStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LabelShippingStatusCd {

    for ( const obj in LabelShippingStatusCd ) {
      if (LabelShippingStatusCd[obj] === value){
        return LabelShippingStatusCd[obj] as LabelShippingStatusCd;
      }
    }
  }
}

const LabelShippingStatusCdHelper = new LabelShippingStatusCdHelperEnumHelperClass();
export default LabelShippingStatusCdHelper;
