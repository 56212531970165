import {PickupRequestReinstateTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupRequestReinstateTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupRequestReinstateTypeCd ) {
      this._values.push(PickupRequestReinstateTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupRequestReinstateTypeCd {

    for ( const obj in PickupRequestReinstateTypeCd ) {
      if (PickupRequestReinstateTypeCd[obj] === value){
        return PickupRequestReinstateTypeCd[obj] as PickupRequestReinstateTypeCd;
      }
    }
  }
}

const PickupRequestReinstateTypeCdHelper = new PickupRequestReinstateTypeCdHelperEnumHelperClass();
export default PickupRequestReinstateTypeCdHelper;
