
export enum CorrectionQueueNameCd {
	AC_CORR = 'AC/ CORR',
	CMX = 'CMX',
	CONFIRM = 'CONFIRM',
	CONTENT = 'CONTENT',
	CWYAUDLVL_1 = 'CWYAUDLVL1',
	CWYAUDLVL_2 = 'CWYAUDLVL2',
	CWYAUDLVL_3 = 'CWYAUDLVL3',
	CWYAUDLVL_4 = 'CWYAUDLVL4',
	CWYMASS = 'CWYMASS',
	DRVR_WAIT = 'DRVR WAIT',
	FIA = 'FIA',
	FIA_599 = 'FIA-599',
	GEN_CORR = 'GEN CORR',
	HOLD = 'HOLD',
	MANBOTHBIL = 'MANBOTHBIL',
	OFFSHORE = 'OFFSHORE',
	PRIORITY = 'PRIORITY',
	SICWRTEOFF = 'SICWRTEOFF',
	WRNTY = 'WRNTY'}
