import {CustomerHoldCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerHoldCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerHoldCategoryCd ) {
      this._values.push(CustomerHoldCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerHoldCategoryCd {

    for ( const obj in CustomerHoldCategoryCd ) {
      if (CustomerHoldCategoryCd[obj] === value){
        return CustomerHoldCategoryCd[obj] as CustomerHoldCategoryCd;
      }
    }
  }
}

const CustomerHoldCategoryCdHelper = new CustomerHoldCategoryCdHelperEnumHelperClass();
export default CustomerHoldCategoryCdHelper;
