import {RateQuoteAccessorialCd} from '../';
import {EnumHelper} from './enum-helper';

export class RateQuoteAccessorialCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RateQuoteAccessorialCd ) {
      this._values.push(RateQuoteAccessorialCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RateQuoteAccessorialCd {

    for ( const obj in RateQuoteAccessorialCd ) {
      if (RateQuoteAccessorialCd[obj] === value){
        return RateQuoteAccessorialCd[obj] as RateQuoteAccessorialCd;
      }
    }
  }
}

const RateQuoteAccessorialCdHelper = new RateQuoteAccessorialCdHelperEnumHelperClass();
export default RateQuoteAccessorialCdHelper;
