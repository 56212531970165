
export enum InvoiceHoldReasonCd {
	AC_PENDING_CORR = 'ACPendingCorr',
	FAILED_GUARANTEE = 'FailedGuarantee',
	HOLD = 'Hold',
	HOLD_FOR_X_DAYS = 'HoldForXDays',
	MISSING_DR = 'MissingDR',
	MISSING_RFC = 'MissingRFC',
	MX_DOOR_TO_DOOR = 'MXDoorToDoor',
	NOT_DELIVERED = 'NotDelivered',
	PENDING_CORR = 'PendingCorr',
	SRN_CORR = 'SRNCorr'}

