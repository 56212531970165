import {RateQuoteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RateQuoteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RateQuoteTypeCd ) {
      this._values.push(RateQuoteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RateQuoteTypeCd {

    for ( const obj in RateQuoteTypeCd ) {
      if (RateQuoteTypeCd[obj] === value){
        return RateQuoteTypeCd[obj] as RateQuoteTypeCd;
      }
    }
  }
}

const RateQuoteTypeCdHelper = new RateQuoteTypeCdHelperEnumHelperClass();
export default RateQuoteTypeCdHelper;
