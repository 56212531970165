
export enum TariffOperatorCd {
	ANY_BETWEEN = 'AnyBetween',
	ANY_EQUAL = 'AnyEqual',
	ANY_GREATER_THAN = 'AnyGreaterThan',
	ANY_GREATER_THAN_OR_EQUAL = 'AnyGreaterThanOrEqual',
	ANY_IN = 'AnyIn',
	ANY_LESS_THAN = 'AnyLessThan',
	ANY_LESS_THAN_OR_EQUAL = 'AnyLessThanOrEqual',
	ANY_MATCHES = 'AnyMatches',
	ANY_NOT_BETWEEN = 'AnyNotBetween',
	ANY_NOT_EQUAL = 'AnyNotEqual',
	ANY_NOT_IN = 'AnyNotIn',
	ANY_NOT_MATCHES = 'AnyNotMatches',
	BETWEEN = 'Between',
	EQUAL_TO = 'EqualTo',
	GREATER_THAN = 'GreaterThan',
	GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual',
	IN = 'In',
	LESS_THAN = 'LessThan',
	LESS_THAN_OR_EQUAL = 'LessThanOrEqual',
	MATCHES = 'Matches',
	NOT_BETWEEN = 'NotBetween',
	NOT_EQUAL_TO = 'NotEqualTo',
	NOT_IN = 'NotIn',
	NOT_MATCHES = 'NotMatches'}
