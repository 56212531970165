import {UserRoleTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class UserRoleTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in UserRoleTypeCd ) {
      this._values.push(UserRoleTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): UserRoleTypeCd {

    for ( const obj in UserRoleTypeCd ) {
      if (UserRoleTypeCd[obj] === value){
        return UserRoleTypeCd[obj] as UserRoleTypeCd;
      }
    }
  }
}

const UserRoleTypeCdHelper = new UserRoleTypeCdHelperEnumHelperClass();
export default UserRoleTypeCdHelper;
