import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CreateSalesRfpRqst,
	CreateSalesRfpResp,
	CreateSalesRfpAutoGriRqst,
	CreateSalesRfpAutoGriResp,
	GetSalesRfpResp,
	GetSalesRfpQuery,
	GetSalesRfpPath,
	ListSalesRfpResp,
	ListSalesRFPsQuery,
	GetSalesRfpCountsResp,
	GetSalesRfpCountsQuery,
	ListSalesRfpLifeCycleResp,
	ListSalesRfpLifeCyclesQuery,
	UpdateSalesRfpRqst,
	UpdateSalesRfpQuery,
	UpdateSalesRfpStatusRqst,
	ValidateSalesRfpResp,
	ValidateSalesRfpPath,
	ReassignSalesRfpRqst,
	ReassignSalesRfpResp,
	UpdateSalesRfpAgreementDtlRqst,
	UpdateSalesRfpAgreementDtlResp,
	UpdateSalesRfpAuditHeaderRqst,
	UpdateSalesRfpAuditHeaderQuery,
	GetSalesRfpAuditHeaderResp,
	GetSalesRfpAuditHeaderPath,
	CreateSalesRfpAcctsRqst,
	CreateSalesRfpAcctsResp,
	ListSalesRfpAcctsResp,
	ListSalesRfpAcctsQuery,
	ListSalesRfpAcctsPath,
	CreateSalesRfpLanesRqst,
	CreateSalesRfpLanesResp,
	ListSalesRfpAccessorialsResp,
	ListSalesRfpAccessorialsQuery,
	ListSalesRfpAccessorialsPath,
	CreateSalesRfpAccessorialsRqst,
	CreateSalesRfpAccessorialsResp,
	ListGlobalAccessorialsResp,
	ListGlobalAccessorialsQuery,
	ListPricingAccessorialsByTypeResp,
	ListPricingAccessorialsByTypeQuery,
	ListPricingAccessorialsApplIndResp,
	ListPricingAccessorialsApplIndQuery,
	CreateSalesRfpAuditLogRqst,
	CreateSalesRfpAuditLogResp,
	ListSalesRfpAuditLogsResp,
	ListSalesRfpAuditLogsQuery,
	ListSalesRfpAuditLogsPath,
	CreateSalesRfpSupportingDocsRqst,
	CreateSalesRfpSupportingDocsResp,
	CreateSalesRfpSupportingDocsQuery,
	DeleteSalesRfpSupportingDocsPath,
	GetSalesRfpSupportingDocResp,
	GetSalesRfpSupportingDocPath,
	ListSalesRfpSupportingDocsResp,
	ListSalesRfpSupportingDocsQuery,
	ListSalesRfpSupportingDocsPath,
	GetUnmappedSupportingDocResp,
	GetUnmappedSupportingDocumentPath,
	CreateSalesRfpShipmentSupportingDocsRqst,
	GetSalesRfpSupportingDocFilePath,
	GetSalesRfpSupportingDocsFileQuery,
	GetSalesRfpSupportingDocsFilePath,
	CreateSalesRfpCommentsRqst,
	CreateSalesRfpCommentsResp,
	CreateSalesRfpCommentsQuery,
	UpdateSalesRfpCommentsRqst,
	ListSalesRfpCommentsResp,
	ListSalesRfpCommentsQuery,
	ListSalesRfpCommentsPath,
	DeleteSalesRfpCommentsQuery,
	DeleteSalesRfpCommentsPath,
	ListSalesRfpObiPlansResp,
	ListSalesRfpObiPlansQuery,
	ListSalesRfpObiPlansPath,
	UpdateSalesRfpObiPlansRqst,
	UpdateSalesRfpObiPlansResp,
	DeleteSalesRfpObiPlansQuery,
	DeleteSalesRfpObiPlansPath,
	CreateSalesRfpObiPlansRqst,
	CreateSalesRfpObiPlansResp,
	ListSalesRfpGeoAreaPricingLanesResp,
	ListSalesRfpGeoAreaPricingLanesQuery,
	ListSalesRfpGeoAreaPricingLanesPath,
	UpdateSalesRfpGeoAreaPricingLanesRqst,
	CreateSalesRfpGeoAreaPricingLanesRqst,
	CreateSalesRfpGeoAreaPricingLanesResp,
	DeleteSalesRfpGeoAreaPricingLanesPath,
	ListSalesRfpCommoditiesResp,
	ListSalesRfpCommoditiesQuery,
	ListSalesRfpCommoditiesPath,
	CreateSalesRfpCommodityRqst,
	CreateSalesRfpCommodityResp,
	GetSalesRfpAgrmntPerformanceResp,
	GetSalesRfpAgrmntPerformancePath,
	CreateSalesRfpAgrmntPerformanceRqst,
	CreateSalesRfpAgrmntPerformanceResp,
	DeleteSalesRfpAgrmntPerformancePath,
	CreateSalesRfpLadingDimensionsRqst,
	CreateSalesRfpLadingDimensionsResp,
	ListSalesRfpLadingDimensionsResp,
	ListSalesRfpLadingDimensionsQuery,
	ListSalesRfpLadingDimensionsPath,
	CreateSalesRfpContactDetailsRqst,
	CreateSalesRfpContactDetailsResp,
	GetSalesRfpContactDetailsResp,
	GetSalesRfpContactDetailsPath,
	UpdateSalesRfpContactDetailsRqst,
	PrintCancelPricingAgreementLetterRqst,
	ListSalesRfpConstraintsResp,
	ListSalesRfpConstraintsQuery,
	ListSalesRfpConstraintsPath,
	CreateSalesRfpConstraintRqst,
	CreateSalesRfpConstraintResp,
	UpdateSalesRfpConstraintRqst,
	DeleteSalesRfpConstraintPath,
	CreateSalesRfpCustomerShipmentsRqst,
	CreateSalesRfpCustomerShipmentsResp,
	DeleteSalesRfpCustomerShipmentsPath,
	ListSalesRfpCustomerShipmentsResp,
	ListSalesRfpCustomerShipmentsQuery,
	ListSalesRfpCustomerShipmentsPath,
	BuildInputHierarchyShipmentFileResp,
	BuildInputHierarchyShipmentFilePath,
	BuildSalesRfpCustomerShipmentsRqst,
	BuildSalesRfpCustomerShipmentsResp,
	UpdateSalesRfpCustomerShipmentsRqst,
	GetCrmAccountInfoResp,
	GetCrmAccountPath,
	ListCrmAccountInfoResp,
	ListCrmAccountQuery,
	CreateRenegotiationSalesRfpsRqst,
	CreatePricingProposalHeaderRqst,
	CreatePricingProposalHeaderResp,
	CreatePricingProposalHeaderQuery,
	ListPricingProposalHeadersResp,
	ListPricingProposalHeadersQuery,
	GetPricingProposalHeaderResp,
	GetPricingProposalHeaderPath,
	UpdatePricingProposalHeaderRqst,
	UpdatePricingProposalRqst,
	UpdatePricingProposalQuery,
	GetPricingProposalResp,
	GetPricingProposalQuery,
	GetPricingProposalPath,
	ListPricingProposalWorkQueueResp,
	ListPricingProposalWorkQueueQuery,
	CalculatePricingProposalFaksRqst,
	CalculatePricingProposalFaksResp,
	CreatePricingAnlysGeoGroupHeadersRqst,
	CreatePricingAnlysGeoGroupHeadersResp,
	UpdatePricingAnlysGeoGroupHeaderRqst,
	ListPricingAnlysGeoGroupHeadersResp,
	ListPricingAnlysGeoGroupHeadersQuery,
	ListPricingAnlysGeoGroupHeadersPath,
	DeletePricingAnlysGeoGroupHeaderPath,
	CreateGeoPricingGroupLanesRqst,
	CreateGeoPricingGroupLanesResp,
	UpdateGeoPricingGroupLanesRqst,
	ListGeoPricingGroupLanesResp,
	ListGeoPricingGroupLanesQuery,
	DeleteGeoPricingGroupLanesQuery,
	DeleteGeoPricingGroupLanesPath,
	CreateGeoPricingGroupsRqst,
	CreateGeoPricingGroupsResp,
	UpdateGeoPricingGroupRqst,
	DeleteGeoPricingGroupPath,
	ListGeoPricingGroupsResp,
	ListGeoPricingGroupsQuery,
	CreatePricingAnalysisWorkFlowRqst,
	CreatePricingAnalysisWorkFlowResp,
	UpdatePricingAnalysisWorkflowRqst,
	ListPricingAnalysisWorkflowProgressResp,
	ListPricingAnalysisWorkflowProgressQuery,
	ListPricingAnalysisWorkflowProgressPath,
	GetPricingExtractWorkflowResp,
	GetPricingExtractWorkflowQuery,
	GetPricingExtractWorkflowStepResp,
	GetPricingExtractWorkflowStepQuery,
	GetPricingExtractWorkflowStepPath,
	DeletePricingAnalysisWorkFlowQuery,
	DeletePricingAnalysisWorkFlowPath,
	GetPricingAnalystDetailsResp,
	GetPricingAnalystDetailsPath,
	ListPricingAnalystsResp,
	ListPricingAnalystsQuery,
	CreatePricingAdminPersonnelRqst,
	CreatePricingAdminPersonnelResp,
	DeletePricingAdminPersonnelQuery,
	CreatePricingAnalysisShipmentRqst,
	CreatePricingAnalysisShipmentResp,
	UpdatePricingAnalysisShipmentRqst,
	UpdatePricingAnalysisShipmentsQuery,
	UpdatePricingAnalysisShipmentFromCsvRqst,
	ListPricingAnalysisShipmentsResp,
	ListPricingAnalysisShipmentsQuery,
	ListPricingAnalysisShipmentsPath,
	SegmentPricingLeafShipmentsRqst,
	SegmentPricingLeafShipmentsResp,
	ListPricingLeafShipmentsResp,
	ListPricingLeafShipmentsQuery,
	ListPricingLeafShipmentsPath,
	UpdatePricingLeafShipmentRqst,
	BulkUpdatePricingLeafShipmentRqst,
	ListPricingModelSegmentsResp,
	ListPricingModelSegmentsQuery,
	DeletePricingAnalysisShipmentsQuery,
	DeletePricingAnalysisShipmentsPath,
	CreatePricingAnlysGeoGroupBiAmcsRqst,
	CreatePricingAnlysGeoGroupBiAmcsResp,
	UpdatePricingAnlysGeoGroupBiAmcsRqst,
	ListPricingAnlysGeoGroupBiAmcsResp,
	ListPricingAnlysGeoGroupBIAmcsQuery,
	ListPricingAnlysGeoGroupBIAmcsPath,
	DeletePricingAnlysGeoGroupBIAmcsQuery,
	DeletePricingAnlysGeoGroupBIAmcsPath,
	CreateGeoPricingDiscountAmcSummaryRqst,
	CreateGeoPricingDiscountAmcSummaryResp,
	UpdateGeoPricingDiscountAmcSummaryRqst,
	ListGeoPricingDiscountAmcSummaryResp,
	ListGeoPricingDiscountAmcSummaryQuery,
	ListGeoPricingDiscountAmcSummaryPath,
	DeleteGeoPricingDiscountAmcSummaryQuery,
	DeleteGeoPricingDiscountAmcSummaryPath,
	CreatePricingAnlysGeoGroupBiTariffsRqst,
	CreatePricingAnlysGeoGroupBiTariffsResp,
	ListPricingAnlysGeoGroupBiTariffsResp,
	ListPricingAnlysGeoGroupBiTariffsQuery,
	ListPricingAnlysGeoGroupBiTariffsPath,
	UpdatePricingAnlysGeoGroupBiTariffRqst,
	DeletePricingAnlysGeoGroupBiTariffQuery,
	DeletePricingAnlysGeoGroupBiTariffPath,
	CreatePricingAnlysGeoGroupCmTariffsRqst,
	CreatePricingAnlysGeoGroupCmTariffsResp,
	ListPricingAnlysGeoGroupCmTariffsResp,
	ListPricingAnlysGeoGroupCmTariffsQuery,
	ListPricingAnlysGeoGroupCmTariffsPath,
	UpdatePricingAnlysGeoGroupCmTariffRqst,
	DeletePricingAnlysGeoGroupCmTariffQuery,
	DeletePricingAnlysGeoGroupCmTariffPath,
	CreatePricingAnlysGeoGroupCmAmcsRqst,
	CreatePricingAnlysGeoGroupCmAmcsResp,
	ListPricingAnlysGeoGroupCmAmcsResp,
	ListPricingAnlysGeoGroupCmAmcsQuery,
	ListPricingAnlysGeoGroupCmAmcsPath,
	UpdatePricingAnlysGeoGroupCmAmcsRqst,
	DeletePricingAnlysGeoGroupCmAmcsQuery,
	DeletePricingAnlysGeoGroupCmAmcsPath,
	CreatePricingProposalAccessorialsRqst,
	CreatePricingProposalAccessorialsResp,
	ListPricingProposalAccessorialsResp,
	ListPricingProposalAccessorialsQuery,
	ListPricingProposalAccessorialsPath,
	DeletePricingProposalAccessorialsPath,
	CreatePricingProposalGeoPricingLanesRqst,
	CreatePricingProposalGeoPricingLanesResp,
	ListPricingProposalGeoPricingLanesResp,
	ListPricingProposalGeoPricingLanesQuery,
	ListPricingProposalGeoPricingLanesPath,
	UpdatePricingProposalGeoPricingLanesRqst,
	DeletePricingProposalGeoPricingLanesQuery,
	DeletePricingProposalGeoPricingLanesPath,
	CreateGeoPricingLaneAmcsRqst,
	CreateGeoPricingLaneAmcsResp,
	ListGeoPricingLaneAmcsResp,
	ListGeoPricingLaneAmcsQuery,
	ListGeoPricingLaneAmcsPath,
	UpdateGeoPricingLaneAmcsRqst,
	UpdateGeoPricingLaneAmcsResp,
	DeleteGeoPricingLaneAmcsQuery,
	DeleteGeoPricingLaneAmcsPath,
	CreateGeoPricingLaneDiscountsRqst,
	CreateGeoPricingLaneDiscountsResp,
	ListGeoPricingLaneDiscountsResp,
	ListGeoPricingLaneDiscountsQuery,
	ListGeoPricingLaneDiscountsPath,
	UpdateGeoPricingLaneDiscountsRqst,
	UpdateGeoPricingLaneDiscountsResp,
	DeleteGeoPricingLaneDiscountsQuery,
	DeleteGeoPricingLaneDiscountsPath,
	CreatePricingAnalysisShipmentAccessorialsRqst,
	CreatePricingAnalysisShipmentAccessorialsResp,
	CreatePricingAnalysisShipmentAccessorialsQuery,
	UpdatePricingAnalysisShipmentAccessorialsRqst,
	UpdatePricingAnalysisShipmentAccessorialsQuery,
	ListPricingAnalysisShipmentAccessorialsResp,
	ListPricingAnalysisShipmentAccessorialsQuery,
	ListPricingAnalysisShipmentAccessorialsPath,
	DeletePricingAnalysisShipmentAccessorialsQuery,
	DeletePricingAnalysisShipmentAccessorialsPath,
	CreatePricingAnalystAssignmentRqst,
	CreatePricingAnalystAssignmentResp,
	ListPricingAnalystAssignmentsResp,
	ListPricingAnalystAssignmentsQuery,
	UpdatePricingAnalystAssignmentRqst,
	DeletePricingAnalystAssignmentPath,
	CreatePricingAnlysGeoGroupDiscAmcsRqst,
	CreatePricingAnlysGeoGroupDiscAmcsResp,
	ListPricingAnlysGeoGroupDiscAmcsResp,
	ListPricingAnlysGeoGroupDiscAmcsQuery,
	ListPricingAnlysGeoGroupDiscAmcsPath,
	UpdatePricingAnlysGeoGroupDiscAmcsRqst,
	DeletePricingAnlysGeoGroupDiscAmcsQuery,
	DeletePricingAnlysGeoGroupDiscAmcsPath,
	CreatePricingProposalCustHierarchyRqst,
	CreatePricingProposalCustHierarchyResp,
	CreatePricingProposalCustHierarchyQuery,
	ListPricingProposalCustHierarchyResp,
	ListPricingProposalCustHierarchyQuery,
	ListPricingProposalCustHierarchyPath,
	UpdatePricingProposalCustHierarchyRqst,
	UpdatePricingProposalCustHierarchiesRqst,
	UpdatePricingProposalCustHierarchiesQuery,
	DeletePricingProposalCustHierarchyPath,
	CalculatePricingGroupSummaryShipmentsRqst,
	CalculatePricingGroupSummaryShipmentsResp,
	CalculatePricingGroupSummaryShipmentsQuery,
	CalculatePricingGroupShipmentAmcResp,
	CalculatePricingGroupShipmentAmcQuery,
	CalculatePricingGroupShipmentAmcPath,
	CalculatePricingGroupShipmentAccsrlRqst,
	CalculatePricingGroupShipmentAccsrlResp,
	CalculatePricingGroupShipmentAccsrlQuery,
	CreateGeoPricingLaneFaksRqst,
	CreateGeoPricingLaneFaksResp,
	GetGeoPricingLaneFaksResp,
	GetGeoPricingLaneFakPath,
	UpdateGeoPricingLaneFaksRqst,
	DeleteGeoPricingLaneFaksQuery,
	DeleteGeoPricingLaneFaksPath,
	DeleteGeoPricingLaneFakOverridesQuery,
	DeleteGeoPricingLaneFakOverridesPath,
	CreatePricingProposalRulesetMatrixRqst,
	CreatePricingProposalRulesetMatrixResp,
	ListPricingProposalRulesetMatrixResp,
	ListPricingProposalRulesetMatrixQuery,
	ListPricingProposalRulesetMatrixPath,
	UpdatePricingProposalRulesetMatrixRqst,
	DeletePricingProposalRulesetMatrixQuery,
	DeletePricingProposalRulesetMatrixPath,
	CreatePricingProposalTmsFileRqst,
	CreatePricingProposalTmsFileResp,
	ListPricingProposalTmsFileResp,
	ListPricingProposalTmsFileQuery,
	ListPricingProposalTmsFilePath,
	UpdatePricingProposalTmsFileRqst,
	DeletePricingProposalTmsFileQuery,
	DeletePricingProposalTmsFilePath,
	ListAggregateModelConstraintTemplatesResp,
	ListAggregateModelConstraintTemplatesQuery,
	ListAggregateModelConstraintTemplatesPath,
	CreatePricingProposalAggrModelGeoStagingsRqst,
	CreatePricingProposalAggrModelGeoStagingsResp,
	ListPricingProposalAggrModelGeoStagingsResp,
	ListPricingProposalAggrModelGeoStagingsQuery,
	ListPricingProposalAggrModelGeoStagingsPath,
	UpdatePricingProposalAggrModelGeoStagingsRqst,
	ListGeoPricingGroupsForProposalResp,
	ListGeoPricingGroupsForProposalQuery,
	ListGeoPricingGroupsForProposalPath,
	ListGeoPricingGroupLanesForProposalResp,
	ListGeoPricingGroupLanesForProposalQuery,
	ListGeoPricingGroupLanesForProposalPath,
	ListGeoPricingGroupMembersForProposalResp,
	ListGeoPricingGroupMembersForProposalQuery,
	ListGeoPricingGroupMembersForProposalPath,
	ListGeoPricingGroupExclMembersForProposalResp,
	ListGeoPricingGroupExclMembersForProposalQuery,
	ListGeoPricingGroupExclMembersForProposalPath,
	ListPricingProposalAmcDiscountsResp,
	ListPricingProposalAmcDiscountsQuery,
	ListPricingProposalAmcDiscountsPath,
	ListPricingCodesByTypeResp,
	ListPricingCodesByTypeQuery,
	CreatePricingCodeRqst,
	CreatePricingCodeResp,
	DeletePricingCodePath,
	UpdatePricingCodeRqst,
	CreateSalesRfpAcctsRulesetRqst,
	CreateSalesRfpAcctsRulesetResp,
	ListSalesRfpAcctsRulesetResp,
	ListSalesRfpAcctsRulesetQuery,
	ListSalesRfpAcctsRulesetPath,
	DeleteSalesRfpAcctsRulesetResp,
	DeleteSalesRfpAcctsRulesetPath,
	ListAcctPricingAgreementResp,
	ListAcctPricingAgreementQuery,
	ListPricingAgreementsResp,
	ListPricingAgreementsQuery,
	ListPricingAgreementAcctsResp,
	ListPricingAgreementAcctsQuery,
	CreatePricingAgeementExpectationRqst,
	CreatePricingAgreementExpectationResp,
	CreatePricingAgreementRqst,
	CreatePricingAgreementResp,
	ListPricingAgreementExpectationsResp,
	ListPricingAgreementExpectationsQuery,
	ListPricingAgreementExpectationsPath,
	GetPricingAgreementExpectationResp,
	GetPricingAgreementExpectationPath,
	ListPricingAgreementShipmentSummResp,
	ListPricingAgreementShipmentSummQuery,
	ListPricingAgreementShipmentSummPath,
	UpdatePricingAgreementRqst,
	GetPricingAgreementShipmentSummResp,
	GetPricingAgreementShipmentSummQuery,
	GetPricingAgreementLiabilityDetailsResp,
	GetPricingAgreementLiabilityDetailsQuery,
	GetPalletToleranceWeightResp,
	GetPalletToleranceWeightQuery,
	GetPalletToleranceWeightPath,
	GetPricingAgreementReportFileQuery,
	GetClassRatesByCustomerIdRqst,
	GetClassRatesByCustomerIdResp,
	GetClassRatesByPricingAgrmtAndRulesetRqst,
	GetClassRatesByPricingAgrmtAndRulesetResp,
	ListRatingTariffWeightBreaksResp,
	ListRatingTariffWeightBreaksQuery,
	GetSalesRfpReportFileQuery,
	GetSalesRfpReportFilePath,
	CreatePricingAdminProposedStrategyRqst,
	CreatePricingAdminProposedStrategyResp,
	DeletePricingAdminProposedStrategyPath,
	UpdatePricingAdminProposedStrategyRqst,
	ListPricingAdminProposedStrategyResp,
	ListPricingAdminProposedStrategyQuery,
	CreatePricingAdminProposedIncreaseLevelRqst,
	CreatePricingAdminProposedIncreaseLevelResp,
	DeletePricingAdminProposedIncreaseLevelPath,
	UpdatePricingAdminProposedIncreaseLevelRqst,
	ListPricingAdminProposedIncreaseLevelResp,
	ListPricingAdminProposedIncreaseLevelQuery,
	CreatePricingAdminFakAdjustPctRqst,
	CreatePricingAdminFakAdjustPctResp,
	DeletePricingAdminFakAdjustPctPath,
	UpdatePricingAdminFakAdjustPctRqst,
	ListPricingAdminFakAdjustPctResp,
	ListPricingAdminFakAdjustPctQuery,
	CreatePricingAdminFscPctLookupRqst,
	CreatePricingAdminFscPctLookupResp,
	DeletePricingAdminFscPctLookupPath,
	UpdatePricingAdminFscPctLookupRqst,
	ListPricingAdminFscPctLookupResp,
	ListPricingAdminFscPctLookupQuery,
	GetUserPreferenceResp,
	GetUserPreferencePath,
	UpdateUserPreferenceRqst,
	GetPricingAgreementMonitorResp,
	GetPricingAgreementMonitorPath,
	ListPricingAgreementMonitorResp,
	ListPricingAgreementMonitorsQuery,
	UpdatePricingAgreementMonitorRqst,
	CreatePricingAgreementMonitorRqst,
	CreatePricingAgreementMonitorResp,
	GetPricingAgreementMonitorCountsResp,
	GetPricingAgreementMonitorCountsQuery,
	GetPricingAgreementMonitorParameterResp,
	GetPricingAgreementMonitorParameterPath,
	ListPricingAgreementMonitorParameterResp,
	ListPricingAgreementMonitorParametersQuery,
	CreatePricingAgreementMonitorParameterRqst,
	CreatePricingAgreementMonitorParameterResp,
	UpdatePricingAgreementMonitorParameterRqst,
	DeletePricingAgreementMonitorParameterPath,
	CreatePricingAgreementMonitorActionRqst,
	CreatePricingAgreementMonitorActionResp,
	GetPricingAgreementMonitorRegionRollupReportResp,
	GetPricingAgreementMonitorRegionRollupReportQuery,
	GetPricingAgreementMonitorRegionRollupReportPath,
	ReassignPricingAgreementMonitorRqst,
	ReassignPricingAgreementMonitorResp,
	ListPAMValidActionsResp,
	ListPricingAgreementMonitorValidActionsQuery,
	ListPricingAgreementMonitorValidActionsPath,
	ListPricingAgreementMonitorRunDateResp,
	ListPraAgreementMonitorRunDateQuery,
	ListPricingAgreementAuditResp,
	ListPricingAgreementAuditsQuery,
	ListPricingAgreementRecentAuditsQuery,
	ListPricingAgreementRecentAuditsPath,
	ListPricingAgreementNextRevisionAuditsQuery,
	ListPricingAgreementNextRevisionAuditsPath,
	UpdatePricingAgreementAuditHeaderRqst,
	UpdatePricingAgreementAuditHeaderResp,
	UpdatePricingAgreementAuditHeaderQuery,
	GetPricingAgreementAuditDetailResp,
	GetPricingAgreementAuditDetailPath,
	CreatePricingAgreementAuditCommentRqst,
	CreatePricingAgreementAuditCommentResp,
	CreatePricingAgreementAuditIssueRqst,
	CreatePricingAgreementAuditIssueResp,
	UpdatePricingAgreementAuditIssueRqst,
	UpdatePricingAgreementAuditIssueResp,
	UpdatePricingAgreementAuditIssueQuery,
	DeletePricingAgreementAuditIssueResp,
	DeletePricingAgreementAuditIssuePath,
	DeletePricingAgreementAuditEventLogPath,
	CreatePricingAgreementAuditDocumentRqst,
	CreatePricingAgreementAuditDocumentResp,
	CreatePricingAgreementAuditDocumentQuery,
	GetPricingAgreementAuditDocumentQuery,
	GetPricingAgreementAuditDocumentPath,
	CreatePricingAgreementAuditParameterRqst,
	CreatePricingAgreementAuditParameterResp,
	DeletePricingAgreementAuditParameterPath,
	GetPricingAgreementAuditParameterResp,
	GetPricingAgreementAuditParameterPath,
	ListPricingAgreementAuditParameterResp,
	ListPricingAgreementAuditParametersQuery,
	ListPricingNavigationBarCountsResp,
	ListPrcNavigationBarCountsQuery,
	ListPricingAgreementDocResp,
	ListPricingAgreementDocHdrsQuery,
	CreatePricingAgreementDocHdrRqst,
	CreatePricingAgreementDocHdrResp,
	CreatePricingAgreementDocumentRqst,
	CreatePricingAgreementDocumentResp,
	GetPricingAgreementDocumentQuery,
	GetPricingAgreementDocumentPath,
	UpdatePricingAgreementDocHdrRqst,
	UpdatePricingAgreementDocHdrResp,
	GetPricingAgreementDocumentsQuery,
	GetPricingAgreementDocumentsPath,
	SearchPricingAgreementCustomerResp,
	SearchPricingAgreementDocCustomerQuery,
	GetPricingAgreementDocHdrResp,
	GetPricingAgreementDocDetailsQuery,
	GetPricingAgreementDocDetailsPath,
	DeletePricingAgreementDocumentPath,
	DeletePricingAgreementDocHeaderPath,
	RegeneratePricingAgreementRqst,
	ListPrcCustomerHierarchyResp,
	ListPricingCustomerHierarchyQuery,
	ListPricingCustomerAccountResp,
	ListPricingCustomerAccountQuery,
	ListPricingCustomerDetailsResp,
	ListPricingCustomerDetailsQuery,
	SubmitTransitTimeFileRequestRqst,
	SubmitTransitTimeFileRequestResp
} from './api-pricing';

import {
	GetHealthCheckResp,
	FileContents,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class PricingApiService extends BaseService {
	private static PricingApiEndpoint = 'pricingApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a Sales RFP based on input data.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a Sales RFP is input.
	* <br/>Post Condition:
	* <br/>Sales RFP request is created and sent back to the consumer.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Error Creating RFP Request is returned.
	* <br/>
	*/
	public createSalesRfp(request: CreateSalesRfpRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates a Sales RFP record and Proposal Header record for Auto GRI.
	* <br/>PreCondition
	* <br/>Valid values are entered: Requester ID, Sales Agent, Lead Cust Id, Cust Agreement Suffix, Cust Agreement Version, Effective Date, Renegotiation Date, Rate Increase %, Monthly Promise, Monthly Potential and Projected OR%.
	* <br/>Post Condition:
	* <br/>Create the Sales RFP and Proposal Header record for the above specified details.
	*/
	public createSalesRfpAutoGri(request: CreateSalesRfpAutoGriRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpAutoGriResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/auto-gri'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Retrieves 1 Sales RFP based on input Sales RFP Request Identifier.
	* <br/>PreCondition
	* <br/>A valid Sales RFP Request Identifier is entered
	* <br/>Post Condition:
	* <br/>1 Sales RFP is returned for the input RFP Request Identifier
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Data not found error is returned.
	* <br/>
	*/
	public getSalesRfp(
							   pathParams: GetSalesRfpPath,
							   queryParams: GetSalesRfpQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSalesRfpResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves 1 or more Sales RFP based on input criteria.
	* <br/>PreCondition
	* <br/>One of the following valid values is entered:
	* <br/>ownerId,pricingAnalystId,negotiatingCustInstID,negotiatingCustMadCd,rfpRequestDateFrom,rfpRequestDateTo,dueDate
	* <br/>Post Condition:
	* <br/>1 or more Sales RFPs are returned for the input criteria.
	* <br/>
	*/
	public listSalesRFPs(
							   queryParams: ListSalesRFPsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Gets Sales Rfp Counts.
	* <br/>PreCondition
	* <br/>One of the following valid values is entered:
	* <br/>ownerId,pricingAnalystId,negotiatingCustInstID,negotiatingCustMadCd,rfpRequestDateFrom,rfpRequestDateTo,dueDate
	* <br/>Post Condition:
	* <br/>Returns Sales Rfp Counts for the above specified details are returned.
	*/
	public getSalesRfpCounts(
							   queryParams: GetSalesRfpCountsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSalesRfpCountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/counts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves 1 or more Sales RFP based on input criteria. Input criteria includes account Id, start date, end date, region Code, ruleset status code, rfp status code, proposal status code, and audit status.
	* <br/>PreCondition
	* <br/>One of the following valid values is entered:
	* <br/>ownerId,pricingAnalystId,negotiatingCustInstID,negotiatingCustMadCd,rfpRequestDateFrom,rfpRequestDateTo,dueDate
	* <br/>Post Condition:
	* <br/>1 or more Sales RFPs are returned for the input criteria.
	* <br/>
	*/
	public listSalesRfpLifeCycles(
							   queryParams: ListSalesRfpLifeCyclesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpLifeCycleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/lifecycle'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates an existing Sales RFP based on input data. Sales Rfp updates can include update to header information or deleting, creating and updating an existing child record like geo pricing group, pricing commodity lanes, pricing commodities, pricing lading units, pricing accessorials, etc.
	* <br/>PreCondition
	* <br/>A valid Sales Rfp id is provided and valid data for updating header or child information is provided.
	* <br/>Post Condition:
	* <br/>if the update is succesful then valid http status code is returned. No updated business object is returned in this case.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Error updating Sales RFP is returned.
	* <br/>
	*/
	public updateSalesRfp(request: UpdateSalesRfpRqst,
							   queryParams: UpdateSalesRfpQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates the status of an existing Sales RFP. A valid sales rfp id is required along with the Status Code to be updated to.
	* <br/>PreCondition
	* <br/>A valid Sales Rfp id is provided and valid status code is also provided.
	* <br/>Post Condition:
	* <br/>if the update is succesful then valid http status code is returned. No updated business object is returned in this case.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Error updating Sales RFP is returned.
	* <br/>
	*/
	public updateSalesRfpStatus(request: UpdateSalesRfpStatusRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/status'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Before submitting a Sales Rfp to Pricing this service will validate that all the data required for submiting to Pricing is valid and if any data is invalid it will return a list of errors and warnings if any.
	* <br/>Pre-conditions: 
	* <br/>1. A valid sales rfp Id is provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If a valid Sales Rfp is entered the Sales Rfp data is retrieved and validated and list of warning and error messages will be returned.
	*/
	public validateSalesRfp(
							   pathParams: ValidateSalesRfpPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ValidateSalesRfpResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/validation-msgs'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Re-assign the sales rfp from current owner to new owner.
	* <br/>Pre-condition:
	* <br/>One of the following valid values is entered:
	* <br/>list of sales rfp id, user type, owner id and salesAgent id to reassign the sales rfp
	* <br/>
	* <br/>Post-condition:
	* <br/>Returns Sales Rfp Counts for reassigned sales rfp.
	*/
	public reassignSalesRfp(request: ReassignSalesRfpRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ReassignSalesRfpResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/reassign'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates the sales rfp previous agreement details renegotiation date and proposal agreement version.
	* <br/>PreCondition
	* <br/>A valid Sales Rfp id and proposal Id is provided.
	* <br/>Post Condition:
	* <br/>if the update is succesful then valid http status code is returned. No updated business object is returned in this case.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Error updating Sales RFP is returned.
	* <br/>
	*/
	public updateSalesRfpAgreementDetails(request: UpdateSalesRfpAgreementDtlRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateSalesRfpAgreementDtlResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/agreement'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates an existing Sales RFP Audit Header on input data. Optionally, if the input parameter emailNotify is set to true then email notification will be sent. If input parameter emailNotify is not provided or is set to false then no email notification will be sent.
	* <br/>PreCondition
	* <br/>A valid Sales Rfp id is provided and valid data for updating Sales Rfp Audit header is provided.
	* <br/>Post Condition:
	* <br/>if the update is succesful then valid http status code is returned. No updated business object is returned in this case.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Error updating Sales RFP Audit Header is returned.
	* <br/>
	*/
	public updateSalesRfpAuditHeader(request: UpdateSalesRfpAuditHeaderRqst,
							   queryParams: UpdateSalesRfpAuditHeaderQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/auditheaders/{rfpAuditHeaderId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Get an existing Sales RFP Audit Header on input data.
	* <br/>PreCondition
	* <br/>A valid Sales Rfp id is provided 
	* <br/>Post Condition:
	* <br/>Sales Rfp Audit header is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Error updating Sales RFP Audit Header is returned.
	* <br/>
	*/
	public getSalesRfpAuditHeader(
							   pathParams: GetSalesRfpAuditHeaderPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSalesRfpAuditHeaderResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/auditheaders'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates Sales RFP locations based on input data.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a Sales RFP accounts is input.
	* <br/>Post Condition:
	* <br/>Sales RFP accounts are created.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request ID input or
	* <br/>Invalid Customer number input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createSalesRfpAccts(request: CreateSalesRfpAcctsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpAcctsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/accounts'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Given a unique sales rfp request instance and optional parent account instance id retrieve all of the List of accounts associated to this request.
	*/
	public listSalesRfpAccts(
							   pathParams: ListSalesRfpAcctsPath,
							   queryParams: ListSalesRfpAcctsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpAcctsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/accounts'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates Sales RFP lanes based on input data.
	* <br/>PreCondition
	* <br/>Sales RFP Identifier along with minimum 1 RFP lane is provided
	* <br/>Post Condition:
	* <br/>Sales RFP lanes is created and sent back to the consumer.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request Identifier input
	* <br/>Post Condition:
	* <br/>Data not inserted error is returned.
	* <br/>
	*/
	public createSalesRfpLanes(request: CreateSalesRfpLanesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/lanes'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Retrieves all sales rfp Accessorials for a given sales rfp id.
	* <br/>PreCondition
	* <br/>valid values is entered:
	* <br/>Sales RFP ID
	* <br/>Post Condition:
	* <br/>Get the list of associated Accessorials with code, description, unit of measure and amount.
	*/
	public listSalesRfpAccessorials(
							   pathParams: ListSalesRfpAccessorialsPath,
							   queryParams: ListSalesRfpAccessorialsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpAccessorialsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/accessorials'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates Sales RFP Accessorials for a given Sales Rfp id.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a Sales RFP accessorials is input.
	* <br/>Post Condition:
	* <br/>Sales RFP accesssorials are created and parent Sales Rfp id is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request ID input or
	* <br/>Invalid accesorial input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createSalesRfpAccessorials(request: CreateSalesRfpAccessorialsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpAccessorialsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/accessorials'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Retrieves all global Accessorial.
	* <br/>PreCondition
	* <br/>valid values is entered:
	* <br/>None
	* <br/>Post Condition:
	* <br/>Get the list of global Accessorials with code, description and unit of measure.
	*/
	public listGlobalAccessorials(
							   queryParams: ListGlobalAccessorialsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGlobalAccessorialsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/globalaccessorials'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves pricing accessorial based on input type.
	* <br/>PreCondition
	* <br/> A valid type code is entered.
	* <br/>Post Condition:
	* <br/> List of accessorials for the input type is returned.
	* <br/>PreCondition
	* <br/> An invalid type code is entered.
	* <br/>Post Condition:
	* <br/> Empty list is returned. 
	* <br/>
	*/
	public listPricingAccessorialsByType(
							   queryParams: ListPricingAccessorialsByTypeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAccessorialsByTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingaccessorials'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves all Accessorial and Appl Ind.
	* <br/>PreCondition
	* <br/>valid values is entered:
	* <br/>None
	* <br/>Post Condition:
	* <br/>Get the list of Accessorials with indicatorKey and indicatorValues
	*/
	public listPricingAccessorialsApplInd(
							   queryParams: ListPricingAccessorialsApplIndQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAccessorialsApplIndResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingaccessorials/applind'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates RFP Audit/Event Logs for a given Sales Rfp id.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a Sales RFP Audit Log is input. Sales Rfp Id is provided along with event type cd and subtype cd, event status and event data.
	* <br/>Post Condition:
	* <br/>Sales RFP Audit Log is created and parent Sales Rfp id is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request ID input or
	* <br/>Invalid Rfp Audit Log input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createSalesRfpAuditLog(request: CreateSalesRfpAuditLogRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpAuditLogResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/auditlogs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Given a unique sales rfp request instance, retrieve all events (Audit Log)
	*/
	public listSalesRfpAuditLogs(
							   pathParams: ListSalesRfpAuditLogsPath,
							   queryParams: ListSalesRfpAuditLogsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpAuditLogsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/auditlogs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates one or more supporting documents for a given Sales Rfp id.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a Sales RFP support document is input.Sales Rfp Id, one or more - document types, files and file locations is input.
	* <br/>Post Condition:
	* <br/>Sales RFP support documents are created and parent Sales Rfp id is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request ID input or
	* <br/>Invalid support document input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createSalesRfpSupportingDocs(request: CreateSalesRfpSupportingDocsRqst,
							   queryParams: CreateSalesRfpSupportingDocsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpSupportingDocsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/supportdocs'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes Sales Rfp Supporting documents for a given sales rfp id. When a supporting doc id is input only that one document is deleted. If supporting document id is not input then all the supporting documents associated to the input sales rfp id are deleted.
	* <br/>PreCondition
	* <br/>A valid Sales Rfp Id is input and has associated supporting documents.Supporting document id is not provided.
	* <br/>Post Condition:
	* <br/>All sales rfp supporting documents associated with input sales rfp id are deleted.
	* <br/>PreCondition
	* <br/>A valid sales rfp id is input and a valid supporting document id is input
	* <br/>Post Condition:
	* <br/>One Sales Rfp supporting document for input sales rfp id and input supporting document id is deleted.
	* <br/>PreCondition
	* <br/>Either sales rfp id or supporting doc id or both are invalid
	* <br/>Post Condition:
	* <br/>No data is deleted and error is returned.
	* <br/>
	*/
	public deleteSalesRfpSupportingDocs(
							   pathParams: DeleteSalesRfpSupportingDocsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/supportdocs/{rfpSupportingDocId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Given a unique sales rfp id and document id get sales rfp supporting document
	* <br/>Pre Condition
	* <br/>A valid Sales Rfp Id and is input and there are supporting documents associated with this Sales Rfp.
	* <br/>Post Condition:
	* <br/>A supporting document for input document id and related to the Sales Rfp is returned.
	* <br/>Pre Condition
	* <br/>Input Sales Rfp Id or document id is invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public getSalesRfpSupportingDoc(
							   pathParams: GetSalesRfpSupportingDocPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSalesRfpSupportingDocResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/supportdocs/{rfpSupportingDocId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Given a unique sales rfp id, retrieve all supporting documents
	* <br/>Pre Condition
	* <br/>A valid Sales Rfp Id is input and there are supporting documents associated with this Sales Rfp.
	* <br/>Post Condition:
	* <br/>All supporting documents related to the Sales Rfp are returned.
	* <br/>Pre Condition:
	* <br/>A valid sales Rfp id is input but there are no supporting documents
	* <br/>Post Condition:
	* <br/>No data is returned.
	* <br/>Pre Condition
	* <br/>Input Sales Rfp Id is invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public listSalesRfpSupportingDocs(
							   pathParams: ListSalesRfpSupportingDocsPath,
							   queryParams: ListSalesRfpSupportingDocsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpSupportingDocsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/supportdocs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation retrieves the Sales rfp supporting document from dms and then maps the field names and records from the document to return the records.
	* <br/>Pre-condition:
	* <br/>A valid rfp supporting doc id is provided
	* <br/>
	* <br/>Post-Condition:
	* <br/>Field names and "unmapped" records are returned
	* <br/>
	*/
	public getUnmappedSupportingDocument(
							   pathParams: GetUnmappedSupportingDocumentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetUnmappedSupportingDocResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/shipment-supporting-documents/{rfpSupportingDocId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation creates the Sales rfp shipment supporting document from dms and then maps the field names and records from the document to return the records.
	* <br/>Pre-condition:
	* <br/>A valid sales rfp id and supporting doc id is provided along with the column field mapping.
	* <br/>
	* <br/>Post-Condition:
	* <br/>Mapped shipment file is created.
	* <br/>
	*/
	public createSalesRfpShipmentSupportingDocs(request: CreateSalesRfpShipmentSupportingDocsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/shipment-supporting-documents'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Given a unique sales rfp id and document id get sales rfp supporting document file
	* <br/>Pre Condition
	* <br/>A valid Sales Rfp Id and is input and there are supporting documents associated with this Sales Rfp.
	* <br/>Post Condition:
	* <br/>A supporting document for input document id and related to the Sales Rfp is returned.
	* <br/>Pre Condition
	* <br/>Input Sales Rfp Id or document id is invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public getSalesRfpSupportingDocFile(
							   pathParams: GetSalesRfpSupportingDocFilePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/supportdocfiles/{rfpSupportingDocId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Given a unique sales rfp id and multiple document ids get zip file containing the sales rfp supporting documents
	* <br/>Pre Condition
	* <br/>A valid Sales Rfp Id is input and there are supporting documents associated with this Sales Rfp.
	* <br/>Post Condition:
	* <br/>A supporting document for input document id and related to the Sales Rfp is returned.
	* <br/>Pre Condition
	* <br/>Input Sales Rfp Id or document ids are invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public getSalesRfpSupportingDocsFile(
							   pathParams: GetSalesRfpSupportingDocsFilePath,
							   queryParams: GetSalesRfpSupportingDocsFileQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/supportdocfiles'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create Sales rfp Comments for a given Sales Rfp Id.
	* <br/>PreCondition
	* <br/>valid values is entered:
	* <br/>Comment type, comment sub type, note
	* <br/>Post Condition:
	* <br/>Rfp comments gets created for the specified Input.
	*/
	public createSalesRfpComments(request: CreateSalesRfpCommentsRqst,
							   queryParams: CreateSalesRfpCommentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpCommentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/comments'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Update Sales Rfp Comments for an input Sales Rfp Id and Comment Id
	* <br/>PreCondition
	* <br/>valid values is entered:
	* <br/>Comment type, comment sub type, note,comment Id
	* <br/>Post Condition:
	* <br/>Rfp comments gets updated for the specified Input.
	*/
	public updateSalesRfpComments(request: UpdateSalesRfpCommentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/comments/{commentId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Retrieves all Sales rfp Comments for a given Sales Rfp Id and optional comment type code.
	* <br/>PreCondition
	* <br/>A valid sales rfp id is entered and has comments associated.
	* <br/>Post Condition:
	* <br/>Rfp comments are retrieved for the specified Input sales rfp id.
	*/
	public listSalesRfpComments(
							   pathParams: ListSalesRfpCommentsPath,
							   queryParams: ListSalesRfpCommentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpCommentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/comments'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Deletes all Sales rfp Comments for a given Sales Rfp Id and optional comment type code.
	* <br/>PreCondition
	* <br/>A valid sales rfp id is entered and has comments associated.
	* <br/>Post Condition:
	* <br/>Rfp comments are retrieved for the specified Input sales rfp id.
	*/
	public deleteSalesRfpComments(
							   pathParams: DeleteSalesRfpCommentsPath,
							   queryParams: DeleteSalesRfpCommentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/comments'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Retrieves a list of sales rfp obi plans for a given sales rfp id.
	* <br/>PreCondition
	* <br/>A valid sales rfp id is entered and has Rfp Obi plans associated.
	* <br/>Post Condition:
	* <br/>Retrieves the sales rfp obi plan details for the specified sales rfp id.
	*/
	public listSalesRfpObiPlans(
							   pathParams: ListSalesRfpObiPlansPath,
							   queryParams: ListSalesRfpObiPlansQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpObiPlansResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/obiplans'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates a sales rfp obi plan for a given sales rfp id and rfp obi plan id.
	* <br/>PreCondition
	* <br/>A valid sales rfp id and a valid rfp obi plan id is input along with obi plan data.
	* <br/>Post Condition:
	* <br/>Update the sales rfp obi plan details for the specified sales rfp id.
	*/
	public updateSalesRfpObiPlans(request: UpdateSalesRfpObiPlansRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateSalesRfpObiPlansResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/obiplans/{rfpObiPlanId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes one or all Sales Rfp Obi plan data based on input.
	* <br/>PreCondition
	* <br/>A valid sales rfp id is provided and it has associated obi plan data. Obi plan id is not provided.
	* <br/>Post Condition:
	* <br/>All the sales rfp obi plan data is deleted for input sales rfp.
	* <br/>PreCondition
	* <br/>A valid sales rfp id and a valid rfp obi plan id is provided.
	* <br/>Post Condition:
	* <br/>sales rfp obi plan data is deleted for input sales rfp id and input rfp obi plan id.
	* <br/>
	*/
	public deleteSalesRfpObiPlans(
							   pathParams: DeleteSalesRfpObiPlansPath,
							   queryParams: DeleteSalesRfpObiPlansQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/obiplans'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Create a sales rfp obi plans for a given sales rfp id.
	* <br/>PreCondition
	* <br/>valid values is entered: Valid values include:obi program name, pay on gross net ind, qualify on gross net ind, incentive rate amount, discount type, discount amount or percent, and discount tiers, if discount type is multiple.
	* <br/>Post Condition:
	* <br/>Create the sales rfp obi plan details for the specified sales rfp id.
	*/
	public createSalesRfpObiPlans(request: CreateSalesRfpObiPlansRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpObiPlansResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/obiplans'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves geo area pricing Lane details for the specified sales rfp.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Mandatory data includes - sales rfp id and geo area pricing id.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, geo are pricing lane details data is persisted.
	*/
	public listSalesRfpGeoAreaPricingLanes(
							   pathParams: ListSalesRfpGeoAreaPricingLanesPath,
							   queryParams: ListSalesRfpGeoAreaPricingLanesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpGeoAreaPricingLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/geoareaprclanes'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates geo area pricing Lane details for the specified sales rfp Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Mandatory data includes - sales rfp id, geo area pricing id, geo pricing lane id and lane details.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, geo are pricing lane details data is persisted.
	*/
	public updateSalesRfpGeoAreaPricingLanes(request: UpdateSalesRfpGeoAreaPricingLanesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/geoareaprclanes/{geoAreaPrcLaneId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service creates geo area pricing Lane details for the specified sales rfp. Geo Pricing Lane information includes Pricing group name, origin and destination included and excluded lanes, rating tariff and rules tariff name, AMC, Discounts and FAK information.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Mandatory data includes - sales rfp id and lane details.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, geo are pricing lane details data is persisted.
	*/
	public createSalesRfpGeoAreaPricingLanes(request: CreateSalesRfpGeoAreaPricingLanesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpGeoAreaPricingLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/geoareaprclanes'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service deletes geo area pricing Lane details for the specified sales rfp and geo pricing group Id. Geo Area Pricing lane id is optional and if not provided then all lane records for the specified sales rfp id and geo area pricing id are deleted. If geo area pricing lane id is provided then only one record is deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Mandatory data includes - sales rfp id, geo area pricing id, and optional geo pricing lane id.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, geo are pricing lane details data is deleted.
	*/
	public deleteSalesRfpGeoAreaPricingLanes(
							   pathParams: DeleteSalesRfpGeoAreaPricingLanesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/geoareaprclanes/{geoAreaPrcLaneId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service returns the list of sales rfp commodity details for the specified sales rfp id. 
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - sales rfp id is provided. 
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, sales rfp commodity details are returned, otherwise error message is returned.
	* <br/>
	*/
	public listSalesRfpCommodities(
							   pathParams: ListSalesRfpCommoditiesPath,
							   queryParams: ListSalesRfpCommoditiesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpCommoditiesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/commodities'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create a sales rfp commodity details for a given sales rfp id.
	* <br/>PreCondition
	* <br/>valid values is entered: Valid values class, desc, pct of business and NFMC code.
	* <br/>Post Condition:
	* <br/>Create the sales rfp commodity details for the specified sales rfp id.
	*/
	public createSalesRfpCommodity(request: CreateSalesRfpCommodityRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpCommodityResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/commodities'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service returns the sales rfp agreement performance for the specified sales rfp id. 
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - sales rfp id is provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data valid/exists, sales rfp agreement performance details are returned, otherwise error message is returned.
	* <br/>
	*/
	public getSalesRfpAgrmntPerformance(
							   pathParams: GetSalesRfpAgrmntPerformancePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSalesRfpAgrmntPerformanceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/agrmntperformance'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create a sales rfp agreement performance for a given sales rfp id.
	* <br/>PreCondition
	* <br/>valid values is entered: Valid values current CDI, projected CDI, current monthly rev, OR, profit, project monthly rev, OR, Profit, density.
	* <br/>Post Condition:
	* <br/>Create the sales rfp pricing analysis details for the specified sales rfp id.
	*/
	public createSalesRfpAgrmntPerformance(request: CreateSalesRfpAgrmntPerformanceRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpAgrmntPerformanceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/agrmntperformance'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes sales rfp agreement performance for the specified sales rfp id. 
	* <br/>Pre-conditions: 
	* <br/>A valid sales rfp id is provided.
	* <br/>Post-conditions: 
	* <br/>Sales rfp agreement performance details are deleted for the specified sales rfp id.
	* <br/>
	*/
	public deleteSalesRfpAgrmntPerformance(
							   pathParams: DeleteSalesRfpAgrmntPerformancePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/agrmntperformance'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Create a sales rfp lading dimension details for a given sales rfp id.
	* <br/>PreCondition
	* <br/>valid values is entered: Valid values height, width, length and weight.
	* <br/>Post Condition:
	* <br/>Create the sales rfp lading dimension details for the specified sales rfp id.
	*/
	public createSalesRfpLadingDimensions(request: CreateSalesRfpLadingDimensionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpLadingDimensionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/ladingdimensions'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service returns the list of sales rfp lading dimension details for the specified sales rfp id. 
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - sales rfp id is provided. 
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, sales rfp lading dimesion details are returned, otherwise error message is returned.
	* <br/>
	*/
	public listSalesRfpLadingDimensions(
							   pathParams: ListSalesRfpLadingDimensionsPath,
							   queryParams: ListSalesRfpLadingDimensionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpLadingDimensionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/ladingdimensions'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates the contact details for the input contact and address, e-mail, fax details
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public createSalesRfpContactDetails(request: CreateSalesRfpContactDetailsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpContactDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/contacts'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Gets the contact details for the input sales rfp id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public getSalesRfpContactDetails(
							   pathParams: GetSalesRfpContactDetailsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSalesRfpContactDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/contacts'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates the contact details for the input contact and address, e-mail, fax details
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public updateSalesRfpContactDetails(request: UpdateSalesRfpContactDetailsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/contacts/{rfpContactId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Given a unique rfp header id and contact id to generate cancel pricing agreement letter file
	* <br/>Pre Condition
	* <br/>A valid RFP Header Id and contact id as input to generate cancel pricing agreement letter.
	* <br/>Post Condition:
	* <br/>A cancel pricing agreement letter is generated and stored either in rfp supporting doc or Pl docs.
	* <br/>Pre Condition
	* <br/>Input RFP Header Id is invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public printCancelPricingAgreementLetter(request: PrintCancelPricingAgreementLetterRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/cancel-letter/print'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service returns the sales rfp constarints for specified sales rfp id and source type. Source type is optional.
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - sales rfp id is provided. Source Type is optional
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, sales rfp agreement performance details are returned, otherwise error message is returned.
	* <br/>
	*/
	public listSalesRfpConstraints(
							   pathParams: ListSalesRfpConstraintsPath,
							   queryParams: ListSalesRfpConstraintsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpConstraintsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/rfpconstraints'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create a sales rfp Constraint for a given sales rfp id.
	* <br/>PreCondition
	* <br/>valid values is entered: Valid values of Constraints
	* <br/>Post Condition:
	* <br/>Create the sales rfp pricing analysis details for the specified sales rfp id.
	*/
	public createSalesRfpConstraint(request: CreateSalesRfpConstraintRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpConstraintResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/rfpConstraints'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates an existing Constraint record for a given Sales Rfp and rfp constraint id 
	* <br/>Pre-conditions: 
	* <br/>1. A valid Sales Rfp identifier is provided and the data to be updated is valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input sales rfp identifier is not valid/does not exist, error message is returned.
	* <br/>2. If Input data is valid and the pricing proposal header is updated successfully then a 200 status code is returned. 
	*/
	public updateSalesRfpConstraint(request: UpdateSalesRfpConstraintRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/rfpConstraints/{rfpConstraintId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes sales rfp constraint for the specified sales rfp id and constraint id. 
	* <br/>Pre-conditions: 
	* <br/>A valid sales rfp id and constraint is provided.
	* <br/>Post-conditions: 
	* <br/>Sales rfp constraint details are deleted for the specified sales rfp id.
	* <br/>
	*/
	public deleteSalesRfpConstraint(
							   pathParams: DeleteSalesRfpConstraintPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/rfpconstraints/{rfpConstraintId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Creates sales rfp customer shipments details for a given sales rfp id and create customer shipments.
	* <br/>PreCondition
	* <br/>valid values is entered: sales rfp id.
	* <br/>Post Condition:
	* <br/>builds the sales rfp customer shipments details for the specified sales rfp id using constraints and constraint end points.
	*/
	public createSalesRfpCustomerShipments(request: CreateSalesRfpCustomerShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpCustomerShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/customershipmentsfile'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes sales rfp customer shipments for the specified sales rfp id.
	* <br/>Pre-conditions: 
	* <br/>A valid sales rfp id is provided.
	* <br/>Post-conditions: 
	* <br/>Sales rfp customer shipments details are deleted for the specified sales rfp id.
	* <br/>
	*/
	public deleteSalesRfpCustomerShipments(
							   pathParams: DeleteSalesRfpCustomerShipmentsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/customershipments'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service returns the list of customer shipment details for the specified sales rfp id. 
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - sales rfp id is provided. 
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, customer shipment details are returned, otherwise error message is returned.
	* <br/>
	*/
	public listSalesRfpCustomerShipments(
							   pathParams: ListSalesRfpCustomerShipmentsPath,
							   queryParams: ListSalesRfpCustomerShipmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpCustomerShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/customershipments'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Build input hierarchy shipment file for a given sales rfp id.
	* <br/>PreCondition
	* <br/>valid values is entered: sales rfp id.
	* <br/>Post Condition:
	* <br/>Builds the input hierarchy shipments file details for the specified sales rfp id.
	*/
	public buildInputHierarchyShipmentFile(
							   pathParams: BuildInputHierarchyShipmentFilePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<BuildInputHierarchyShipmentFileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/input-hierarchy'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Build sales rfp customer shipments details for a given sales rfp id and create customer shipments.
	* <br/>PreCondition
	* <br/>valid values is entered: sales rfp id.
	* <br/>Post Condition:
	* <br/>builds the sales rfp customer shipments details for the specified sales rfp id using constraints and constraint end points.
	*/
	public buildSalesRfpCustomerShipments(request: BuildSalesRfpCustomerShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<BuildSalesRfpCustomerShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/customershipments'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Update a sales rfp customer shipments details for a given customer shipment file id.
	* <br/>PreCondition
	* <br/>valid values is entered: shipment weight, origin details, destination details and percent of business.
	* <br/>Post Condition:
	* <br/>updates the sales rfp customer shipments details for the specified customer shipments id.
	*/
	public updateSalesRfpCustomerShipments(request: UpdateSalesRfpCustomerShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/customershipments/{custShipmentFileId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will return the Account Information as captured in the CRM System. For input account id the Account Owner, and parent account and ultimate parent account information is sent back.
	* <br/>Pre-condition:
	* <br/>A valid Account Id is provided
	* <br/>
	* <br/>Post-Condition:
	* <br/>Basic account details and owner(s) are returned
	* <br/>
	*/
	public getCrmAccount(
							   pathParams: GetCrmAccountPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCrmAccountInfoResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/crmaccounts/{accountId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will retrieve the Account Information as captured in the CRM System. For list of input account ids the Account Owner, and parent account and ultimate parent account information is sent back.
	* <br/>Pre-condition:
	* <br/>At least 1 valid Account Id is provided
	* <br/>
	* <br/>Post-Condition:
	* <br/>Basic account details and owner(s) are returned
	* <br/>
	*/
	public listCrmAccount(
							   queryParams: ListCrmAccountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListCrmAccountInfoResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/crmaccounts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates Renegotiation Sales Rfps. There is an optional input to this service. The service will be triggered by a Tibco process. The service will check if there is any Pricing Agreement that should be renegotiated within 120 days from todays date and it will create a Renegotiation RFP for those agreements, if not already created. 
	* <br/>PreCondition
	* <br/>There exists at least one Pricing Agreement which should be renegotiated within 120 days and there is no renegotiation Rfp created.
	* <br/>Post Condition:
	* <br/>A renegotiation Sales Rfp is created for each Pricing Agreement that is supposed to be renegotiated within 120 days from todays date.
	* <br/>Status message = 201
	* <br/>
	* <br/>PreCondition
	* <br/>There does not exist any Pricing Agreement which should be renegotiated within 120 days.
	* <br/>Post Condition:
	* <br/>No Renegotiation Sales Rfp is created. Status message = 400
	* <br/>
	*/
	public createRenegotiationSalesRfps(request: CreateRenegotiationSalesRfpsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/renegotiation'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates a new Pricing Header Proposal record based on the input data.
	* <br/>Pre-conditions: 
	* <br/>1. Input data to create Pricing Proposal Header record is valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data is valid then pricing proposal instance id is returned.
	*/
	public createPricingProposalHeader(request: CreatePricingProposalHeaderRqst,
							   queryParams: CreatePricingProposalHeaderQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingProposalHeaderResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Retrieves Pricing Proposal header data. Based on the input salesRfpId or analysisTypeCd,proposal status code or pricing analyst id. One or more rows are returned.
	* <br/>Pre-conditions: 
	* <br/>1. A valid sales rfp id or analysis type cd or proposal status cd or pricing analyst id is provided 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal header data is returned.
	*/
	public listPricingProposalHeaders(
							   queryParams: ListPricingProposalHeadersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalHeadersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves Pricing Proposal header data based on the input pricing proposal id. 
	* <br/>Pre-conditions: 
	* <br/>1. A valid pricing proposal instance id is provided 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal header data is returned.
	*/
	public getPricingProposalHeader(
							   pathParams: GetPricingProposalHeaderPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingProposalHeaderResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals-hdr/{prcProposalInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates an existing Pricing Header Proposal record based on the input data.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Header identifier is provided and the data to be updated is valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input pricing proposal header identifier is not valid/does not exist, error message is returned.
	* <br/>2. If Input data is valid and the pricing proposal header is updated successfully then a 200 status code is returned. 
	*/
	public updatePricingProposalHeader(request: UpdatePricingProposalHeaderRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals-hdr/{prcProposalInstId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates Pricing Proposal data based on the input pricing proposal id and optional level of detail to retrieve children information like RFP Header, accessorials, lane, or customer hierarchy
	* <br/>Pre-conditions: 
	* <br/>1. A valid pricing proposal instance id is provided. If level of detail is provided then corresponding child data is also sent back.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal header data is returned.
	*/
	public updatePricingProposal(request: UpdatePricingProposalRqst,
							   queryParams: UpdatePricingProposalQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Retrieves Pricing Proposal data based on the input pricing proposal id and optional level of detail to retrieve children information like accessorials, lane, or customer hierarchy
	* <br/>Pre-conditions: 
	* <br/>1. A valid pricing proposal instance id is provided. If level of detail is provided then corresponding child data is also sent back.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal header data is returned.
	*/
	public getPricingProposal(
							   pathParams: GetPricingProposalPath,
							   queryParams: GetPricingProposalQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingProposalResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves Pricing Proposal header data. Based on the input salesRfpId or analysisTypeCd,proposal status code or pricing analyst id. One or more rows are returned.
	* <br/>Pre-conditions: 
	* <br/>1. A valid sales rfp id or analysis type cd or proposal status cd or pricing analyst id is provided 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal header data is returned.
	*/
	public listPricingProposalWorkQueue(
							   queryParams: ListPricingProposalWorkQueueQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalWorkQueueResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-proposal-queue'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service recalculates the Pricing Proposal FAKs and updates the Pricing Proposal for a given Pricing Proposal Id.
	* <br/>1. A valid Pricing Proposal Identifier is provided and it has associated Pricing Proposal FAK records.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal FAKs are calculated.
	*/
	public calculatePricingProposalFaks(request: CalculatePricingProposalFaksRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CalculatePricingProposalFaksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/calculatefaks'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service creates the pricing analysis geo group header record.  Based on the input, PRC_ANLYS_GEO_GROUP_HDR table is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group header record is persisted.
	*/
	public createPricingAnlysGeoGroupHeader(request: CreatePricingAnlysGeoGroupHeadersRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnlysGeoGroupHeadersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service updates the pricing analysis geo group header record.  Based on the input, PRC_ANLYS_GEO_GROUP_HDR table is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. - A valid Pricing Proposal Instance id and Pricing Analysis Geo Group Id must be provided 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group header record is persisted.
	*/
	public updatePricingAnlysGeoGroupHeader(request: UpdatePricingAnlysGeoGroupHeaderRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service retrieves a list of pricing analysis ï¿½geo group header information based on input Pricing Proposal Instance Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input Pricing Proposal Instance id is valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis geo group porter shipment-cost model AMC record is retrieved.
	*/
	public listPricingAnlysGeoGroupHeaders(
							   pathParams: ListPricingAnlysGeoGroupHeadersPath,
							   queryParams: ListPricingAnlysGeoGroupHeadersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnlysGeoGroupHeadersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service delest the pricing analysis geo group header record. Pricing Proposal instance id is mandatory and Pricing anlalysis geo group id is optional. If provided, then only 1 record is deleted for the input Pricing Analysis Geo Group Id. If not provided, then all the Pricing Analysis geo group records are deleted for input Pricing Proposal Instance Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group header record is persisted.
	*/
	public deletePricingAnlysGeoGroupHeader(
							   pathParams: DeletePricingAnlysGeoGroupHeaderPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Creates a Geo Pricing Group record. The Geo Pricing Group details include a Pricing Group name and can be setup for a given customer account. It includes a list of Geo group members that are included as part of the Group and may also exclude certain members as part of the group. 
	* <br/>Pre-conditions: 
	* <br/>1. Input data to create Geo Pricing group is valid. Geo Pricing group name is required. Group member data is not provided. 
	* <br/>Post-conditions: 
	* <br/>1. Geo Pricing Group data is persisted. If Geo Group members and Geo Group Excluded members data is provided then those are also persisted
	* <br/>
	*/
	public createGeoPricingGroupLanes(request: CreateGeoPricingGroupLanesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateGeoPricingGroupLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-group-lanes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates Geo Pricing Group Lanes record. The updates include adding or removing geo group lane members. A valid GeoPricingGroupLaneIdentifier is required.
	* <br/>Pre-conditions: 
	* <br/>1. A valid geoPricingGroupLaneId is input along with the Geo Pricing Group Lane data to be updated.
	* <br/>Post-conditions: 
	* <br/>1. Geo Pricing Group lane data is persisted and success message is returned.
	* <br/>
	*/
	public updateGeoPricingGroupLanes(request: UpdateGeoPricingGroupLanesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-group-lanes/{geoPricingGroupLaneId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Retrieves a list of geo pricing group lanes and their origin and destination pricing groups.Optionally, the consumer can pass in the acctInstId or the acctMadCd to filter the list based on the input.
	* <br/>Pre-conditions: 
	* <br/>	1. No filter criteria is provided.
	* <br/>Post-conditions: 
	* <br/>	1. All the GeoPricingGroupLane records are returned.
	* <br/>Pre-conditions: 
	* <br/>	2. A valid acctInstId or acctMadCd is provided as filter criteria
	* <br/>Post-conditions: 
	* <br/>	2. GeoPricingGroupLane information for the matching the acctInstId or acctMadCd is returned.
	* <br/>
	*/
	public listGeoPricingGroupLanes(
							   queryParams: ListGeoPricingGroupLanesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingGroupLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-group-lanes'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Deletes a Geo Pricing Group lane record based on input GeoPricingGrouplaneId and optional geo pricing group lane mbr id. If geo pricing group lane mbr id is not provided then all the geo pricing group lane member records will be deleted. If supplied, then only 1 record for the input geo pricing group lane mbr id will be deleted.
	* <br/>Pre-conditions: 
	* <br/>	A valid GeoPricingGrouplaneId is provided.
	* <br/>Post-conditions: 
	* <br/>	Geo Pricing Group lane and its associated members are deleted.
	* <br/>
	*/
	public deleteGeoPricingGroupLanes(
							   pathParams: DeleteGeoPricingGroupLanesPath,
							   queryParams: DeleteGeoPricingGroupLanesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-group-lanes/{geoPricingGroupLaneId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Creates a Geo Pricing Group record. The Geo Pricing Group details include a Pricing Group name and can be setup for a given customer account. It includes a list of Geo group members that are included as part of the Group and may also exclude certain members as part of the group. 
	* <br/>Pre-conditions: 
	* <br/>1. Input data to create Geo Pricing group is valid. Geo Pricing group name is required. Group member data is not provided. 
	* <br/>Post-conditions: 
	* <br/>1. Geo Pricing Group data is persisted. If Geo Group members and Geo Group Excluded members data is provided then those are also persisted
	* <br/>
	*/
	public createGeoPricingGroups(request: CreateGeoPricingGroupsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateGeoPricingGroupsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-groups'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates Geo Pricing Group record. The updates include adding or removing geo group members or geo group excluded members. A valid GeoPricingGroupIdentifier is required.
	* <br/>Pre-conditions: 
	* <br/>1. A valid geoPricingGroupId is input along with the Geo Pricing Group data to be updated.
	* <br/>Post-conditions: 
	* <br/>1. Geo Pricing Group data is persisted and success message is returned.
	* <br/>
	*/
	public updateGeoPricingGroup(request: UpdateGeoPricingGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-groups/{geoPricingGroupId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes a Geo Pricing Group record based on input GeoPricingGroupId. The service will also delete all the GeoPricingGroupMembers and the GeoPricingGroupExcludedMembers of the group.
	* <br/>Pre-conditions: 
	* <br/>	A valid GeoPricingGroupId is provided.
	* <br/>Post-conditions: 
	* <br/>	Geo Pricing Group and its related members and excluded members are deleted.
	* <br/>
	*/
	public deleteGeoPricingGroup(
							   pathParams: DeleteGeoPricingGroupPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-groups/{geoPricingGroupId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Retrieves a list of geo pricing groups and their included and excluded member details based on the level of detail requested. The list can be filtered by passing optional 1 or more pricingGroupIds
	* <br/>Pre-conditions: 
	* <br/>	1. No filter criteria is provided.
	* <br/>Post-conditions: 
	* <br/>	1. All the GeoPricingGroup records are returned.
	* <br/>Pre-conditions: 
	* <br/>	2. A valid pricingGroupName is provided as filter criteria
	* <br/>Post-conditions: 
	* <br/>	2. GeoPricingGroup information for the matching the pricingGroupName is returned.
	* <br/>
	*/
	public listGeoPricingGroups(
							   queryParams: ListGeoPricingGroupsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingGroupsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/geo-pricing-groups'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service creates the pricing analysis flow record.  Based on the input, PRC_ANALYSIS_FLOW table is persisted. This service will be consumed by both Front End and TIBCO. Front End will pass the prcProposalInstId and extractTypeCd and extractStatusCd (within the analysisFlowInfo object). TIBCO will pass the prcProposalInstId and prcExtractWorkflowId (within the analysisFlowInfo object).
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis flow record is persisted.
	*/
	public createPricingAnalysisWorkFlow(request: CreatePricingAnalysisWorkFlowRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnalysisWorkFlowResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/workflows'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service update an existing pricing analysis flow record.  Based on the input, PRC_ANALYSIS_FLOW table is updated. This service will be consumed by both Front End and TIBCO. Front End will pass the prcProposalInstId and analysiFlowInstId(within the analysisFlowInfo object). 
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis flow record is persisted.
	*/
	public updatePricingAnalysisWorkFlow(request: UpdatePricingAnalysisWorkflowRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/workflows/{analysisFlowInstId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service retrieves pricing analysis workflow progress based on input Pricing proposal id. The list can be filtered by providing an extract type code and/or extract status code. One or more rows will be returned based on the input data.
	* <br/>Pre-conditions: 
	* <br/>1. A valid prc proposal inst id is provided along with valid values for extract type code and/or extract status code.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis header data is returned.
	*/
	public listPricingAnalysisWorkflowProgress(
							   pathParams: ListPricingAnalysisWorkflowProgressPath,
							   queryParams: ListPricingAnalysisWorkflowProgressQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnalysisWorkflowProgressResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/workflows'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will get the pricing extract workflow record from PRC_EXTRACT_WORKFLOW based on the input workflow name. 
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing extract workflow record is retrieved.
	*/
	public getPricingExtractWorkflow(
							   queryParams: GetPricingExtractWorkflowQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingExtractWorkflowResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/extractworkflows'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service retrieves the pricing analysis extract workflow step record.  Based on the input, PRC_EXTRACT_WORKFLOW_STEP record is retrieved.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis extract workflow step record is retrieved.
	*/
	public getPricingExtractWorkflowStep(
							   pathParams: GetPricingExtractWorkflowStepPath,
							   queryParams: GetPricingExtractWorkflowStepQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingExtractWorkflowStepResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/extractworkflows/{prcExtractWorkflowId}/workflowsteps'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will delete one or all Pricing Analysis workflow records for a given Pricing proposal instance id and optional Analysis flow instance id. If no analysis flow id is provided then all the Analysis flow records are deleted, otherwise, only 1 record for the input Analysis flow id is deleted.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis flow record is persisted.
	*/
	public deletePricingAnalysisWorkFlow(
							   pathParams: DeletePricingAnalysisWorkFlowPath,
							   queryParams: DeletePricingAnalysisWorkFlowQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/workflows'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* If the pricing analyst employee id is provided, the pricing analyst name and the pricing manager info are returned. One row will be returned.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public getPricingAnalystDetails(
							   pathParams: GetPricingAnalystDetailsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAnalystDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricinganalysts/{pricingAnalystEmplId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* If the Manager Id is provided, it will list all the Pricing Analyst under the Manager Id. 
	* <br/>If the Role Type is provided, it will list all the employees of that role type.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, List of pricing analysts data is returned.		
	*/
	public listPricingAnalysts(
							   queryParams: ListPricingAnalystsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnalystsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricinganalysts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will store user details in pricing system. If the details are not there it will create otherwise it will update the existing details.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public createPricingAdminPersonnel(request: CreatePricingAdminPersonnelRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAdminPersonnelResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-admin-personnel'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will delete admin personnel details.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Atleast 1 adminPersonnelId is input
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, Pricing Admin Personnel record is deleted.
	*/
	public deletePricingAdminPersonnel(
							   queryParams: DeletePricingAdminPersonnelQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-admin-personnel'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates a Pricing Analysis Shipment records for a given Pricing Proposal. The Analysis shipments are created from Customer shipment file given at the time of Sales Rfp creation or input by Pricing Analyst. 
	* <br/>Pre-conditions: 
	* <br/>1. Input Pricing Proposal Instance id is valid and minimum data for creating shipments is provided. Data required:
	* <br/>                                                Shipment ID
	* <br/>                                                Actual Weight
	* <br/>                                                Density 
	* <br/>                                                Pieces
	* <br/>                                                Pallets
	* <br/>                                                Origin Zip Code
	* <br/>                                                Destination Zip Code
	* <br/>                                                Pickup Date
	* <br/>                                                Tariff Revenue
	* <br/>                                                Actual Class
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis shipment data is persisted and Pricing Proposal Instance Id is returned.
	*/
	public createPricingAnalysisShipments(request: CreatePricingAnalysisShipmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnalysisShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shipments'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service updates one or more Pricing Analysis Shipment records for a given Pricing Proposal Instance Id.
	* <br/>Pre-conditions: 
	* <br/>1. Pricing Proposal Instance Id is valid and Analysis Shipment Input data is valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis shipment data is persisted and no data is returned.
	*/
	public updatePricingAnalysisShipments(request: UpdatePricingAnalysisShipmentRqst,
							   queryParams: UpdatePricingAnalysisShipmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shipments'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates one or more analysis shipment data for a given CSV file.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a Sales RFP support document is input.Sales Rfp Id, one or more - document types, files and file locations is input.
	* <br/>Post Condition:
	* <br/>Sales RFP support documents are created and parent Sales Rfp id is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Sales RFP Request ID input or
	* <br/>Invalid support document input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public updatePricingAnalysisShipmentsFromCSV(request: UpdatePricingAnalysisShipmentFromCsvRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/analysis-shipment-csv'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves pricing analysis shipment level records based on the input pricing proposal instance id. One or more rows will be returned based on the input data.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. A valid pricing proposal ID is provided.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis shpment level data is returned.
	*/
	public listPricingAnalysisShipments(
							   pathParams: ListPricingAnalysisShipmentsPath,
							   queryParams: ListPricingAnalysisShipmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnalysisShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shipments'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will create the Pricing Leaf shipment records by reading all the Shipments selected for analysis for input pricing proposal id and running them thru the segmentation model.
	* <br/>Pre-conditions: 
	* <br/>1. Pricing Proposal Id is valid and has analysis shipments.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, leaf shipments are created and parent pricing proposal id is returned.
	*/
	public segmentPricingLeafShipments(request: SegmentPricingLeafShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SegmentPricingLeafShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/leafshipments'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves pricing leaf shipment records based on the input pricing proposal instance id. One or more rows will be returned based on the input data.
	* <br/>Pre-conditions: 
	* <br/>1. Pricing Proposal Id is valid and has Leaf Shipments.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, leaf shpment level data is returned.
	*/
	public listPricingLeafShipments(
							   pathParams: ListPricingLeafShipmentsPath,
							   queryParams: ListPricingLeafShipmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingLeafShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/leafshipments'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates an existing pricing leaf shipment record based on the input pricing proposal instance id and the leaf shipment id.
	* <br/>Pre-conditions: 
	* <br/>1. Pricing Proposal Id and leaf shipment id is valid.
	* <br/>Post-conditions: 
	* <br/>1. Leaf Shipment record is updated based on input data.
	* <br/>
	* <br/>
	*/
	public updatePricingLeafShipment(request: UpdatePricingLeafShipmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/leafshipments/{prcLeafShipmentId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service updates in bulk 1 or more existing pricing leaf shipment record based on the input pricing proposal instance id and the leaf shipment id.
	* <br/>Pre-conditions: 
	* <br/>1. Pricing Proposal Id and leaf shipment id is valid.
	* <br/>Post-conditions: 
	* <br/>1. Leaf Shipment record is updated based on input data.
	* <br/>
	* <br/>
	*/
	public bulkUpdatePricingLeafShipments(request: BulkUpdatePricingLeafShipmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/leafshipments'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service returns all the segments of the Pricing Model.
	* <br/>Pre-conditions: 
	* <br/>none
	* <br/>Post-conditions: 
	* <br/>List of all the Pricing Model segments is returned.
	* <br/>
	*/
	public listPricingModelSegments(
							   queryParams: ListPricingModelSegmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingModelSegmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-model-segments'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service deletes Pricing Analysis shipment.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. If list of shipment Ids provided delete those shipments otherwise delete all shipments for the proposal ID.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analysis shipments are deleted.
	* <br/>
	*/
	public deletePricingAnalysisShipments(
							   pathParams: DeletePricingAnalysisShipmentsPath,
							   queryParams: DeletePricingAnalysisShipmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shipments'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing analysis historical rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and BI AMC data. PRC_ANLYS_GEO_GROUP_BI_AMC table is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public createPricingAnlysGeoGroupBIAmcs(request: CreatePricingAnlysGeoGroupBiAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnlysGeoGroupBiAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-amcs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service updates the pricing analysis historical rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and BI AMC data. PRC_ANLYS_GEO_GROUP_BI_AMC table is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public updatePricingAnlysGeoGroupBIAmcs(request: UpdatePricingAnlysGeoGroupBiAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-amcs/{geoGroupBiAmcId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service retrieves a list of pricing analysis historical rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public listPricingAnlysGeoGroupBIAmcs(
							   pathParams: ListPricingAnlysGeoGroupBIAmcsPath,
							   queryParams: ListPricingAnlysGeoGroupBIAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnlysGeoGroupBiAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service deletes the pricing analysis historical rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and optional geo group BI AMC id.If geo group BI AMC id is not provided then all the records for input pricing proposal id and pricing analysis geo group id are deleted otherwise only one record for the input geo group bi amc id is deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public deletePricingAnlysGeoGroupBIAmcs(
							   pathParams: DeletePricingAnlysGeoGroupBIAmcsPath,
							   queryParams: DeletePricingAnlysGeoGroupBIAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the geo pricing group disount and AMC record.  Based on the input, PRC_GEO_DSCNT_AMC_SUMM  is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  geo pricing group discount and absolute minum charge record is persisted.
	*/
	public createGeoPricingDiscountAmcSummary(request: CreateGeoPricingDiscountAmcSummaryRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateGeoPricingDiscountAmcSummaryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-discount-amcs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service updates the geo pricing group disount and AMC record.  Based on the input pricing proposal id and geo discount amc summary identifier, PRC_GEO_DSCNT_AMC_SUMM  is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  geo pricing group discount and absolute minum charge record is persisted.
	*/
	public updateGeoPricingDiscountAmcSummary(request: UpdateGeoPricingDiscountAmcSummaryRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-discount-amcs/{geoDiscAmcSummaryId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service retrieves the geo pricing group disount and AMC record for the input pricing proposal instance id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  geo pricing group discount and absolute minum charge record is persisted.
	*/
	public listGeoPricingDiscountAmcSummary(
							   pathParams: ListGeoPricingDiscountAmcSummaryPath,
							   queryParams: ListGeoPricingDiscountAmcSummaryQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingDiscountAmcSummaryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-discount-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service deletes one or all the geo pricing group disount and AMC record.  
	* <br/>Pre-conditions: 
	* <br/>1. Input data - pricing proposal instance id and ,optional , geoDiscAmcSummaryId is input
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  geo pricing group discount and absolute minum charge record is persisted.
	*/
	public deleteGeoPricingDiscountAmcSummary(
							   pathParams: DeleteGeoPricingDiscountAmcSummaryPath,
							   queryParams: DeleteGeoPricingDiscountAmcSummaryQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-discount-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing analysis  porter shipment-cost model  not rated with absolute minimum charge record.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.Input data includes the Pricing Proposal Instance Id and the Pricing Analysis Geo Group Id.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public createPricingAnlysGeoGroupBiTariffs(request: CreatePricingAnlysGeoGroupBiTariffsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnlysGeoGroupBiTariffsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-tariffs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves a list of pricing analysis historical shipment rated with tariff record based on the input pricing proposal inst id, pricing analysis geo group id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public listPricingAnlysGeoGroupBiTariffs(
							   pathParams: ListPricingAnlysGeoGroupBiTariffsPath,
							   queryParams: ListPricingAnlysGeoGroupBiTariffsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnlysGeoGroupBiTariffsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-tariffs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the pricing analysis Historical shipments based on the input pricing proposal inst id, pricing analysis geo group id and BI tariff data
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public updatePricingAnlysGeoGroupBiTariff(request: UpdatePricingAnlysGeoGroupBiTariffRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-tariffs/{geoGroupBiTariffId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes the pricing analysis cost model shipment porter file not rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and optional geo group Cm Tariff id.If geo group CM Tariff id is not provided then all the records for input pricing proposal id and pricing analysis geo group id are deleted otherwise only one record for the input geo group cm tariff id is deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public deletePricingAnlysGeoGroupBiTariff(
							   pathParams: DeletePricingAnlysGeoGroupBiTariffPath,
							   queryParams: DeletePricingAnlysGeoGroupBiTariffQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/bi-tariffs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing analysis  porter shipment-cost model  not rated with absolute minimum charge record.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.Input data includes the Pricing Proposal Instance Id and the Pricing Analysis Geo Group Id.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public createPricingAnlysGeoGroupCmTariffs(request: CreatePricingAnlysGeoGroupCmTariffsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnlysGeoGroupCmTariffsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-tariffs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves a list of pricing analysis cost model rated with tariff record based on the input pricing proposal inst id, pricing analysis geo group id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public listPricingAnlysGeoGroupCmTariffs(
							   pathParams: ListPricingAnlysGeoGroupCmTariffsPath,
							   queryParams: ListPricingAnlysGeoGroupCmTariffsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnlysGeoGroupCmTariffsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-tariffs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the pricing analysis Cost model rated with tariff record based on the input pricing proposal inst id, pricing analysis geo group id and Cost Model tariff data
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public updatePricingAnlysGeoGroupCmTariff(request: UpdatePricingAnlysGeoGroupCmTariffRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-tariffs/{geoGroupCmTariffId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes the pricing analysis cost model shipment porter file not rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and optional geo group Cm Tariff id.If geo group CM Tariff id is not provided then all the records for input pricing proposal id and pricing analysis geo group id are deleted otherwise only one record for the input geo group cm tariff id is deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public deletePricingAnlysGeoGroupCmTariff(
							   pathParams: DeletePricingAnlysGeoGroupCmTariffPath,
							   queryParams: DeletePricingAnlysGeoGroupCmTariffQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-tariffs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing analysis cost model rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and CM AMC data. PRC_ANLYS_GEO_GROUP_CM_AMC table is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public createPricingAnlysGeoGroupCmAmcs(request: CreatePricingAnlysGeoGroupCmAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnlysGeoGroupCmAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-amcs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves a list of pricing analysis historical rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public listPricingAnlysGeoGroupCmAmcs(
							   pathParams: ListPricingAnlysGeoGroupCmAmcsPath,
							   queryParams: ListPricingAnlysGeoGroupCmAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnlysGeoGroupCmAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the pricing analysis historical rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and CM AMC data. PRC_ANLYS_GEO_GROUP_CM_AMC table is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group historical shipments AMC record is persisted.
	*/
	public updatePricingAnlysGeoGroupCmAmcs(request: UpdatePricingAnlysGeoGroupCmAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-amcs/{geoGroupCmAmcId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes the pricing analysis cost model rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and optional geo group BI AMC id.If geo group BI AMC id is not provided then all the records for input pricing proposal id and pricing analysis geo group id are deleted otherwise only one record for the input geo group bi amc id is deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group cost model shipments AMC record is persisted.
	*/
	public deletePricingAnlysGeoGroupCmAmcs(
							   pathParams: DeletePricingAnlysGeoGroupCmAmcsPath,
							   queryParams: DeletePricingAnlysGeoGroupCmAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/cm-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing proposal accessorial records for a given Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis shipment accessorial record is persisted.
	*/
	public createPricingProposalAccessorials(request: CreatePricingProposalAccessorialsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingProposalAccessorialsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/accessorials'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves the pricing proposal accessorial records for a given Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis shipment accessorial record is returned.
	*/
	public listPricingProposalAccessorials(
							   pathParams: ListPricingProposalAccessorialsPath,
							   queryParams: ListPricingProposalAccessorialsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalAccessorialsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/accessorials'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service deletes the pricing proposal accessorial records for a given pricing proposal accessorial id,  Pricing and Pricing Proposal Id. Pricing Proposal Accessorial Id is optional and if provided only 1 record matching the id will be deleted. If not provided then all the proposal accessorial records for input Pricing Proposal Instance Id will be deleted.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis shipment accessorial record is persisted.
	*/
	public deletePricingProposalAccessorials(
							   pathParams: DeletePricingProposalAccessorialsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/accessorials/{prcProposalAccsrlId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing lane record for an input Pricing Proposal Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public createPricingProposalGeoPricingLanes(request: CreatePricingProposalGeoPricingLanesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingProposalGeoPricingLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geoprclanes'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service returns a list of Geo Pricing Lanes record for input Pricing Proposal Id.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Id is provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public listPricingProposalGeoPricingLanes(
							   pathParams: ListPricingProposalGeoPricingLanesPath,
							   queryParams: ListPricingProposalGeoPricingLanesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalGeoPricingLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geoprclanes'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the pricing lane record for an input Pricing Proposal Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public updatePricingProposalGeoPricingLanes(request: UpdatePricingProposalGeoPricingLanesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geoprclanes/{geoAreaPrcLaneId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service updates the pricing lane record for an input Pricing Proposal Id and an optional geoAreaPrcLaneId. If geoAreaPrcLaneId is not input then all the geo pricing lane records will be deleted. If suppied, then only 1 record for the input geoAreaPrcLaneId will be deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public deletePricingProposalGeoPricingLanes(
							   pathParams: DeletePricingProposalGeoPricingLanesPath,
							   queryParams: DeletePricingProposalGeoPricingLanesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geoprclanes'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* 
	* <br/>This service creates the Absolute Minimum Charge records for a given Geo Pricing Lane.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Valid data includes the parent geo pricing lane identifier, amc amount and currency and various indicators: Interstate, Intrastate, International, Thirdparty, Outbound prepaid, outbound collect, inbound prepaid and inbound collect.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal absolute minimum charge data is persisted.
	* <br/>
	*/
	public createGeoPricingLaneAmcs(request: CreateGeoPricingLaneAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateGeoPricingLaneAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/amcs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* 
	* <br/>This service retrieves a list of absolute minimum chargs for a given Geo Pricing Lane. A valid geo pricing lane id must be provided to retrieve the absolute minimum charges.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal discount data is persisted.
	*/
	public listGeoPricingLaneAmcs(
							   pathParams: ListGeoPricingLaneAmcsPath,
							   queryParams: ListGeoPricingLaneAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingLaneAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* 
	* <br/>This service updates the Absolute Minimum Charge records for a given Geo Pricing Lane and a given geo pricing lane amc identifier.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Valid data includes the parent geo pricing lane identifier, geo pricing lane amc id,  amc amount and currency and various indicators: Interstate, Intrastate, International, Thirdparty, Outbound prepaid, outbound collect, inbound prepaid and inbound collect.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal absolute minimum charge data is persisted.
	* <br/>
	*/
	public updateGeoPricingLaneAmcs(request: UpdateGeoPricingLaneAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateGeoPricingLaneAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/amcs/{geoPricingLaneAmcId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* 
	* <br/>This service deletes one or all the Absolute Minimum Charge records for a given Geo Pricing Lane.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Valid data includes the parent geo pricing lane identifier and an optional geo pricing lane amc id. If geo pricing lane id is not provided then all amc records are deleted. If a geo pricing lane amc id is provided then only 1 record is deleted.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal absolute minimum charge data is deleted.
	* <br/>
	*/
	public deleteGeoPricingLaneAmcs(
							   pathParams: DeleteGeoPricingLaneAmcsPath,
							   queryParams: DeleteGeoPricingLaneAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* 
	* <br/>This service creates discounts for a given Geo Pricing Lane. A geo pricing lane id must be provided and valid.If the discount is tiered then range type code, range uom, begin range value and end range value and discount percent must be provided. 
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal discount data is persisted.
	*/
	public createGeoPricingLaneDiscounts(request: CreateGeoPricingLaneDiscountsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateGeoPricingLaneDiscountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/discounts'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* 
	* <br/>This service retrieves a list of discounts for a given Geo Pricing Lane. A valid geo pricing lane id must be provided to retrieve the discounts.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal discount data is persisted.
	*/
	public listGeoPricingLaneDiscounts(
							   pathParams: ListGeoPricingLaneDiscountsPath,
							   queryParams: ListGeoPricingLaneDiscountsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingLaneDiscountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/discounts'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* 
	* <br/>This service updates an existing discount for a given Geo Pricing Lane. A geo pricing lane id and geo pricing lane discount id must be provided and valid.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal discount data is persisted.
	*/
	public updateGeoPricingLaneDiscounts(request: UpdateGeoPricingLaneDiscountsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateGeoPricingLaneDiscountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/discounts/{geoPricingLaneDiscountId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* 
	* <br/>This service will delete one or all discounts for a given Geo Pricing Lane. 
	* <br/>Pre-conditions: 
	* <br/>1. A valid geo pricing lane id is provided.
	* <br/>Post-conditions: 
	* <br/>1. All the discounts associated with this lane id are deleted and a success message is sent back.
	* <br/>Pre-conditions: 
	* <br/>2. A valid geo pricing lane id and a geo pricing lane discount id is provided.
	* <br/>Post-conditions: 
	* <br/>2. One discount record associated with this lane id and discount id is deleted and a success message is sent back.
	* <br/>
	*/
	public deleteGeoPricingLaneDiscounts(
							   pathParams: DeleteGeoPricingLaneDiscountsPath,
							   queryParams: DeleteGeoPricingLaneDiscountsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geolanes/{geoPricingLaneId}/discounts'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing analysis shipment accessorial records for a given Pricing Analysis Shipment id and Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis shipment accessorial record is persisted.
	*/
	public createPricingAnalysisShipmentAccessorials(request: CreatePricingAnalysisShipmentAccessorialsRqst,
							   queryParams: CreatePricingAnalysisShipmentAccessorialsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnalysisShipmentAccessorialsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shipments/{prcAnlysShipmentId}/shpmt-accsrls'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service updates the pricing analysis shipment accessorial records for a given pricing analysis shipment accessorial id,  Pricing Analysis Shipment id and Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis shipment accessorial record is persisted.
	*/
	public updatePricingAnalysisShipmentAccessorials(request: UpdatePricingAnalysisShipmentAccessorialsRqst,
							   queryParams: UpdatePricingAnalysisShipmentAccessorialsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shpmt-accsrls'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service retrieves the pricing analysis shipment accessorial records for a given Pricing Analysis Shipment id and Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis shipment accessorial record is returned.
	*/
	public listPricingAnalysisShipmentAccessorials(
							   pathParams: ListPricingAnalysisShipmentAccessorialsPath,
							   queryParams: ListPricingAnalysisShipmentAccessorialsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnalysisShipmentAccessorialsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shipments/{prcAnlysShipmentId}/shpmt-accsrls'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service deletes the pricing analysis shipment accessorial records for a given pricing analysis shipment accessorial id,  Pricing Analysis Shipment id and Pricing Proposal Id. PricingAnalysisShipmentAccessorialId is optional and if provided only 1 record matching the id will be deleted. If not provided then all the Shipment accessorial records for input PricingAnalysisShipmentId will be deleted.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis shipment accessorial record is persisted.
	*/
	public deletePricingAnalysisShipmentAccessorials(
							   pathParams: DeletePricingAnalysisShipmentAccessorialsPath,
							   queryParams: DeletePricingAnalysisShipmentAccessorialsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/shipments/{prcAnlysShipmentId}/shpmt-accsrls'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates a set of Pricing Analyst Assignments.
	* <br/>Pre-conditions:		 	
	* <br/>1. Input data are valid. Mandatory data includes: AssignmentTypeCd,PricingAnalystEmployeeId,PricingAnalystFullName,EffectiveDate, and ExpirationDate. If no expiration date is provided then default to 12/31/2999.
	* <br/>If AssignmentTypeCd = 1(AE/SAM) then required fields are: AccountExecutiveEmployeedId, AccountExecutiveFullName,NASAccountSalesSupervisorEmployeeId,NASAccountSalesSupervisorEmployeeName,NASAccountSalesSpecialistEmployeeId,NASAccountSalesSpecialistFullName.
	* <br/>If AssignmentTypeCd = 2(District) then required fields are:SalesRegionCd
	* <br/>If AssignmentTypeCd = 3(Region) then Area Code is required.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid, error message is returned.
	* <br/>2. If Input data valid,  one or more pricing analyst assignment records are persisted.
	*/
	public createPricingAnalystAssignments(request: CreatePricingAnalystAssignmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnalystAssignmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingAnalystAssignments'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will retrieve a list of one or more Pricing Analyst Assignments based on the input Search criteria.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  One or More Pricing Analyst Assignments are returned.
	*/
	public listPricingAnalystAssignments(
							   queryParams: ListPricingAnalystAssignmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnalystAssignmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingAnalystAssignments'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the Pricing Analyst Assignment information of input Pricing Analyst Assignment Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid, error message is returned.
	* <br/>2. If Input data valid, pricing analyst assignment record is updated.
	*/
	public updatePricingAnalystAssignments(request: UpdatePricingAnalystAssignmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingAnalystAssignments/{analystAssignmentId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes Pricing Analyst Assignment of the input Pricing Analyst Assignment Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. Validate Input Pricing Analyst Assignment Id is not blank and numeric.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing Analyst Assignment for the input Pricing Analyst Assignment Id is deleted.
	* <br/>
	*/
	public deletePricingAnalystAssignment(
							   pathParams: DeletePricingAnalystAssignmentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingAnalystAssignments/{analystAssignmentId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing analysis geo group discount and AMC record. Based on the input pricing proposal inst id, pricing analysis geo group id, pricing analysis geo group discount and AMC data the DiscountAMC data is persisted
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid: PricingProposalInstId, PricingAnalysisGeoGroupId and geo group discount and AMC data.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group discount and AMC record is persisted.
	*/
	public createPricingAnlysGeoGroupDiscAmcs(request: CreatePricingAnlysGeoGroupDiscAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAnlysGeoGroupDiscAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/discount-amcs'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves a list of pricing analysis geo group discount rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group discount and AMC record is persisted.
	*/
	public listPricingAnlysGeoGroupDiscAmcs(
							   pathParams: ListPricingAnlysGeoGroupDiscAmcsPath,
							   queryParams: ListPricingAnlysGeoGroupDiscAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAnlysGeoGroupDiscAmcsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/discount-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the pricing analysis geo group discount rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and Discount AMC data. PRC_ANLYS_GEO_GROUP_DISC_AMC table is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group discount and AMC record is persisted.
	*/
	public updatePricingAnlysGeoGroupDiscAmcs(request: UpdatePricingAnlysGeoGroupDiscAmcsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/discount-amcs/{geoGroupDiscAmcId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes the pricing analysis geo group discount rated with absolute minimum charge record.  Based on the input pricing proposal inst id, pricing analysis geo group id and optional geo group Discount AMC id.If geo group Discount AMC id is not provided then all the records for input pricing proposal id and pricing analysis geo group id are deleted otherwise only one record for the input geo group discount amc id is deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing analysis geo group discount and AMC record is persisted.
	*/
	public deletePricingAnlysGeoGroupDiscAmcs(
							   pathParams: DeletePricingAnlysGeoGroupDiscAmcsPath,
							   queryParams: DeletePricingAnlysGeoGroupDiscAmcsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geogroups/{prcAnalysGeoGroupId}/discount-amcs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the customer hierarchy record for an input Pricing Proposal Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public createPricingProposalCustHierarchy(request: CreatePricingProposalCustHierarchyRqst,
							   queryParams: CreatePricingProposalCustHierarchyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingProposalCustHierarchyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/custhierarchies'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will retrieve a list of all the customer hierarchy record for an input Pricing Proposal Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public listPricingProposalCustHierarchy(
							   pathParams: ListPricingProposalCustHierarchyPath,
							   queryParams: ListPricingProposalCustHierarchyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalCustHierarchyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/custhierarchies'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service Updates the customer hierarchy record for an input Pricing Proposal Id. 
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public updatePricingProposalCustHierarchy(request: UpdatePricingProposalCustHierarchyRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/custhierarchies/{prcProposalCustHierarchyId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service Updates the customer hierarchy records for an input Pricing Proposal Id. 
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public updatePricingProposalCustHierarchies(request: UpdatePricingProposalCustHierarchiesRqst,
							   queryParams: UpdatePricingProposalCustHierarchiesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/custhierarchies'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes the customer hierarchy record for an input Pricing Proposal Id. Pricing Proposal Customer hierarchy id is optional and if provided, only 1 record for the corresponding Identifier will be deleted. If not provided, then all customer hierarchy records for the input Pricing Proposal Id will be deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing group lane record is persisted.
	*/
	public deletePricingProposalCustHierarchy(
							   pathParams: DeletePricingProposalCustHierarchyPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/custhierarchies/{prcProposalCustHierarchyId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service returns the summary of shipments for a requested pricing group. The inputs are:  a valid Pricing Proposal ID and at least one pricing group category indicator. Service can accept more than one group category indicators.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,   summary of shipments for a requested pricing group are returned.
	*/
	public calculatePricingGroupSummaryShipments(request: CalculatePricingGroupSummaryShipmentsRqst,
							   queryParams: CalculatePricingGroupSummaryShipmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CalculatePricingGroupSummaryShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-group-summary-shipments/{prcProposalInstId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service returns the summary of shipments for a requested pricing group. The inputs are:  a valid Pricing Proposal ID and at least one pricing group category indicator. Service can accept more than one group category indicators.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,   summary of shipments for a requested pricing group are returned.
	*/
	public calculatePricingGroupShipmentAmc(
							   pathParams: CalculatePricingGroupShipmentAmcPath,
							   queryParams: CalculatePricingGroupShipmentAmcQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CalculatePricingGroupShipmentAmcResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-group-shipments-amc/{prcProposalInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns the summary of shipments for a requested pricing group. The inputs are:  a valid Pricing Proposal ID and at least one pricing group category indicator. Service can accept more than one group category indicators.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,   summary of shipments for a requested pricing group are returned.
	*/
	public calculatePricingGroupShipmentAccsrl(request: CalculatePricingGroupShipmentAccsrlRqst,
							   queryParams: CalculatePricingGroupShipmentAccsrlQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CalculatePricingGroupShipmentAccsrlResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-group-shipments-accsrls/{prcProposalInstId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* 
	* <br/>This service creates Pricing Proposal FAK (class override)  records for a given Pricing Proposal Instance Id and Geo Pricing Lane Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal FAK data is persisted.
	*/
	public createGeoPricingLaneFaks(request: CreateGeoPricingLaneFaksRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateGeoPricingLaneFaksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geopricinglanes/{geoPrcLaneId}/fakoverrides'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* 
	* <br/>This service returns Pricing Proposal FAK (class override)  records for a given Pricing Proposal Instance Id and Geo Pricing Lane Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal FAK data is persisted.
	*/
	public getGeoPricingLaneFak(
							   pathParams: GetGeoPricingLaneFakPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetGeoPricingLaneFaksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geopricinglanes/{geoPrcLaneId}/fakoverrides'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* 
	* <br/>This service updates Pricing Proposal FAK (class override)  records for a given Pricing Proposal Instance Id and Geo Pricing Lane Id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal FAK data is persisted.
	*/
	public updateGeoPricingLaneFak(request: UpdateGeoPricingLaneFaksRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geopricinglanes/{geoPrcLaneId}/fakoverrides/{pricingProposalFakId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* 
	* <br/>This service deletes Pricing Proposal FAK (class override)  records for a given Pricing Proposal Instance Id and Geo Pricing Lane Id. PricingProposalFakId is optional and if not provided then all the Pricing Proposal FAK records for a given geo pricing lane and pricing proposal are deleted, otherwise, only 1 FAK record for input pricing proposal fak id is deleted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal FAK data is persisted.
	*/
	public deleteGeoPricingLaneFaks(
							   pathParams: DeleteGeoPricingLaneFaksPath,
							   queryParams: DeleteGeoPricingLaneFaksQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geopricinglanes/{geoPrcLaneId}/fakoverrides'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* 
	* <br/>This service deletes Pricing Proposal FAK (class override) records for a given Pricing Proposal Instance Id, Geo Pricing Lane Id, PricingProposalFakHdrId and Fak Override IDs.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing proposal FAK data is persisted.
	*/
	public deleteGeoPricingLaneFakOverrides(
							   pathParams: DeleteGeoPricingLaneFakOverridesPath,
							   queryParams: DeleteGeoPricingLaneFakOverridesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geopricinglanes/{geoPrcLaneId}/fakhdr/{prcFakHdrId}/fakoverrides'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing proposal ruleset matrix record based on the input parameters.  Based on the input, PRC_PROPOSAL_RULESET_MATRIX is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal ruleset matrix record is returned and persisted.
	*/
	public createPricingProposalRulesetMatrix(request: CreatePricingProposalRulesetMatrixRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingProposalRulesetMatrixResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/rulesetmatrix'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves the pricing proposal ruleset matrix record for an input Pricing Proposal Instance Id.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Instance Id is supplied. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal ruleset matrix record is returned and persisted.
	*/
	public listPricingProposalRulesetMatrix(
							   pathParams: ListPricingProposalRulesetMatrixPath,
							   queryParams: ListPricingProposalRulesetMatrixQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalRulesetMatrixResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/rulesetmatrix'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates an existing pricing proposal ruleset matrix record for input Pricing Proposal Inst Id and Pricing Proposal Ruleset Matrix id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal ruleset matrix record is returned and persisted.
	*/
	public updatePricingProposalRulesetMatrix(request: UpdatePricingProposalRulesetMatrixRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/rulesetmatrix/{prcProposalRulesetMatrixId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes the pricing proposal ruleset matrix record for an input Pricing Proposal Instance Id. If pricing proposal ruleset matrix id is provided then only 1 record is deleted. If not provided, then all the ruleset matrix records for input pricing proposal instance id are deleted.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Instance Id is supplied. Optionally, a valid Pricing Proposal Ruleset Matrix Id is provided.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal ruleset matrix record is returned and persisted.
	*/
	public deletePricingProposalRulesetMatrix(
							   pathParams: DeletePricingProposalRulesetMatrixPath,
							   queryParams: DeletePricingProposalRulesetMatrixQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/rulesetmatrix'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service creates the pricing proposal TMS File record based on the input parameters.  Based on the input, PRC_PROPOSAL_TMS_FILE is persisted.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal TMS file record is returned and persisted.
	*/
	public createPricingProposalTmsFile(request: CreatePricingProposalTmsFileRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingProposalTmsFileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/tmsfiles'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves the pricing proposal TMS file records for an input Pricing Proposal Instance Id.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Instance Id is supplied. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal ruleset matrix record is returned and persisted.
	*/
	public listPricingProposalTmsFile(
							   pathParams: ListPricingProposalTmsFilePath,
							   queryParams: ListPricingProposalTmsFileQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalTmsFileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/tmsfiles'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates an existing pricing proposal TMS file record for input Pricing Proposal Inst Id and Pricing Proposal Tms file id.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal ruleset matrix record is returned and persisted.
	*/
	public updatePricingProposalTmsFile(request: UpdatePricingProposalTmsFileRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/tmsfiles/{prcProposalTmsFileId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service deletes the pricing proposal TMS file record for an input Pricing Proposal Instance Id. If pricing proposal TMS file id is provided then only 1 record is deleted. If not provided, then all the TMS File records for input pricing proposal instance id are deleted.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Instance Id is supplied. Optionally, a valid Pricing Proposal TMS File Id is provided.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal ruleset matrix record is returned and persisted.
	*/
	public deletePricingProposalTmsFile(
							   pathParams: DeletePricingProposalTmsFilePath,
							   queryParams: DeletePricingProposalTmsFileQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/tmsfiles'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Retrieves a list of Aggregate Model Constraint templates for an input Aggregate model template id. 
	* <br/>Pre-conditions: 
	* <br/>	1. A valid Aggregate Model template Identifier is provided and has Constraint templates associated.
	* <br/>Post-conditions: 
	* <br/>	1. All the Aggragate Model Constraint template records associated with the input aggregate model template id are returned.
	* <br/>
	*/
	public listAggregateModelConstraintTemplates(
							   pathParams: ListAggregateModelConstraintTemplatesPath,
							   queryParams: ListAggregateModelConstraintTemplatesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAggregateModelConstraintTemplatesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/aggr-models/{prcProposalInstId}/constraint-templates/{aggrModelTemplateId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service creates the Pricing Proposal Aggrgation Model Geo Staging records for a given Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  Pricing Proposal Aggrgation Model Geo Staging records are persisted.
	*/
	public createPricingProposalAggrModelGeoStagings(request: CreatePricingProposalAggrModelGeoStagingsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingProposalAggrModelGeoStagingsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/aggrmodelgeostagings'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service retrieves the pricing proposal aggregation Model Geo Staging records for a given Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal aggregation Model Geo Staging records are returned.
	*/
	public listPricingProposalAggrModelGeoStagings(
							   pathParams: ListPricingProposalAggrModelGeoStagingsPath,
							   queryParams: ListPricingProposalAggrModelGeoStagingsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalAggrModelGeoStagingsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/aggrmodelgeostagings'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the pricing proposal Aggr Model Geo Staging records for a given Pricing Proposal Id.
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  pricing proposal Aggr Model Geo Staging records are persisted.
	*/
	public updatePricingProposalAggrModelGeoStagings(request: UpdatePricingProposalAggrModelGeoStagingsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/aggrmodelgeostagings'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service returns a list of Geo Pricing Groups for a Proposal.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Id is provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  a list of Geo Pricing Groups for a given proposal is returned.
	*/
	public listGeoPricingGroupsForProposal(
							   pathParams: ListGeoPricingGroupsForProposalPath,
							   queryParams: ListGeoPricingGroupsForProposalQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingGroupsForProposalResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-pricing-groups'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns a list of Geo Pricing Group Lanes for a Proposal.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Id is provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists,  a list of Geo Pricing Group Lanes is returned.
	*/
	public listGeoPricingGroupLanesForProposal(
							   pathParams: ListGeoPricingGroupLanesForProposalPath,
							   queryParams: ListGeoPricingGroupLanesForProposalQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingGroupLanesForProposalResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-pricing-group-lanes'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns a list of Geo Pricing Group Members for a Proposal.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Id is provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, a list of Geo Pricing Group Members for a given proposal is returned.
	*/
	public listGeoPricingGroupMembersForProposal(
							   pathParams: ListGeoPricingGroupMembersForProposalPath,
							   queryParams: ListGeoPricingGroupMembersForProposalQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingGroupMembersForProposalResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-pricing-group-members'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns a list of excluded Geo Pricing Group Members for a Proposal.
	* <br/>Pre-conditions: 
	* <br/>1. A valid Pricing Proposal Id is provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, a list of Excluded Geo Pricing Group Members for a given proposal is returned.
	*/
	public listGeoPricingGroupExclMembersForProposal(
							   pathParams: ListGeoPricingGroupExclMembersForProposalPath,
							   queryParams: ListGeoPricingGroupExclMembersForProposalQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoPricingGroupExclMembersForProposalResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/geo-pricing-group-exclmembers'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns the list of AMCs and Discounts for a given Pricing Proposal Id.
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - pricing proposal Instance id is provided and it has associated AMCs and Discounts.
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, AMCs and Discounts detail are returned, otherwise error message is returned.
	* <br/>
	*/
	public listPricingProposalAmcDiscounts(
							   pathParams: ListPricingProposalAmcDiscountsPath,
							   queryParams: ListPricingProposalAmcDiscountsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingProposalAmcDiscountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingproposals/{prcProposalInstId}/amc-discounts'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation list the codes filtered by any combination category (for example SalesRFP), sub-category (SalesRFPTypes) or no filter which would return all the Pricing codes. Each code returned will have its details.
	* <br/>
	* <br/>Pre-condition:
	* <br/>If a valid category code is input then the list of codes will be returned that belong to that category.
	* <br/>If a valid sub-category code is input then the list of codes belonging to the category and sub-category code combination will be returned. Category is optional. The sub-category alone will retrieve the list of codes. Multiple sub-categories may be provided.
	* <br/>If no category and sub-category code entered then all the Pricing codes will be returned.
	* <br/>
	* <br/>Post-Condition:
	* <br/>List of code details (Name, Value, and display order) is returned for each code
	* <br/>
	*/
	public listPricingCodesByType(
							   queryParams: ListPricingCodesByTypeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingCodesByTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingcodes'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation creates the pricing code for the specified Input.
	* <br/>Pre-condition:
	* <br/>category code, sub category code, name, sorting order, effective date, expiry date and value are specified as input.
	* <br/>Post-Condition:
	* <br/>Pricing code will be created and Pricing Code Id is assigned and sent back in the response.
	*/
	public createPricingCode(request: CreatePricingCodeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingcodes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* The operation deletes the pricing code for the specified code ID.
	* <br/>Pre-condition:
	* <br/>Code ID specified as input and is valid.
	* <br/>Post-Condition:
	* <br/>Pricing code will be deleted.
	*/
	public deletePricingCode(
							   pathParams: DeletePricingCodePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingcode/{pricingCodeId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* The operation updates the pricing code information for a given Pricing Code Id.
	* <br/>Pre-condition:
	* <br/>Pricing Code Id is valid and category code, sub category code, name, sorting order, effective date, expiry date and value are specified as input.
	* <br/>Post-Condition:
	* <br/>Pricing code will be updated.
	* <br/>Pre-condition:
	* <br/>Invalid Pricing Code Id provided
	* <br/>Post-Condition:
	* <br/>Error message is returned.
	*/
	public updatePricingCode(request: UpdatePricingCodeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingcodes/{pricingCodeId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates the Rulesets for input Sales Rfp Id
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public createSalesRfpAcctsRuleset(request: CreateSalesRfpAcctsRulesetRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSalesRfpAcctsRulesetResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/accounts/rulesets'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Gets the list of rulesets impacted for each customer account added/deleted for specific update location sales rfp id .
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid- A valid sales rfp id must be provided. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public listSalesRfpAcctsRuleset(
							   pathParams: ListSalesRfpAcctsRulesetPath,
							   queryParams: ListSalesRfpAcctsRulesetQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSalesRfpAcctsRulesetResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/accounts/rulesets'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Delete the Rulesets for input Sales Rfp Id
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public deleteSalesRfpAcctsRuleset(
							   pathParams: DeleteSalesRfpAcctsRulesetPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteSalesRfpAcctsRulesetResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/accounts/rulesets'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* list the pricing agreements either associated with the negotiating customer which is a corporate account or for the list of 1 or more accounts which are P2D or Bil2 accounts. The negotiating customer will be used to find pricing agreement for which it is the owner. The list of accounts will be used to search the rulesets where they are referenced and find the pricing agreements to which those rulesets belong.
	* <br/>
	* <br/>
	* <br/>PreCondition
	* <br/>Either a negotiating customer is provided or a list of 1 or more accounts will be provided
	* <br/>Post Condition:
	* <br/>Basic Pricing agreement identifying information is returned for each pricing agreement that meets the criteria.
	* <br/>
	*/
	public listAcctPricingAgreement(
							   queryParams: ListAcctPricingAgreementQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAcctPricingAgreementResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts/acctpricingagreements'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieve a list of pricing agreements based on input parameters. Input parameters include acctInstId, or pricingAgreementId
	* <br/>PreCondition
	* <br/>Either a negotiating customer account id is provided or a pricing agreement id is provided
	* <br/>Post Condition:
	* <br/>Basic Pricing agreement identifying information is returned for each pricing agreement that meets the criteria.
	* <br/>
	*/
	public listPricingAgreements(
							   queryParams: ListPricingAgreementsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves a list of locations for the specified pricing agreement.
	* <br/>PreCondition
	* <br/>A valid pricing agreement to be specified customer id, suffix and version number.
	* <br/>Post Condition:
	* <br/>Retrieves the agreement locations for the specified pricing agreement.
	*/
	public listPricingAgreementAccts(
							   queryParams: ListPricingAgreementAcctsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementAcctsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/accts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create a pricing agreeemnt Expectations for the account.
	* <br/>PreCondition
	* <br/>valid values is entered: Pricing agreement expectations details project monthly revenue, projected OR, monthly promise amount, monthly potential amount,  profit improvrmrnt target, effective date, expiration date.
	* <br/>Post Condition:
	* <br/>Create the pricing agreement expectations for the above specified details.
	*/
	public createPricingAgreementExpectation(request: CreatePricingAgeementExpectationRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing/{agreementId}/agreementexpectations'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates a pricing agreement for the customer account.
	* <br/>PreCondition
	* <br/>valid values is entered: Pricing agreement details customer, version, seq number, effective date, expiration date, renegotiation date, primary location, sales rfp id, agreement type.
	* <br/>Post Condition:
	* <br/>Create the pricing agreement for the above specified details.
	*/
	public createPricingAgreement(request: CreatePricingAgreementRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service returns the list of Pricing agreement Expectations for a given Pricing Agreement Id. 
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - Pricing Agreement Id.
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, Pricing agreement Expectations details are returned, otherwise error message is returned.
	* <br/>
	*/
	public listPricingAgreementExpectations(
							   pathParams: ListPricingAgreementExpectationsPath,
							   queryParams: ListPricingAgreementExpectationsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementExpectationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/{agreementId}/expectations'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns the details of Pricing agreement Expectations for a given Pricing Agreement expectation Id. 
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - Pricing Agreement expectation  Id.
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, Pricing agreement Expectations details are returned, otherwise error message is returned.
	* <br/>
	*/
	public getPricingAgreementExpectation(
							   pathParams: GetPricingAgreementExpectationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/{agreementId}/expectations/{agreementExpectationId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns the list of Pricing agreement shipment summary specified for a given agreement identifier.
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - agreement id and/or lead account ID
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, Pricing agreement shipment summary details are returned, otherwise error message is returned.
	* <br/>
	*/
	public listPricingAgreementShipmentSumm(
							   pathParams: ListPricingAgreementShipmentSummPath,
							   queryParams: ListPricingAgreementShipmentSummQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementShipmentSummResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/{agreementId}/shipmentsumm'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates a pricing agreeemnt for the account.
	* <br/>PreCondition
	* <br/>valid values is entered: Pricing agreement details effective date, expiration date, renegotiation date, primary location, sales rfp id, agreement type.
	* <br/>Post Condition:
	* <br/>Create the pricing agreement for the above specified details.
	*/
	public updatePricingAgreement(request: UpdatePricingAgreementRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/{agreementId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service returns the count of shipments specified for a given customer or sequence or version.
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - customer id and/or seq ni and/or version number
	* <br/>Post-conditions: 
	* <br/>1.  If Input data valid/exists, shipment countdetails are returned, otherwise error message is returned.
	* <br/>
	*/
	public getPricingAgreementShipmentSumm(
							   queryParams: GetPricingAgreementShipmentSummQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementShipmentSummResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/shipmentsumm'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns pricing agreement liability details based on input parameters. Input parameters include: the customer ID, sequence number, version number, and/or the pricing agreement ID.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Either a negotiating customer account ID, sequence number, and version number are provided or a pricing agreement ID is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If there is a match based on the provided criteria, then basic pricing agreement and liability details are returned.
	* <br/>2. If there are no matches found, then an empty response is returned.  Thea application will handle the message that will be displayed to the user. 
	*/
	public getPricingAgreementLiabilityDetails(
							   queryParams: GetPricingAgreementLiabilityDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementLiabilityDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/liability'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the Pallet Tolerance Weight for the input Pricing Agreement Id.
	* <br/>PreCondition
	* <br/>Mandatory Agreement Id is input.
	* <br/>Post Condition:
	* <br/>Pallet Tolerance weight for the input agreement id is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement id input 
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public getPalletToleranceWeight(
							   pathParams: GetPalletToleranceWeightPath,
							   queryParams: GetPalletToleranceWeightQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPalletToleranceWeightResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/{agreementId}/palletTolerances'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Given a unique combination of Lead Account Id, Agreement Seq Number and Agreement version number return an agreement details report (typically PDF)
	* <br/>
	* <br/>Pre Condition:
	* <br/>A valid Agreement Id and reportType
	* <br/>Post Condition:
	* <br/>Pricing Agreement document file is downloaded.
	* <br/>
	* <br/>
	*/
	public getPricingAgreementReportFile(
							   queryParams: GetPricingAgreementReportFileQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/reportfile'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a Pricing Agreement Monitor file that will be downloaded by SFDC. There is no input to this service. The service will be triggered by a Tibco process. The output will be a PDF file of Pricing Agreement Monitor records that includes the projected and actual OR for each of the pricing agreement.
	* <br/>PreCondition
	* <br/>There exists at least one Sales Rfp with Pricing Agreement Expectation records.
	* <br/>Post Condition:
	* <br/>A PDF file is created with Pricing Agreement Monitor records.
	* <br/>Status message = 201
	* <br/>
	*/
	public getPricingAgreementMonitorFile(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-agreement-monitor/downloadfile'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieve the Class Rates for input Customer account Instance Id
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and is valid. Mandatory data includes:
	* <br/>	Customer Account Instance Id, chargeToCd (Valid values are P(prepaid), C(Collect), B(both)), Origin stateCd, countryCd, postalCd, Destination stateCd, countryCd, postalCd, partyTypeCd - valid values are S(Shipper), C(consignee), B(bill 2 party)
	* <br/>Post-conditions: 
	* <br/>1. If Pricing agreement exists for input customer account instance id (or its parent account in heirarchy) and all other input data is valid then Class rates information will be returned.
	* <br/>2. If any of the above mentioned input data is not valid then error status code will be returned along with appropriate error message.
	* <br/>
	*/
	public getClassRatesByCustomerId(request: GetClassRatesByCustomerIdRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetClassRatesByCustomerIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/classrates/{acctInstId}/{partyTypeCode}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Retrieve the Class Rates for input Agreement Id and Ruleset sequence number.
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and is valid. Mandatory data includes:
	* <br/>	Pricing agreement Instance id, Ruleset sequence number, chargeToCd (Valid values are P(prepaid), C(Collect), B(both)), Origin stateCd, countryCd, postalCd, Destination stateCd, countryCd, postalCd, partyTypeCd - valid values are S(Shipper), C(consignee), B(bill 2 party)
	* <br/>Post-conditions: 
	* <br/>1. If Pricing agreement instance id and ruleset sequence number exists and all other input data is valid then Class rates information will be returned.
	* <br/>2. If any of the above mentioned input data is not valid then error status code will be returned along with appropriate error message.
	* <br/>
	*/
	public getClassRatesByPricingAgrmtAndRuleset(request: GetClassRatesByPricingAgrmtAndRulesetRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetClassRatesByPricingAgrmtAndRulesetResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/classrates/{agreementId}/{rulesetNbr}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service returns all the rating tariffs along with their allowed weight breaks. 
	* <br/>		The list can be filtered by passing optional parameters: tariffCode
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - None. 
	* <br/>Post-conditions: 
	* <br/>1.  Rating tariffs are returned, otherwise error message is returned.
	* <br/>
	*/
	public listRatingTariffWeightBreaks(
							   queryParams: ListRatingTariffWeightBreaksQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRatingTariffWeightBreaksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/tariffweightbreaks'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Given a unique sales rfp id, build and return a sales rfp report (typically PDF)
	* <br/>
	* <br/>Pre Condition
	* <br/>A valid Sales Rfp Id
	* <br/>Post Condition
	* <br/>Sales Rfp document file is downloaded.
	* <br/>
	* <br/>
	*/
	public getSalesRfpReportFile(
							   pathParams: GetSalesRfpReportFilePath,
							   queryParams: GetSalesRfpReportFileQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfps/{salesRfpId}/reportfile'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a Sales Rfp Audit file. There is no input to this service. The service will be triggered by a Tibco process. The service will create a csv file with data from various Sales Rfp tables to create an audit file that will be downloaded by Sales force for auditing the Sales Rfps.
	* <br/>
	*/
	public getSalesRfpAuditFile(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/salesrfp-audit/downloadfile'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will create the Proposed Strategy records in the reference table PRC_ADMIN_PROPOSED_STRATEGY
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public createPricingAdminProposedStrategy(request: CreatePricingAdminProposedStrategyRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAdminProposedStrategyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposed-strategies'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will delete an existing Proposed Strategy records in the reference table PRC_ADMIN_PROPOSED_STRATEGY
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public deletePricingAdminProposedStrategy(
							   pathParams: DeletePricingAdminProposedStrategyPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposed-strategies/{adminProposedStrategyPctId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service will update an existing Proposed Strategy records in the reference table PRC_ADMIN_PROPOSED_STRATEGY
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public updatePricingAdminProposedStrategy(request: UpdatePricingAdminProposedStrategyRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposed-strategies/{adminProposedStrategyPctId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will retrieve the list of all the Proposed Strategy records in the reference table PRC_ADMIN_PROPOSED_STRATEGY
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public listPricingAdminProposedStrategy(
							   queryParams: ListPricingAdminProposedStrategyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAdminProposedStrategyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposed-strategies'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will create the Proposed Incr Level records in the reference table PRC_ADMIN_PROPOSED_INCR_LEVEL
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public createPricingAdminProposedIncreaseLevel(request: CreatePricingAdminProposedIncreaseLevelRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAdminProposedIncreaseLevelResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposedincrlevels'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will delete an existing Proposed Strategy records in the reference table PRC_ADMIN_PROPOSED_INCR_LEVEL
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public deletePricingAdminProposedIncreaseLevel(
							   pathParams: DeletePricingAdminProposedIncreaseLevelPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposedincrlevels/{adminProposedIncrLevelId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service will update an existing Proposed Strategy records in the reference table PRC_ADMIN_PROPOSED_INCR_LEVEL Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public updatePricingAdminProposedIncreaseLevel(request: UpdatePricingAdminProposedIncreaseLevelRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposedincrlevels/{adminProposedIncrLevelId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will retrieve the list of all the Proposed Increase Level records in the reference table PRC_ADMIN_PROPOSED_INCR_LEVEL
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public listPricingAdminProposedIncreaseLevel(
							   queryParams: ListPricingAdminProposedIncreaseLevelQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAdminProposedIncreaseLevelResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/proposedincrlevels'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will create the Proposed Strategy records in the reference table PRC_ADMIN_FAK_ADJUST_PCT
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public createPricingAdminFakAdjustPct(request: CreatePricingAdminFakAdjustPctRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAdminFakAdjustPctResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fakAdjustPcts'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will delete an existing Proposed Strategy records in the reference table PRC_ADMIN_FAK_ADJUST_PCT
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public deletePricingAdminFakAdjustPct(
							   pathParams: DeletePricingAdminFakAdjustPctPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fakAdjustPcts/{adminFakAdjustPctId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service will update an existing Proposed Strategy records in the reference table PRC_ADMIN_FAK_ADJUST_PCT
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public updatePricingAdminFakAdjustPct(request: UpdatePricingAdminFakAdjustPctRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fakadjustpcts/{adminFakAdjustPctId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will retrieve the list of all the Proposed Strategy records in the reference table PRC_ADMIN_FAK_ADJUST_PCT
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public listPricingAdminFakAdjustPct(
							   queryParams: ListPricingAdminFakAdjustPctQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAdminFakAdjustPctResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fakadjustpcts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will create the fsc pct lookup records in the reference table PRC_ADMIN_FSC_PCT_LOOKUP
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public createPricingAdminFscPctLookup(request: CreatePricingAdminFscPctLookupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAdminFscPctLookupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fscPctLookup'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will delete an existing Proposed Strategy records in the reference table PRC_ADMIN_FSC_PCT_LOOKUP
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public deletePricingAdminFscPctLookup(
							   pathParams: DeletePricingAdminFscPctLookupPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fscPctLookup/{adminFscPctLookupId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service will update an existing Proposed Strategy records in the reference table PRC_ADMIN_FCS_PCT_LOOKUP
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public updatePricingAdminFscPctLookup(request: UpdatePricingAdminFscPctLookupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fscpctlookup/{adminFscPctLookupId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will retrieve the list of all the Fuel Surcharge Percent records in the reference table PRC_ADMIN_FSC_PCT_LOOKUP
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. 
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public listPricingAdminFscPctLookup(
							   queryParams: ListPricingAdminFscPctLookupQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAdminFscPctLookupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/fscpctlookup'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation retrieves the user preference for a given employee id and app key
	* <br/>Pre-condition:
	* <br/>A valid employee id  and app key is provided and user preference exists for this combination
	* <br/>Post-Condition:
	* <br/>If User preferences exists for the employee id and app key combination then the User preference is returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>User preference not exists for employee id and/or app key combination
	* <br/>Post-Condition:
	* <br/>Not found message is returned.
	* <br/>
	* <br/>
	*/
	public getUserPreference(
							   pathParams: GetUserPreferencePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetUserPreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/users/{employeeId}/userprefs/{appKey}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation updates the user preference
	* <br/>Pre-condition:
	* <br/>A valid employee id, app key, and user preference is provided
	* <br/>
	*/
	public updateUserPreference(request: UpdateUserPreferenceRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/users/{employeeId}/userprefs/{appKey}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Gets pricing agreeemnt monitor.
	* <br/>PreCondition
	* <br/>A valid pricingAgreementMonitorId is entered.
	* <br/>Post Condition:
	* <br/>Returns a pricing agreement monitor for the entered pricingAgreementMonitorId.
	*/
	public getPricingAgreementMonitor(
							   pathParams: GetPricingAgreementMonitorPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementMonitorResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/{pricingAgreementMonitorId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists pricing agreement monitor records.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement monitor records for the above specified details.
	*/
	public listPricingAgreementMonitors(
							   queryParams: ListPricingAgreementMonitorsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementMonitorResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates pricing agreeemnt monitor.
	* <br/>PreCondition
	* <br/>valid input values are entered
	* <br/>Post Condition:
	* <br/>Update a pricing agreement monitor for the above specified details.
	*/
	public updatePricingAgreementMonitor(request: UpdatePricingAgreementMonitorRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/{pricingAgreementMonitorId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates Pricing Agreement Monitors.
	* <br/>
	*/
	public createPricingAgreementMonitors(request: CreatePricingAgreementMonitorRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementMonitorResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Gets pricing agreeemnt monitor counts.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement monitor record counts for the above specified details.
	*/
	public getPricingAgreementMonitorCounts(
							   queryParams: GetPricingAgreementMonitorCountsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementMonitorCountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/counts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Gets pricing agreeemnt monitor parameter.
	* <br/>PreCondition
	* <br/>A valid agreementMonitorParameterId is entered.
	* <br/>Post Condition:
	* <br/>Returns a pricing agreement monitor parameter for the entered agreementMonitorParameterId.
	*/
	public getPricingAgreementMonitorParameter(
							   pathParams: GetPricingAgreementMonitorParameterPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementMonitorParameterResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitorparameters/{agreementMonitorParameterId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists pricing agreeemnt monitor parameters.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement monitor parameters for the above specified details.
	*/
	public listPricingAgreementMonitorParameters(
							   queryParams: ListPricingAgreementMonitorParametersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementMonitorParameterResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitorparameters'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates pricing agreeemnt monitor parameter.
	* <br/>PreCondition
	* <br/>valid input values are entered
	* <br/>Post Condition:
	* <br/>Create a pricing agreement monitor parameter for the above specified details.
	*/
	public createPricingAgreementMonitorParameter(request: CreatePricingAgreementMonitorParameterRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementMonitorParameterResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitorparameters'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates pricing agreeemnt monitor parameter.
	* <br/>PreCondition
	* <br/>valid input values are entered
	* <br/>Post Condition:
	* <br/>Update a pricing agreement monitor parameter for the above specified details.
	*/
	public updatePricingAgreementMonitorParameter(request: UpdatePricingAgreementMonitorParameterRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitorparameters/{agreementMonitorParameterId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes pricing agreeemnt monitor parameter.
	* <br/>PreCondition
	* <br/>A valid agreementMonitorParameterId is entered.
	* <br/>Post Condition:
	* <br/>Deletes a pricing agreement monitor parameter for the entered agreementMonitorParameterId.
	*/
	public deletePricingAgreementMonitorParameter(
							   pathParams: DeletePricingAgreementMonitorParameterPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitorparameters/{agreementMonitorParameterId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Creates a Pricing Agreement Monitor Action
	* <br/>PreCondition
	* <br/>valid values is entered: Action Type, Action Taken, Comment Text
	* <br/>Post Condition:
	* <br/>Create the pricing agreement monitor action for the above specified details.
	*/
	public createPricingAgreementMonitorAction(request: CreatePricingAgreementMonitorActionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementMonitorActionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/{pricingAgreementMonitorId}/actions'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Gets pricing agreement region rollup report
	* <br/>PreCondition
	* <br/>A valid user is entered
	* <br/>Post Condition:
	* <br/>Returns a pricing agreement rollup report for the user
	*/
	public getPricingAgreementMonitorRegionRollupReport(
							   pathParams: GetPricingAgreementMonitorRegionRollupReportPath,
							   queryParams: GetPricingAgreementMonitorRegionRollupReportQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementMonitorRegionRollupReportResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/rollupreport/{requesterId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Re-assign the PAM from current owner to new owner.
	* <br/>PreCondition
	* <br/>One of the following valid values is entered:
	* <br/>list of Pam id, from owner id and to owner id to reassign the Pam
	* <br/>Post Condition:
	* <br/>Returns PAM Counts for reassigned sales rfp.
	*/
	public reassignPricingAgreementMonitor(request: ReassignPricingAgreementMonitorRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ReassignPricingAgreementMonitorResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/reassign'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Lists valid actions for a pricing agreement monitor
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns valid actions for a pricing agreement monitor
	*/
	public listPricingAgreementMonitorValidActions(
							   pathParams: ListPricingAgreementMonitorValidActionsPath,
							   queryParams: ListPricingAgreementMonitorValidActionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPAMValidActionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/{agreementMonitorId}/validactions'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* pricing agreement monitor open date.
	* <br/>PreCondition
	* <br/>there is no input
	* <br/>Post Condition:
	* <br/>Returns date from pra agreement monitor date .
	*/
	public listPraAgreementMonitorRunDate(
							   queryParams: ListPraAgreementMonitorRunDateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementMonitorRunDateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementmonitors/rundate'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists pricing agreement Audit records.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement audit records for the above specified details.
	*/
	public listPricingAgreementAudits(
							   queryParams: ListPricingAgreementAuditsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementAuditResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists pricing agreement recent Audit records.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement recent audit records for the above specified details.
	*/
	public listPricingAgreementRecentAudits(
							   pathParams: ListPricingAgreementRecentAuditsPath,
							   queryParams: ListPricingAgreementRecentAuditsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementAuditResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{loginUserId}/recent'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists pricing agreement next revision records.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement next revision records for the above specified details.
	*/
	public listPricingAgreementNextRevisionAudits(
							   pathParams: ListPricingAgreementNextRevisionAuditsPath,
							   queryParams: ListPricingAgreementNextRevisionAuditsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementAuditResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{leadAcctId}/next-revision'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates 1 or more the pricing agreement audit header.
	* <br/>1. Input data are valid.
	* <br/>Post-conditions:
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing agreement audit header details will be persisted.
	*/
	public updatePricingAgreementAuditHeader(request: UpdatePricingAgreementAuditHeaderRqst,
							   queryParams: UpdatePricingAgreementAuditHeaderQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdatePricingAgreementAuditHeaderResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Gets pricing agreeemnt Audit Details.
	* <br/>PreCondition
	* <br/>A valid agreement audit header id is entered.
	* <br/>Post Condition:
	* <br/>Returns a pricing agreement audit details for the entered agreement audit header Id.
	*/
	public getPricingAgreementAuditDetail(
							   pathParams: GetPricingAgreementAuditDetailPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementAuditDetailResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{agreementAuditHdrId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates comment including attchment for agreement audit hdr.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a agreement audit hdr comment input. Agreement audit hdr Id, Comment details , file and file locations is input.
	* <br/>Post Condition:
	* <br/>Agreement Audit Hdr comment and parent Audit Hdr Rfp id is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Audit Hdr ID input or
	* <br/>Invalid support document input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createPricingAgreementAuditComment(request: CreatePricingAgreementAuditCommentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementAuditCommentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{agreementAuditHdrId}/comments'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates audit issue including attchment for agreement audit hdr.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for creating a agreement audit issue input. Agreement issue hdr Id, Issue details , file and file locations is input.
	* <br/>Post Condition:
	* <br/>Agreement Audit Hdr comment and parent Audit Hdr Rfp id is returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Audit Hdr ID input or
	* <br/>Invalid support document input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createPricingAgreementAuditIssue(request: CreatePricingAgreementAuditIssueRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementAuditIssueResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{agreementAuditHdrId}/issues'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Update issue including attchment for agreement audit hdr.
	* <br/>PreCondition
	* <br/>Minimum manadatory data for updating agreement audit hdr issue input. Agreement audit hdr Id, issue details , file and file locations is input.
	* <br/>Post Condition:
	* <br/>Agreement Audit Hdr issue is returned
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Audit Hdr ID input or
	* <br/>Invalid support document input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public updatePricingAgreementAuditIssue(request: UpdatePricingAgreementAuditIssueRqst,
							   queryParams: UpdatePricingAgreementAuditIssueQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdatePricingAgreementAuditIssueResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{agreementAuditHdrId}/issues/{agreementAuditIssueId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Delete the Pricing Agreement Audit Issue for input Audit Issue Id
	* <br/>Pre-conditions:
	* <br/>1. Input data are valid.
	* <br/>Post-conditions:
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public deletePricingAgreementAuditIssue(
							   pathParams: DeletePricingAgreementAuditIssuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeletePricingAgreementAuditIssueResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{agreementAuditHdrId}/issues/{agreementAuditIssueId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Delete the Pricing Agreement Audit Event Log for input Audit event log Id
	* <br/>Pre-conditions:
	* <br/>1. Input data are valid.
	* <br/>Post-conditions:
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public deletePricingAgreementAuditEventLog(
							   pathParams: DeletePricingAgreementAuditEventLogPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/eventlogs/{agreementAuditEventLogId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Creates document for either an AuditComment or an Audit Issue.
	* <br/>PreCondition
	* <br/>	Agreement audit id is required along with either a Comment Id or Issue id depending upon the document file.
	* <br/>Post Condition:
	* <br/>	Status code is returned
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Audit Hdr ID input or
	* <br/>Invalid support document input
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createPricingAgreementAuditDocument(request: CreatePricingAgreementAuditDocumentRqst,
							   queryParams: CreatePricingAgreementAuditDocumentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementAuditDocumentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{agreementAuditHdrId}/documents'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Given a unique sales rfp id and document id get sales rfp supporting document file
	* <br/>Pre Condition
	* <br/>A valid Pricing Agreement Audit Header Id and is input and there are supporting documents associated with this Id.
	* <br/>Post Condition:
	* <br/>A supporting document for input document id and related to the Agreement Audit header is returned.
	* <br/>Pre Condition
	* <br/>Input Agreement Audit Header Id is invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public getPricingAgreementAuditDocument(
							   pathParams: GetPricingAgreementAuditDocumentPath,
							   queryParams: GetPricingAgreementAuditDocumentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementaudits/{agreementAuditHdrId}/documents'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates pricing agreeemnt audit parameter.
	* <br/>PreCondition
	* <br/>valid input values are entered
	* <br/>Post Condition:
	* <br/>Create a pricing agreement audit parameter for the above specified details.
	*/
	public createPricingAgreementAuditParameter(request: CreatePricingAgreementAuditParameterRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementAuditParameterResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementauditparameters'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes pricing agreeemnt audit parameter.
	* <br/>PreCondition
	* <br/>A valid agreementAuditParameterId is entered.
	* <br/>Post Condition:
	* <br/>Deletes a pricing agreement audit parameter for the entered agreementAuditParameterId.
	*/
	public deletePricingAgreementAuditParameter(
							   pathParams: DeletePricingAgreementAuditParameterPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementauditparameters/{agreementAuditParameterId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Gets pricing agreeemnt audit parameter.
	* <br/>PreCondition
	* <br/>A valid agreementAuditParameterId is entered.
	* <br/>Post Condition:
	* <br/>Returns a pricing agreement audit parameter for the entered agreementAuditParameterId.
	*/
	public getPricingAgreementAuditParameter(
							   pathParams: GetPricingAgreementAuditParameterPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementAuditParameterResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementauditparameters/{agreementAuditParameterId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists pricing agreeemnt audit parameters.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement audit parameters for the above specified details.
	*/
	public listPricingAgreementAuditParameters(
							   queryParams: ListPricingAgreementAuditParametersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementAuditParameterResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreementauditparameters'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List pricing navigation bar counts for Audit, PAM, Queue and RFP.
	* <br/>PreCondition
	* <br/>None.
	* <br/>Post Condition:
	* <br/>Returns pricing agreement monitor record counts for the above specified details.
	*/
	public listPrcNavigationBarCounts(
							   queryParams: ListPrcNavigationBarCountsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingNavigationBarCountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricing-navigation-bar/counts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves a list of pricig agreement doc hdrs for a specified account id.
	* <br/>PreCondition
	* <br/>A valid account id to be specified.
	* <br/>Post Condition:
	* <br/>Retrieves all the agreement headers and documents for the specified account id (either corporate or P&D and Bill-To.
	*/
	public listPricingAgreementDocHdrs(
							   queryParams: ListPricingAgreementDocHdrsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingAgreementDocResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates agreement document hdr details.
	* <br/>PreCondition
	* <br/>Agreement Doc header details is input.
	* <br/>Post Condition:
	* <br/>Agreement doc header details are returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Doc Hdr input 
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createPricingAgreementDocHeader(request: CreatePricingAgreementDocHdrRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementDocHdrResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates document for pricing agreement document Header.
	* <br/>PreCondition
	* <br/>	Agreement doc id is required.
	* <br/>Post Condition:
	* <br/>	Status code is returned
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Doc Hdr ID input 
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public createPricingAgreementDocument(request: CreatePricingAgreementDocumentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePricingAgreementDocumentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs/{agreementDocHdrId}/documents'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Given a unique pricing document header id and document id get pricing agreement document file
	* <br/>Pre Condition
	* <br/>A valid Pricing Agreement Doc Header Id and is input and there are documents associated with this Id.
	* <br/>Post Condition:
	* <br/>A agreement document for input document id and related to the Agreement Doc header is returned.
	* <br/>Pre Condition
	* <br/>Input Agreement Doc Header Id is invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public getPricingAgreementDocument(
							   pathParams: GetPricingAgreementDocumentPath,
							   queryParams: GetPricingAgreementDocumentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs/{agreementDocHdrId}/documents'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates agreement document hdr details.
	* <br/>PreCondition
	* <br/>Agreement Doc header details is input.
	* <br/>Post Condition:
	* <br/>Agreement doc header details are returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Doc Hdr ID input 
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public updatePricingAgreementDocHeader(request: UpdatePricingAgreementDocHdrRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdatePricingAgreementDocHdrResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Given a unique pricing document header id and list of document ids get pricing agreement documents file
	* <br/>Pre Condition
	* <br/>A valid Pricing Agreement Doc Header Id and is input and there are documents associated with this Id.
	* <br/>Post Condition:
	* <br/>A agreement document for input document id and related to the Agreement Doc header is returned.
	* <br/>Pre Condition
	* <br/>Input Agreement Doc Header Id is invalid
	* <br/>Post Condition
	* <br/>No data is returned
	* <br/>
	*/
	public getPricingAgreementDocuments(
							   pathParams: GetPricingAgreementDocumentsPath,
							   queryParams: GetPricingAgreementDocumentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs/{agreementDocHdrId}/doczip'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieves a list of pricig agreements and corp customers for a specified account id.
	* <br/>PreCondition
	* <br/>A valid account id to be specified.
	* <br/>Post Condition:
	* <br/>Retrieves all the pricing agreements and corp customer details for the given customer Id
	*/
	public searchPricingAgreementDocCustomer(
							   queryParams: SearchPricingAgreementDocCustomerQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchPricingAgreementCustomerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/customers'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Gets the agreement document hdr details.
	* <br/>PreCondition
	* <br/>Agreement Doc header Id is input.
	* <br/>Post Condition:
	* <br/>Agreement doc header details are returned.
	* <br/>
	* <br/>PreCondition
	* <br/>Invalid Agreement Doc Hdr ID input 
	* <br/>Post Condition:
	* <br/>Bad request error is returned.
	* <br/>
	*/
	public getPricingAgreementDocDetails(
							   pathParams: GetPricingAgreementDocDetailsPath,
							   queryParams: GetPricingAgreementDocDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPricingAgreementDocHdrResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs/{agreementDocHdrId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Delete the Pricing Agreement DOcument for input Document type Id
	* <br/>Pre-conditions:
	* <br/>1. Input data are valid.
	* <br/>Post-conditions:
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public deletePricingAgreementDocument(
							   pathParams: DeletePricingAgreementDocumentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs/{agreementDocHdrId}/documents/{docTypeId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Delete the Pricing Agreement Document Header for input Document header Id
	* <br/>Pre-conditions:
	* <br/>1. Input data are valid.
	* <br/>Post-conditions:
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, pricing analyst data is returned.
	*/
	public deletePricingAgreementDocHeader(
							   pathParams: DeletePricingAgreementDocHeaderPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreements/dochdrs/{agreementDocHdrId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Regenerate a pricing agreement. 
	* <br/>		
	* <br/>PreCondition: 
	* <br/>1. Valid values is entered: Agreement Id, customer number,effective date, external Indicator. 
	* <br/>
	* <br/>Post Condition: 
	* <br/>1. If successful, regenerate the pricing agreement for the above specified details.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public regeneratePricingAgreement(request: RegeneratePricingAgreementRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pricingagreement/regenerate'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Given a customer id or code gets the customer hierarchy
	*/
	public listPricingCustomerHierarchy(
							   queryParams: ListPricingCustomerHierarchyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPrcCustomerHierarchyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounthierarchy'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation lists customer account details for the requested account.
	* <br/>
	* <br/>Pre-condition:
	* <br/>A valid search criteria value (Account Code, Account Name or location) is provided.
	* <br/>
	* <br/>Post-Condition:
	* <br/>List of customer account details (account id, Customer account MAD Code, location type, name, address, credit information) are returned.
	*/
	public listPricingCustomerAccount(
							   queryParams: ListPricingCustomerAccountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingCustomerAccountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customeraccount'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation lists customer details for the requested account.
	* <br/>
	* <br/>Pre-condition:
	* <br/>A valid search criteria value (list of Account ID or list of Account Code) is provided.
	* <br/>
	* <br/>Post-Condition:
	* <br/>1. List of customer account details (account id, Customer account MAD Code, location type, name, address, credit information) are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listPricingCustomerDetails(
							   queryParams: ListPricingCustomerDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPricingCustomerDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customerdetails'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Submits the request for Transit time file as requested by the user. 
	* <br/>			Mandatory data: upto 10 postal codes, requestorEmailid.
	* <br/>			Optional parameters: directionCode: Valid values are: Inbound, Outbound or Both. If not supplied, then Outbound is assumed.
	* <br/>								 countryCode: Valid values are: US, CA or both. If not supplied, then US is assumed.
	* <br/>								 fileFormat: csv, txt, xls, watkins. If not supplied, then csv is assumed.
	* <br/>																 
	* <br/>			When the request is successfully received a status of 200 is returned. 
	* <br/>			If a mandatory data is missing then status code of 400 will be returned.
	* <br/>
	*/
	public submitTransitTimeFileRequest(request: SubmitTransitTimeFileRequestRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SubmitTransitTimeFileRequestResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/rfp-file-requests/transit-time'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(PricingApiService.PricingApiEndpoint);
	}
}
