
export enum BeaconOwnerTypeCd {
	CUSTOMER = 'Customer',
	DOOR_PLAN = 'DoorPlan',
	LANE = 'Lane',
	LOAD = 'Load',
	SCHEDULE = 'Schedule',
	SHIPMENT = 'Shipment',
	TRAILER = 'Trailer',
	TRIP = 'Trip'}

