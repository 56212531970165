
export enum ScoEventTypeCd {
	ARRIVE = 'Arrive',
	ASSIGN = 'Assign',
	COMPLETE = 'Complete',
	CONVERT = 'Convert',
	DEPART = 'Depart',
	DISPATCH = 'Dispatch',
	ENTER = 'Enter',
	LEAVE = 'Leave',
	LOGIN = 'Login',
	LOGOFF = 'Logoff',
	RELEASE = 'Release',
	RETURN = 'Return',
	START = 'Start',
	STOP = 'Stop',
	UNASSIGN = 'Unassign',
	UPDATE = 'Update'}
