
export enum RateQuoteAccessorialCd {
	AFTER_BUSINESS_HOURS_DELIVERY = 'AfterBusinessHoursDelivery',
	APPOINTMENT = 'Appointment',
	BLIND_SHIPMENT = 'BlindShipment',
	COD_CANCEL_CHANGE = 'CODCancelChange',
	CONTAINER_SPOT_DELIVERY = 'ContainerSpotDelivery',
	CONTAINER_STATION = 'ContainerStation',
	CORRECTION_TO_BOL = 'CorrectionToBOL',
	CUBIC_CAPACITY = 'CubicCapacity',
	DEST_CONSOLIDATION_HANDLING = 'DestConsolidationHandling',
	DEST_CONSTRUCTION_UTILITY = 'DestConstructionUtility',
	DEST_HOLIDAY_WEEKEND = 'DestHolidayWeekend',
	DEST_INSIDE_DELIVERY = 'DestInsideDelivery',
	DEST_MINE_GOVT_AIRPORT = 'DestMineGovtAirport',
	DEST_RECONSIGNMENT = 'DestReconsignment',
	DEST_SORTING_SEGREGATING = 'DestSortingSegregating',
	EXCESSIVE_LENGTH = 'ExcessiveLength',
	EXCESSIVE_VALUE_CHARGE = 'ExcessiveValueCharge',
	EXHIBITION_DESTINATION = 'ExhibitionDestination',
	EXHIBITION_ORIG = 'ExhibitionOrig',
	FREEZABLE_PROTECTION = 'FreezableProtection',
	GUARANTEED_BY_NOON = 'GuaranteedByNoon',
	INSPECTION_OF_SHIPMENT = 'InspectionOfShipment',
	INSTANT_GUARANTEED_SERVICE = 'InstantGuaranteedService',
	IN_BOND_CUSTOMS_INSP_DELAY = 'InBondCustomsInspDelay',
	IN_BOND_CUSTOMS_REL_FORMS = 'InBondCustomsRelForms',
	IN_BOND_FREIGHT = 'InBondFreight',
	IN_BOND_TIR_CARNET = 'InBondTIRCarnet',
	LIFT_GATE_SERVICE = 'LiftGateService',
	LIFT_GATE_SERVICES = 'LiftGateServices',
	NOTIFICATION_PRIOR_TO_DELIVERY = 'NotificationPriorToDelivery',
	ORIG_CONSOLIDATION_HANDLING = 'OrigConsolidationHandling',
	ORIG_CONSTRUCTION_UTILITY = 'OrigConstructionUtility',
	ORIG_CONTAINER_STATION = 'OrigContainerStation',
	ORIG_HOLIDAY_WEEKEND = 'OrigHolidayWeekend',
	ORIG_INSIDE_PICKUP = 'OrigInsidePickup',
	ORIG_MINE_GOVT_AIRPORT = 'OrigMineGovtAirport',
	ORIG_RECONSIGNMENT = 'OrigReconsignment',
	ORIG_SORTING_SEGREGATING = 'OrigSortingSegregating',
	PIERS_WHARVES_LOADING = 'PiersWharvesLoading',
	PIERS_WHARVES_UNLOADING = 'PiersWharvesUnloading',
	RAPID_REMOTE_SERVICE = 'RapidRemoteService',
	REDELIVERY = 'Redelivery',
	RESIDENTIAL_DESTINATION = 'ResidentialDestination',
	RESIDENTIAL_ORIG = 'ResidentialOrig',
	RETURN_CHECK = 'ReturnCheck',
	RETURN_UNDELIVERED_SHIPMENT = 'ReturnUndeliveredShipment',
	SINGLE_SHIPMENT = 'SingleShipment',
	SUFFERANCE_CANADA_NBONQC = 'SufferanceCanadaNBONQC',
	SUFFERANCE_CANADA_OTHER = 'SufferanceCanadaOther',
	SUFFERANCE_EXAMINATION = 'SufferanceExamination',
	SUFFERANCE_HANDLING = 'SufferanceHandling',
	SUFFERANCE_INSPECTION_DELAY = 'SufferanceInspectionDelay',
	SUFFERANCE_REMANIFEST = 'SufferanceRemanifest',
	TIME_DATE_CRITICAL = 'TimeDateCritical',
	WEIGH_REWEIGH_OF_SHIPMENT = 'WeighReweighOfShipment',
	WILL_CALL = 'WillCall'}
