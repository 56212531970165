import {ProNbrTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProNbrTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProNbrTypeCd ) {
      this._values.push(ProNbrTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProNbrTypeCd {

    for ( const obj in ProNbrTypeCd ) {
      if (ProNbrTypeCd[obj] === value){
        return ProNbrTypeCd[obj] as ProNbrTypeCd;
      }
    }
  }
}

const ProNbrTypeCdHelper = new ProNbrTypeCdHelperEnumHelperClass();
export default ProNbrTypeCdHelper;
