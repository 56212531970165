import {TariffTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class TariffTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TariffTypeCd ) {
      this._values.push(TariffTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TariffTypeCd {

    for ( const obj in TariffTypeCd ) {
      if (TariffTypeCd[obj] === value){
        return TariffTypeCd[obj] as TariffTypeCd;
      }
    }
  }
}

const TariffTypeCdHelper = new TariffTypeCdHelperEnumHelperClass();
export default TariffTypeCdHelper;
