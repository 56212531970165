import {HookCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class HookCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HookCategoryCd ) {
      this._values.push(HookCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HookCategoryCd {

    for ( const obj in HookCategoryCd ) {
      if (HookCategoryCd[obj] === value){
        return HookCategoryCd[obj] as HookCategoryCd;
      }
    }
  }
}

const HookCategoryCdHelper = new HookCategoryCdHelperEnumHelperClass();
export default HookCategoryCdHelper;
