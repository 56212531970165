import {EquipmentEventReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentEventReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentEventReasonCd ) {
      this._values.push(EquipmentEventReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentEventReasonCd {

    for ( const obj in EquipmentEventReasonCd ) {
      if (EquipmentEventReasonCd[obj] === value){
        return EquipmentEventReasonCd[obj] as EquipmentEventReasonCd;
      }
    }
  }
}

const EquipmentEventReasonCdHelper = new EquipmentEventReasonCdHelperEnumHelperClass();
export default EquipmentEventReasonCdHelper;
