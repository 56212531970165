import {RwgLogHeaderMoreInfoCd} from '../';
import {EnumHelper} from './enum-helper';

export class RwgLogHeaderMoreInfoCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RwgLogHeaderMoreInfoCd ) {
      this._values.push(RwgLogHeaderMoreInfoCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RwgLogHeaderMoreInfoCd {

    for ( const obj in RwgLogHeaderMoreInfoCd ) {
      if (RwgLogHeaderMoreInfoCd[obj] === value){
        return RwgLogHeaderMoreInfoCd[obj] as RwgLogHeaderMoreInfoCd;
      }
    }
  }
}

const RwgLogHeaderMoreInfoCdHelper = new RwgLogHeaderMoreInfoCdHelperEnumHelperClass();
export default RwgLogHeaderMoreInfoCdHelper;
