import {ShipmentDirectionCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentDirectionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentDirectionCd ) {
      this._values.push(ShipmentDirectionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentDirectionCd {

    for ( const obj in ShipmentDirectionCd ) {
      if (ShipmentDirectionCd[obj] === value){
        return ShipmentDirectionCd[obj] as ShipmentDirectionCd;
      }
    }
  }
}

const ShipmentDirectionCdHelper = new ShipmentDirectionCdHelperEnumHelperClass();
export default ShipmentDirectionCdHelper;
