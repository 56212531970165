import {CorrectionReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class CorrectionReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CorrectionReasonCd ) {
      this._values.push(CorrectionReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CorrectionReasonCd {

    for ( const obj in CorrectionReasonCd ) {
      if (CorrectionReasonCd[obj] === value){
        return CorrectionReasonCd[obj] as CorrectionReasonCd;
      }
    }
  }
}

const CorrectionReasonCdHelper = new CorrectionReasonCdHelperEnumHelperClass();
export default CorrectionReasonCdHelper;
