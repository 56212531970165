import {BondTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BondTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BondTypeCd ) {
      this._values.push(BondTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BondTypeCd {

    for ( const obj in BondTypeCd ) {
      if (BondTypeCd[obj] === value){
        return BondTypeCd[obj] as BondTypeCd;
      }
    }
  }
}

const BondTypeCdHelper = new BondTypeCdHelperEnumHelperClass();
export default BondTypeCdHelper;
