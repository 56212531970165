import {ExternalShipmentStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExternalShipmentStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExternalShipmentStatusCd ) {
      this._values.push(ExternalShipmentStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExternalShipmentStatusCd {

    for ( const obj in ExternalShipmentStatusCd ) {
      if (ExternalShipmentStatusCd[obj] === value){
        return ExternalShipmentStatusCd[obj] as ExternalShipmentStatusCd;
      }
    }
  }
}

const ExternalShipmentStatusCdHelper = new ExternalShipmentStatusCdHelperEnumHelperClass();
export default ExternalShipmentStatusCdHelper;
