import {TariffOperatorCd} from '../';
import {EnumHelper} from './enum-helper';

export class TariffOperatorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TariffOperatorCd ) {
      this._values.push(TariffOperatorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TariffOperatorCd {

    for ( const obj in TariffOperatorCd ) {
      if (TariffOperatorCd[obj] === value){
        return TariffOperatorCd[obj] as TariffOperatorCd;
      }
    }
  }
}

const TariffOperatorCdHelper = new TariffOperatorCdHelperEnumHelperClass();
export default TariffOperatorCdHelper;
