import {PickupRequestReasonTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupRequestReasonTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupRequestReasonTypeCd ) {
      this._values.push(PickupRequestReasonTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupRequestReasonTypeCd {

    for ( const obj in PickupRequestReasonTypeCd ) {
      if (PickupRequestReasonTypeCd[obj] === value){
        return PickupRequestReasonTypeCd[obj] as PickupRequestReasonTypeCd;
      }
    }
  }
}

const PickupRequestReasonTypeCdHelper = new PickupRequestReasonTypeCdHelperEnumHelperClass();
export default PickupRequestReasonTypeCdHelper;
