import {PickupRequestReasonSubCategoryRefCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupRequestReasonSubCategoryRefCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupRequestReasonSubCategoryRefCd ) {
      this._values.push(PickupRequestReasonSubCategoryRefCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupRequestReasonSubCategoryRefCd {

    for ( const obj in PickupRequestReasonSubCategoryRefCd ) {
      if (PickupRequestReasonSubCategoryRefCd[obj] === value){
        return PickupRequestReasonSubCategoryRefCd[obj] as PickupRequestReasonSubCategoryRefCd;
      }
    }
  }
}

const PickupRequestReasonSubCategoryRefCdHelper = new PickupRequestReasonSubCategoryRefCdHelperEnumHelperClass();
export default PickupRequestReasonSubCategoryRefCdHelper;
