
export enum ApiTypeAttributeDataTypeCd {
	ANY_TYPE = 'anyType',
	ANY_URI = 'anyURI',
	BASE_64_BINARY = 'base64Binary',
	BOOLEAN = 'boolean',
	BYTE = 'byte',
	DATE = 'date',
	DATE_TIME = 'dateTime',
	DECIMAL = 'decimal',
	DOUBLE = 'double',
	INT = 'int',
	INTEGER = 'integer',
	LONG = 'long',
	SHORT = 'short',
	STRING = 'string',
	TIME = 'time'}

