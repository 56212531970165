import {RevenueDayCd} from '../';
import {EnumHelper} from './enum-helper';

export class RevenueDayCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RevenueDayCd ) {
      this._values.push(RevenueDayCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RevenueDayCd {

    for ( const obj in RevenueDayCd ) {
      if (RevenueDayCd[obj] === value){
        return RevenueDayCd[obj] as RevenueDayCd;
      }
    }
  }
}

const RevenueDayCdHelper = new RevenueDayCdHelperEnumHelperClass();
export default RevenueDayCdHelper;
