
export enum ShipmentDetailCd {
	ACCESSORIAL = 'Accessorial',
	ADVANCE_BEYOND = 'AdvanceBeyond',
	APPOINTMENT_REQUIRED = 'AppointmentRequired',
	BILL_ENTRY_STATS = 'BillEntryStats',
	CHILD_SHIPMENT = 'ChildShipment',
	COMMODITY = 'Commodity',
	COMMODITY_DIMENSIONS = 'CommodityDimensions',
	COUNTRY_CODES = 'CountryCodes',
	CUSTOMS_BOND = 'CustomsBond',
	EVENT_LOG = 'EventLog',
	HANDLING_UNIT = 'HandlingUnit',
	HANDLING_UNIT_MOVEMENT = 'HandlingUnitMovement',
	LINEHAUL_DIMENSIONS = 'LinehaulDimensions',
	MANAGEMENT_REMARK = 'ManagementRemark',
	MISC_LINE_ITEM = 'MiscLineItem',
	MOVEMENT_EXCEPTION = 'MovementException',
	NOTIFICATION = 'Notification',
	NO_SHIPMENT = 'NoShipment',
	OPERATIONS_SHIPMENT = 'OperationsShipment',
	RATING_INFO = 'RatingInfo',
	REMARKS = 'Remarks',
	RULE_OVERRIDE = 'RuleOverride',
	SHIPMENT_BILL_OF_LADING = 'ShipmentBillOfLading',
	SHIPMENT_HIST = 'ShipmentHist',
	SHIPMENT_ONLY = 'ShipmentOnly',
	SHIPMENT_PARTIES = 'ShipmentParties',
	SIBLING_SHIPMENT = 'SiblingShipment',
	SPECIAL_SERVICES = 'SpecialServices',
	SUPP_REF_NBR = 'SuppRefNbr',
	TIME_DATE_CRITICAL = 'TimeDateCritical',
	XDOCK_EXCEPTION = 'XdockException'}

