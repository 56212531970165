import {HookStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class HookStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HookStatusCd ) {
      this._values.push(HookStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HookStatusCd {

    for ( const obj in HookStatusCd ) {
      if (HookStatusCd[obj] === value){
        return HookStatusCd[obj] as HookStatusCd;
      }
    }
  }
}

const HookStatusCdHelper = new HookStatusCdHelperEnumHelperClass();
export default HookStatusCdHelper;
