import {PickupRequestHoldTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupRequestHoldTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupRequestHoldTypeCd ) {
      this._values.push(PickupRequestHoldTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupRequestHoldTypeCd {

    for ( const obj in PickupRequestHoldTypeCd ) {
      if (PickupRequestHoldTypeCd[obj] === value){
        return PickupRequestHoldTypeCd[obj] as PickupRequestHoldTypeCd;
      }
    }
  }
}

const PickupRequestHoldTypeCdHelper = new PickupRequestHoldTypeCdHelperEnumHelperClass();
export default PickupRequestHoldTypeCdHelper;
