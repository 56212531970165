import {FbdsVersionCd} from '../';
import {EnumHelper} from './enum-helper';

export class FbdsVersionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FbdsVersionCd ) {
      this._values.push(FbdsVersionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FbdsVersionCd {

    for ( const obj in FbdsVersionCd ) {
      if (FbdsVersionCd[obj] === value){
        return FbdsVersionCd[obj] as FbdsVersionCd;
      }
    }
  }
}

const FbdsVersionCdHelper = new FbdsVersionCdHelperEnumHelperClass();
export default FbdsVersionCdHelper;
