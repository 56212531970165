import {PnDAutoPrintOptionCd} from '../';
import {EnumHelper} from './enum-helper';

export class PnDAutoPrintOptionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PnDAutoPrintOptionCd ) {
      this._values.push(PnDAutoPrintOptionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PnDAutoPrintOptionCd {

    for ( const obj in PnDAutoPrintOptionCd ) {
      if (PnDAutoPrintOptionCd[obj] === value){
        return PnDAutoPrintOptionCd[obj] as PnDAutoPrintOptionCd;
      }
    }
  }
}

const PnDAutoPrintOptionCdHelper = new PnDAutoPrintOptionCdHelperEnumHelperClass();
export default PnDAutoPrintOptionCdHelper;
