import {FreightStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class FreightStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FreightStatusCd ) {
      this._values.push(FreightStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FreightStatusCd {

    for ( const obj in FreightStatusCd ) {
      if (FreightStatusCd[obj] === value){
        return FreightStatusCd[obj] as FreightStatusCd;
      }
    }
  }
}

const FreightStatusCdHelper = new FreightStatusCdHelperEnumHelperClass();
export default FreightStatusCdHelper;
