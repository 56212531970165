import {CustomerNotificationPriorityCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerNotificationPriorityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerNotificationPriorityCd ) {
      this._values.push(CustomerNotificationPriorityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerNotificationPriorityCd {

    for ( const obj in CustomerNotificationPriorityCd ) {
      if (CustomerNotificationPriorityCd[obj] === value){
        return CustomerNotificationPriorityCd[obj] as CustomerNotificationPriorityCd;
      }
    }
  }
}

const CustomerNotificationPriorityCdHelper = new CustomerNotificationPriorityCdHelperEnumHelperClass();
export default CustomerNotificationPriorityCdHelper;
