import {OriginalSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class OriginalSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OriginalSourceCd ) {
      this._values.push(OriginalSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OriginalSourceCd {

    for ( const obj in OriginalSourceCd ) {
      if (OriginalSourceCd[obj] === value){
        return OriginalSourceCd[obj] as OriginalSourceCd;
      }
    }
  }
}

const OriginalSourceCdHelper = new OriginalSourceCdHelperEnumHelperClass();
export default OriginalSourceCdHelper;
