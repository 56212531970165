/**
 * Location API
 * Version: 2.0
 * Build: 2.0.0.34
 */

import {
	DataValidationError,
	ListInfo,
	Address,
	LatLong,
	AuditInfo,
	LocationDetailCd,
	LocationHierarchyTypeCd,
	NextBusinessDayOperationCd,
	NonWorkDayTypeCd,
	PolygonTypeCd,
	ServiceTypeCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to delete a location feature.
 */
export class DeleteLocationFeatureSettingsQuery {
	/**
	 * The list of unique identifiers which are associated with Location Feature Settings.
	 * Required: false
	 * Array: true
	 */
	instIds:number[] = undefined;
	/**
	 * The unique identifier for a feature.
	 * Required: false
	 * Array: false
	 */
	featureCd:string = undefined;
	/**
	 * The unique identifier for a SIC.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Determines whether the date provided is a non-work date for the location.
 */
export class DetermineNonWorkDayPath {
	/**
	 * The service center identification
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The date for which this operation will determine whether it is a non-work day for the location.
	 * Required: true
	 * Array: false
	 */
	date:string = undefined;
}
/**
 * Response to the request to determine whether the date provided is a non-work date for the location.
 */
export class DetermineNonWorkDayResp {
	/**
	 * The code that determines the type of non-work day.
	 * Required: true
	 * Array: false
	 */
	nonWorkDayTypeCd:NonWorkDayTypeCd = undefined;
	/**
	 * A description of the non-work day.
	 * Required: true
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Indicates whether Linehaul runs on a non-work day.
	 * Required: true
	 * Array: false
	 */
	linehaulInd:number = undefined;
}
/**
 * Path parameters which are used to determine the service date based on offset.
 */
export class DetermineOperationalServiceDatePath {
	/**
	 * The service center identification code for the origin location where the service will start.
	 * Required: true
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * The service center identification code for the destination location where the service will end.
	 * Required: true
	 * Array: false
	 */
	destinationSicCd:string = undefined;
}
/**
 * Query parameters which are used to determine the service date based on offset.
 */
export class DetermineOperationalServiceDateQuery {
	/**
	 * The calendar date for which determination of service date based on offset will start.
	 * Required: false
	 * Array: false
	 */
	calendarDate:string = undefined;
	/**
	 * The code associated with the type of service.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ServiceTypeCd = undefined;
	/**
	 * The number of days to add to the calendar date.
	 * Required: false
	 * Array: false
	 */
	offsetDaysCount:number = undefined;
	/**
	 * Indicates the next service date should be returned if the offset of 0 returns a non-service date.
	 * Required: false
	 * Array: false
	 */
	findNextServiceDateInd:boolean = undefined;
}
/**
 * Response to the request to determine the service date based on offset.
 */
export class DetermineOperationalServiceDateResp {
	/**
	 * The service date based on the calendar date and offset provided.
	 * Required: false
	 * Array: false
	 */
	serviceDate:string = undefined;
}
/**
 * Request to return the geo area for a location.
 */
export class GetGeoAreasForLocationPath {
	/**
	 * Unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: true
	 * Array: false
	 */
	polygonTypeCd:string = undefined;
}
/**
 * Request to return the geo area for a location.
 */
export class GetGeoAreasForLocationQuery {
	/**
	 * The date used to validate active satellite sics.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Indicates whether satellite SICs for the parent should be included in the response.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
	/**
	 * Indicates whether geoArea shape data should be included in the response.
	 * Required: false
	 * Array: false
	 */
	geoAreaShapeInd:boolean = undefined;
}
/**
 * Response to the request to return the geo area for a location.
 */
export class GetGeoAreasForLocationResp {
	/**
	 * Details about a geo area.
	 * Required: false
	 * Array: true
	 */
	geoArea:GeoArea[] = undefined;
}
/**
 * Query parameters which are used to retrieve location reference details.
 */
export class GetLocationReferenceDetailsQuery {
	/**
	 * The service center identification code for a location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The unique identifier for a location.
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * The fields that should be returned as part of the response body.
	 * Required: false
	 * Array: true
	 */
	locationDetailCds:LocationDetailCd[] = undefined;
}
/**
 * Response to the request to retrieve location reference details.
 */
export class GetLocationReferenceDetailsResp {
	/**
	 * The location reference details.
	 * Required: false
	 * Array: false
	 */
	locationReference:LocationReference = undefined;
	/**
	 * The Company Operations details.
	 * Required: false
	 * Array: false
	 */
	companyOperations:CompanyOperations = undefined;
	/**
	 * The ConWay Corporation details.
	 * Required: false
	 * Array: false
	 */
	conWayCorporation:ConWayCorporation = undefined;
	/**
	 * The charge to service center identification code.
	 * Required: false
	 * Array: false
	 */
	chargeToSicCd:string = undefined;
	/**
	 * The name of the charge to SIC.
	 * Required: false
	 * Array: false
	 */
	chargeToSicName:string = undefined;
	/**
	 * The area service center identification code.
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * The region service center identification code.
	 * Required: false
	 * Array: false
	 */
	regionSicCd:string = undefined;
	/**
	 * The satellite service center identification code.
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicCd:string = undefined;
	/**
	 * The name of the satellitle parent SIC.
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicName:string = undefined;
	/**
	 * >The zone parent service center identification code.
	 * Required: false
	 * Array: false
	 */
	zoneParentSicCd:string = undefined;
	/**
	 * The name of the zone parent SIC.
	 * Required: false
	 * Array: false
	 */
	zoneParentSicName:string = undefined;
	/**
	 * The linehaul service center identification code.
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicCd:string = undefined;
	/**
	 * The name of the linehaul host SIC.
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicName:string = undefined;
	/**
	 * The location Hierarchy Group details for the region.
	 * Required: false
	 * Array: false
	 */
	regionLocationHierarchyGroup:HierarchyGroup = undefined;
	/**
	 * The location Hierarchy Group details for the area.
	 * Required: false
	 * Array: false
	 */
	areaLocationHierarchyGroup:HierarchyGroup = undefined;
}
/**
 * Path parameter used to return operation service center profitability details for the sicCd provided.
 */
export class GetLocOperationsServiceCenterProfitabilityBySicPath {
	/**
	 * The unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to return operation service center profitability details for the sicCd provided.
 */
export class GetLocOperationsServiceCenterProfitabilityBySicResp {
	/**
	 * The operation service center profitability details for a location.
	 * Required: false
	 * Array: false
	 */
	locOperationsServiceCentersProfitability:LocOperationsSvccProfitability = undefined;
}
/**
 * Path parameters which are used to calculate the service days count.
 */
export class GetOperationalServiceDaysCountPath {
	/**
	 * The service center identification code for the origin location where the service started.
	 * Required: true
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * The service center identification code for the destination location where the service ended.
	 * Required: true
	 * Array: false
	 */
	destinationSicCd:string = undefined;
}
/**
 * Query parameters which are used to calculate the service days count.
 */
export class GetOperationalServiceDaysCountQuery {
	/**
	 * The date for which the service started.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The date for which the service ended.
	 * Required: false
	 * Array: false
	 */
	endDate:string = undefined;
	/**
	 * The code associated with the type of service.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ServiceTypeCd = undefined;
}
/**
 * Response to the request to calculate the service days.
 */
export class GetOperationalServiceDaysCountResp {
	/**
	 * The number of days it took to complete the service.
	 * Required: false
	 * Array: false
	 */
	serviceDaysCount:number = undefined;
}
/**
 * Request to return the sector by the door number provided.
 */
export class GetSectorByDoorNumberPath {
	/**
	 * The location for which the sector is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number for which the sector is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	doorNumber:number = undefined;
}
/**
 * Response to the request to return the sector by the door number provided.
 */
export class GetSectorByDoorNumberResp {
	/**
	 * The sector number associated with the door number.
	 * Required: false
	 * Array: false
	 */
	sectorNumber:number = undefined;
	/**
	 * The location for which the sector is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number for which the sector is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	doorNumber:number = undefined;
}
/**
 * Query parameters used to retrieve the SIC assigned to the postal codes provided.
 */
export class GetSicForPostalCodesQuery {
	/**
	 * The list of US or CA postal codes for which the SIC will be retrieved.
	 * Required: true
	 * Array: true
	 */
	postalCds:string[] = undefined;
}
/**
 * Response to the request to retrieve the SIC assigned to the postal codes provided.
 */
export class GetSicForPostalCodesResp {
	/**
	 * The list of SICs that are assigned to the postal codes provided along with additional details.
	 * Required: false
	 * Array: true
	 */
	postalSicAssignments:PostalSicAssignment[] = undefined;
	/**
	 * The validation error details for the list of invalid postal codes.
	 * Required: false
	 * Array: true
	 */
	validationErrors:DataValidationError[] = undefined;
}
/**
 * Request to retrieve all active doors by SIC.
 */
export class ListAllActiveDoorsBySicPath {
	/**
	 * The code associated with the service center for which all active doors will be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameters used to return the doors for a location.
 */
export class ListAllActiveDoorsBySicQuery {
	/**
	 * The area inst id for which the door is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	areaInstId:number = undefined;
}
/**
 * Response to the request to list all active doors by a service center.
 */
export class ListAllActiveDoorsBySicResp {
	/**
	 * The list of active doors that are associated with the SIC provided.
	 * Required: false
	 * Array: true
	 */
	activeDoors:Door[] = undefined;
}
/**
 * Query parameters used to filter the list of area assignments returned.
 */
export class ListAreaAssignmentsQuery {
	/**
	 * The unique identifier for the area assignment is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * The service center identification code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to list area assignments that match the input criteria.
 */
export class ListAreaAssignmentsResp {
	/**
	 * The list of area assignments that match the input criteria.
	 * Required: false
	 * Array: true
	 */
	areaAssignments:AreaAssignment[] = undefined;
}
/**
 * Path parameter used to list geo areas for locations.
 */
export class ListGeoAreasPath {
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: true
	 * Array: false
	 */
	polygonTypeCd:string = undefined;
}
/**
 * Query parameters used to list geo areas for locations.
 */
export class ListGeoAreasQuery {
	/**
	 * The list of geo area identifiers.
	 * Required: false
	 * Array: true
	 */
	areaInstIds:number[] = undefined;
	/**
	 * The list of service center identification codes.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
	/**
	 * The date used to validate active satellite SICs.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Indicates whether satellite SICs for the parent should be included in the response.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
	/**
	 * Indicates whether geo area shape data should be included in the response.
	 * Required: false
	 * Array: false
	 */
	geoAreaShapeInd:boolean = undefined;
}
/**
 * Response to the request to return the geo area for a location.
 */
export class ListGeoAreasResp {
	/**
	 * The list of geo areas associated with the locations provided.
	 * Required: false
	 * Array: true
	 */
	geoAreas:GeoArea[] = undefined;
}
/**
 * Request to list all active SICs.
 */
export class ListAllActiveSicsRqst {
}
/**
 * Response to the request to list all active service service centers and their region, city, state, and if they are a FAC or OB.
 */
export class ListAllActiveSicsResp {
	/**
	 * A list of active service centers and their details.
	 * Required: false
	 * Array: true
	 */
	activeSics:ActiveSic[] = undefined;
}
/**
 * Request to retrieve all sic codes by region.
 */
export class ListAllSicCdsByRegionPath {
	/**
	 * The code identifying the region.
	 * Required: true
	 * Array: false
	 */
	locGroupCode:string = undefined;
}
/**
 * Response to the request to list sic codes by region code.
 */
export class ListAllSicCdsByRegionResp {
	/**
	 * A list of the sic codes.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
}
/**
 * Query parameters used to filter the list location features returned.
 */
export class ListLocationFeaturesQuery {
	/**
	 * The unique identifier for the employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The unique identifier for the SIC.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code that represents the feature for which details are to be retrieved.
	 * Required: false
	 * Array: false
	 */
	featureCd:string = undefined;
	/**
	 * The value attached to the specific feature. For example - 'Y'.
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list location features that match the input criteria.
 */
export class ListLocationFeaturesResp {
	/**
	 * The list of location features that matched the input criteria.
	 * Required: false
	 * Array: true
	 */
	locationFeatures:FeatureSetting[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Query to retrieve a list location group codes.
 */
export class ListLocationGroupCodesQuery {
	/**
	 * The category code groups the SICs by linehaul region.
	 * Required: false
	 * Array: false
	 */
	groupCategoryCd:string = undefined;
	/**
	 * Used to filter the list by a specific location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to list operational service centers.
 */
export class ListLocationGroupCodesResp {
	/**
	 * A list of the regions along with the SICs that are part of that region.
	 * Required: false
	 * Array: true
	 */
	locationGroupCodes:LocationGroupCode[] = undefined;
}
/**
 * Query to list locations.
 */
export class ListLocationsQuery {
	/**
	 * Indicates if only active locations should be included.
	 * Required: false
	 * Array: false
	 */
	activeInd:boolean = undefined;
	/**
	 * Indicates if zone locations should be included.
	 * Required: false
	 * Array: false
	 */
	zoneDesiredInd:boolean = undefined;
	/**
	 * Indicates if satellite locations should be included.
	 * Required: false
	 * Array: false
	 */
	satelliteDesiredInd:boolean = undefined;
	/**
	 * Indicates if meet and turn SICs should be returned.
	 * Required: false
	 * Array: false
	 */
	meetAndTurnDesiredInd:boolean = undefined;
}
/**
 * Request to list locations
 */
export class ListLocationsRqst {
	/**
	 * List of SIC codes desired. If not supplied, list all locations based on the other criteria.
	 * Required: false
	 * Array: true
	 */
	refSicCd:string[] = undefined;
	/**
	 * A list of group categories to include.
	 * Required: false
	 * Array: true
	 */
	groupCategoryCd:string[] = undefined;
}
/**
 * Response to list locations and their zones and satellites, including its region, city, state, and if they are a FAC or OB.
 */
export class ListLocationsResp {
	/**
	 * A list of locations that match the input criteria.
	 * Required: false
	 * Array: true
	 */
	locationSic:LocationSic[] = undefined;
}
/**
 * Query parameter which is used to list the operational hierarchy.
 */
export class ListOperationalHierarchyQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list the operational hierarchy.
 */
export class ListOperationalHierarchyResp {
	/**
	 * The list of all operational regions, districts, SICs, zones, and satellites.
	 * Required: false
	 * Array: true
	 */
	operationalHierarchy:OperationalRegion[] = undefined;
}
/**
 * Request to list all operational regions with their districts and for each district all of its SICs.
 */
export class ListOperationalRegionsQuery {
	/**
	 * The type of location which will be used to filter the data. Possible values: REGION, DISTRICT and SIC.
	 * Required: false
	 * Array: false
	 */
	locationHierarchyTypeCd:LocationHierarchyTypeCd = undefined;
	/**
	 * The value used to filter the results based on the selected locationHierarchyTypeCd. The values should be the codes of the registry in the Database. Example: 
	 * 						LocationHierarchyTypeCd = 'REGION' and locationHierarchyFilters = ['CEA', 'CCA'], to filter by REGIONs Eastern and Mid-West
	 * 						LocationHierarchyTypeCd = 'DISTRICT' and locationHierarchyFilters = ['OAG', 'OCA'], to filter through the DISTRICTs 'ATLANTA DISTRICT' and 'CANADA DISTRICT'
	 * 						LocationHierarchyTypeCd = 'SIC' and locationHierarchyFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 * Required: false
	 * Array: true
	 */
	locationHierarchyFilters:string[] = undefined;
}
/**
 * Response wiht the of list all Operational Regions with their Districts, and for each District, all SICs..
 */
export class ListOperationalRegionsResp {
	/**
	 * A list of all operational regions which match the filter criteria.
	 * Required: false
	 * Array: true
	 */
	operationalRegions:OperationalRegion[] = undefined;
}
/**
 * Request to list operational service centers.
 */
export class ListOperationalServiceCentersQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list operational service centers.
 */
export class ListOperationalServiceCentersResp {
	/**
	 * A list of operational service centers.
	 * Required: false
	 * Array: true
	 */
	serviceCenters:ServiceCenter[] = undefined;
}
/**
 * Request to insert or update a location feature setting.
 */
export class UpsertLocationFeatureSettingRqst {
	/**
	 * The feature setting which will be created or update the existing entry.
	 * Required: true
	 * Array: false
	 */
	featureSetting:FeatureSetting = undefined;
}
/**
 * Response to the request to insert or update a location feature setting.
 */
export class UpsertLocationFeatureSettingResp {
	/**
	 * The feature setting which was created or updated the existing entry.
	 * Required: false
	 * Array: false
	 */
	featureSetting:FeatureSetting = undefined;
}
/**
 * Request to return the host SIC for a zone.
 */
export class GetHostSicDetailsPath {
	/**
	 * Unique identifier for the zone service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to return the host SIC for a zone.
 */
export class GetHostSicDetailsResp {
	/**
	 * Unique identifier for the zone service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Unique identifier for the host service center.
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
	/**
	 * Identifies if the SIC is zone or satellite.
	 * Required: false
	 * Array: false
	 */
	sicType:string = undefined;
}
/**
 * Request to retrieve location reference details for a service center location.
 */
export class GetLocReferenceDetailsBySicPath {
	/**
	 * The code identifying a given service center
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * The query parameter for the request to get the loc reference for a SIC.
 */
export class GetLocReferenceDetailsBySicQuery {
	/**
	 * The fields that should be returned as part of the response body.
	 * Required: false
	 * Array: true
	 */
	responseFields:LocationDetailCd[] = undefined;
}
/**
 * Response that contains location reference detail information for a service center location.
 */
export class GetLocReferenceDetailsBySicResp {
	/**
	 * Required: false
	 * Array: false
	 */
	locReference:LocationReference = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locCompanyOperations:CompanyOperations = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locConWayCorporation:ConWayCorporation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeToSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeToSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneParentSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneParentSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionLocHierarchyGroup:HierarchyGroup = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	areaLocHierarchyGroup:HierarchyGroup = undefined;
}
/**
 * Path parameter used to determine the next business day.
 */
export class GetNextBusinessDayPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameters used to determine the next business day.
 */
export class GetNextBusinessDayQuery {
	/**
	 * The date for which the next business day will be determined.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The number of days to add to to the start date.
	 * Required: false
	 * Array: false
	 */
	offsetDaysCount:number = undefined;
	/**
	 * Identifies the operation to be executed. If no value is passed, NextBusinessDay is used as the default.
	 * Required: false
	 * Array: false
	 */
	nextBusinessDayOperationCd:NextBusinessDayOperationCd = undefined;
}
/**
 * Response to the request to determine the next business day.
 */
export class GetNextBusinessDayResp {
	/**
	 * The next business day after adding offset days to the start date.
	 * Required: false
	 * Array: false
	 */
	nextBusinessDay:string = undefined;
	/**
	 * The date for which the next business day was determined.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The number of days to add to to the start date.
	 * Required: false
	 * Array: false
	 */
	offsetDaysCount:number = undefined;
	/**
	 * Identifies the type of non-work day.
	 * Required: false
	 * Array: false
	 */
	nonWorkDayTypeCd:NonWorkDayTypeCd = undefined;
	/**
	 * Indicates whether the given date is marked for linehaul. Possible values: Y or N.
	 * Required: false
	 * Array: false
	 */
	linehaulInd:string = undefined;
	/**
	 * Indicates whether the given date is marked for non-work. Possible values: Y or N.
	 * Required: false
	 * Array: false
	 */
	nonWorkDayInd:string = undefined;
	/**
	 * The revenue allocation for the given date.  This is use by the Sales team (including BI) to identify how a particular day is weighted as far as revenue is concerned.
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbr:number = undefined;
	/**
	 * The operation that was executed for the next business day calculation.
	 * Required: false
	 * Array: false
	 */
	nextBusinessDayOperationCd:NextBusinessDayOperationCd = undefined;
}
/**
 * Request to retrieve detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicPath {
	/**
	 * The code identifying a given service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response that contains detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicResp {
	/**
	 * The code identifying a given service center
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code identifying the component for the sic
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * address of service center
	 * Required: false
	 * Array: false
	 */
	address:Address = undefined;
	/**
	 * GIS lat long
	 * Required: false
	 * Array: false
	 */
	coordinates:LatLong = undefined;
	/**
	 * The offset of the timezone.
	 * Required: false
	 * Array: false
	 */
	timezoneOffset:number = undefined;
}
/**
 * Path to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicPath {
	/**
	 * The SIC code for a service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicQuery {
	/**
	 * The true/false indicator denoting if the zone should be returned.
	 * Required: false
	 * Array: false
	 */
	zoneInd:boolean = undefined;
	/**
	 * The true/false indicator denoting if the satellite should be returned.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
}
/**
 * Response to the request to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicResp {
	/**
	 * The SIC used in the search (same as the input SIC).
	 * Required: false
	 * Array: false
	 */
	refSic:string = undefined;
	/**
	 * The region for the import SIC (host region for the sic).
	 * Required: false
	 * Array: false
	 */
	refRegionSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a zone.
	 * Required: false
	 * Array: false
	 */
	refZoneSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a satellite.
	 * Required: false
	 * Array: false
	 */
	refSatelliteSic:string = undefined;
	/**
	 * The SIC code for a service center.
	 * Required: false
	 * Array: true
	 */
	zoneSatelliteInfo:ZoneSatelliteInfo[] = undefined;
}
/**
 * Path parameter used to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeaturePath {
	/**
	 * The code that represents the feature for which details are to be retrieved.
	 * Required: true
	 * Array: false
	 */
	featureCd:string = undefined;
}
/**
 * Query parameter used to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeatureQuery {
	/**
	 * The value attached to the specific feature. For example - 'Y'.
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
}
/**
 * Response to the request to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeatureResp {
	/**
	 * A list of location features associated with the feature code and optionally the feature value.
	 * Required: false
	 * Array: true
	 */
	locationFeatures:FeatureSetting[] = undefined;
}
/**
 * Query parameters used to list operational calendar days.
 */
export class ListOperationalCalendarDaysQuery {
	/**
	 * The beginning date to check for a work day or non-working day.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The end date to check for a work day or non-working day.
	 * Required: false
	 * Array: false
	 */
	endDate:string = undefined;
	/**
	 * The code associated with the type of revenue day.  Valid values: R (Revenue day), W (Weekend day), and H (Holiday).
	 * Required: false
	 * Array: false
	 */
	revenueDayCd:string = undefined;
	/**
	 * For Revenue use 1.0 and 0 for Weekend and Holiday.
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbrStr:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list the operational calendard days that match the filter criteria.
 */
export class ListOperationalCalendarDaysResp {
	/**
	 * The list of operation caldendar days which match the filter criteria.
	 * Required: false
	 * Array: true
	 */
	operationalCalendarDays:LocationOperationalCalendar[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Details about an active service center.
 */
export class ActiveSic {
	/**
	 * The code associated with the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code associated with the group that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * The region that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * The ciity for service center.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * The offset of the service center.
	 * Required: false
	 * Array: false
	 */
	serviceCenterOffset:string = undefined;
	/**
	 * The state for the service center.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * Returns 1 if SIC is a FAC.
	 * Required: false
	 * Array: false
	 */
	isFac:number = undefined;
	/**
	 * Returns 1 if SIC is a OB.
	 * Required: false
	 * Array: false
	 */
	isOb:number = undefined;
	/**
	 * A description for the location.
	 * Required: false
	 * Array: false
	 */
	sicDescription:string = undefined;
}
/**
 * Details about a zone/satellite which is linked to a host SIC.
 */
export class ChildSic {
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name of the service center. E.g. Portland.
	 * Required: false
	 * Array: false
	 */
	sicName:string = undefined;
	/**
	 * The category (zone or satellite) of the child service center in the operational hierarchy group.
	 * Required: false
	 * Array: false
	 */
	sicCategory:string = undefined;
	/**
	 * The list of grandchild operational service centers. They may be zones/satellites.
	 * Required: false
	 * Array: true
	 */
	grandchildSics:GrandchildSic[] = undefined;
}
/**
 * District represents a specific geographical location under a Region which is composed of a group of SIC codes. Examples: Chicago, Detroit, Milwaukee etc
 */
export class District {
	/**
	 * The code use as an identifier on the database.
	 * Required: false
	 * Array: false
	 */
	districtCd:string = undefined;
	/**
	 * The name of the District. Example: Chicago, Detroit, Milwaukee, etc.
	 * Required: false
	 * Array: false
	 */
	districtName:string = undefined;
	/**
	 * This district category in the operational hierarchy group.
	 * Required: false
	 * Array: false
	 */
	districtCategory:string = undefined;
	/**
	 * A list of the SIC codes that belongs to that District.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
	/**
	 * A list of host SICs which include zones/satellites that are linked to them.
	 * Required: false
	 * Array: true
	 */
	hostSics:HostSic[] = undefined;
}
/**
 * Details about a geo area.
 */
export class GeoArea {
	/**
	 * The unique identifier for the geo area.
	 * Required: false
	 * Array: false
	 */
	areaInstId:string = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaName:string = undefined;
	/**
	 * The description for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaDesc:string = undefined;
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: false
	 * Array: false
	 */
	polygonTypeCd:PolygonTypeCd = undefined;
	/**
	 * The coordinates for the locations.
	 * Required: false
	 * Array: true
	 */
	polygon:LatLong[] = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details about a zone/satellite that is linked to a zone which is linked to a host SIC.
 */
export class GrandchildSic {
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name of service center. E.g. Portland.
	 * Required: false
	 * Array: false
	 */
	sicName:string = undefined;
	/**
	 * The category (zone or satellite) of the service center in the operational hierarchy group.
	 * Required: false
	 * Array: false
	 */
	sicCategory:string = undefined;
}
/**
 * Details about a host SIC which has zones/satellites linked to it.
 */
export class HostSic {
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name of service center. E.g. Portland.
	 * Required: false
	 * Array: false
	 */
	sicName:string = undefined;
	/**
	 * The category (host, zone, or satellite) of the service center in the operational hierarchy group.
	 * Required: false
	 * Array: false
	 */
	sicCategory:string = undefined;
	/**
	 * The list of child operational service centers. They may be zones/satellites.
	 * Required: false
	 * Array: true
	 */
	childSics:ChildSic[] = undefined;
}
/**
 * Details about a linehaul region, which is a location group code.
 */
export class LocationGroupCode {
	/**
	 * The region
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The list of sic code that belongs to that region.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
}
/**
 * Details about an active service center.
 */
export class LocationSic {
	/**
	 * The code associated with the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code associated with the group that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * The region that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * The ciity for service center.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * The offset of the service center.
	 * Required: false
	 * Array: false
	 */
	serviceCenterOffset:string = undefined;
	/**
	 * The state for the service center.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * Returns 1 if SIC is a FAC.
	 * Required: false
	 * Array: false
	 */
	isFac:number = undefined;
	/**
	 * Returns 1 if SIC is a OB.
	 * Required: false
	 * Array: false
	 */
	isOb:number = undefined;
	/**
	 * A description for the location.
	 * Required: false
	 * Array: false
	 */
	sicDescription:string = undefined;
	/**
	 * The SIC of the location hosting this satellite.
	 * Required: false
	 * Array: false
	 */
	parentSatelliteSic:string = undefined;
	/**
	 * The SIC of the service center that hosts the zone.
	 * Required: false
	 * Array: false
	 */
	zoneTerminalSic:string = undefined;
	/**
	 * Indicator to identify this SIC as a satellite.
	 * Required: false
	 * Array: false
	 */
	aSatelliteSic:boolean = undefined;
	/**
	 * Indicator to identify this SIC as a zone.
	 * Required: false
	 * Array: false
	 */
	aZoneSic:boolean = undefined;
}
/**
 * Operational Region represents a geographical area by which the Operations are divided in the organization and is composed of Districts.
 */
export class OperationalRegion {
	/**
	 * The code use as an identifier on the database.
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The name of the Operational Region. Example: Eastern, Mid-West, and Western.
	 * Required: false
	 * Array: false
	 */
	regionName:string = undefined;
	/**
	 * The region category in the operational hierarchy group.
	 * Required: false
	 * Array: false
	 */
	regionCategory:string = undefined;
	/**
	 * A list of the Districts along with the SICs that are part of it.
	 * Required: false
	 * Array: true
	 */
	districts:District[] = undefined;
}
/**
 * Details about postal code and SIC associations.
 */
export class PostalSicAssignment {
	/**
	 * Location Id code
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Postal Code associated with the Sic Code
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * US state code or CA province code
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 2 character country code
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * If set to true then the Postal Code is a direct point
	 * Required: false
	 * Array: false
	 */
	directPointInd:boolean = undefined;
	/**
	 * If set to true then the Rapid Remote Service (RRS) is available
	 * Required: false
	 * Array: false
	 */
	rrsServiceInd:boolean = undefined;
	/**
	 * The service center identification code for the host location for the zone.
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
}
/**
 * Details about a service center.
 */
export class ServiceCenter {
	/**
	 * Address infomration for the service center.
	 * Required: false
	 * Array: false
	 */
	locAddress:LocationAddress = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Reference details about a service center.
	 * Required: false
	 * Array: false
	 */
	reference:LocationReference = undefined;
}
/**
 * Details about zone and satellite.
 */
export class ZoneSatelliteInfo {
	/**
	 * The SIC code for the region.
	 * Required: false
	 * Array: false
	 */
	regionSic:string = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * The parent satellite.
	 * Required: false
	 * Array: false
	 */
	satelliteParent:string = undefined;
	/**
	 * The zone location of the terminal.
	 * Required: false
	 * Array: false
	 */
	zoneTerminal:string = undefined;
}
/**
 * 
 */
export class AreaAssignment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rsnbleDisplayTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryRouteDepartTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupOnlyInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CompanyOperations {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyNameCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	menloOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rsiOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityOperationsFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	clasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operations24HrFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dcmntImageFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayToFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtFreightType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dir:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facHrsOfOpnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightOperationsFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightServiceAreaFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightServiceAreaType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fuelFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hstedSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedToFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhDayMeetFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhNightMeetFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rrSpurFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safetyClasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	satlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentOriginDestinationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceSatlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termProdFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termStmsLocationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tieFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	workDoorCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	warmRoomFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	yardMeetAndTurnFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightLtlMovementFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightLtlMaintFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	maintTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milemakerSplcNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ConWayCorporation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cnwyCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmptCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmptName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyLogoCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	glCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltCorp3CharName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sobCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltT60MajCorp:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyNameCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Door {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displaySeqNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbrTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hzntlCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	vrtclCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sectorId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupOnlyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dtlCapxTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replLstUpdtTmst:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlTmst:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DpndtCityOperationsLnk {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parntSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	childSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtFreightServiceDayCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class FeatureSetting {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	settingValueType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class GroupMember {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HierarchyCategory {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCategoryName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HierarchyGroup {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupRefSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltNameA:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltNameB:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltRgnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HrchyGroupMbr {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupRefSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LoadPlan {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	firstFacSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mustClearSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	secondFacSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdFacSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dayFreightInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originRailSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationPowerSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	roomSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thruSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	meetSic:string = undefined;
}
/**
 * 
 */
export class LocFreightPostalArea {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoPostalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightPostalAreaEffDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dirFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
}
/**
 * 
 */
export class LocOperationsSvccProfitability {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtPdManifestCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundAvgLoadedWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundManPowerLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundPpldLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundPpldCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prideStudyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupConfrmInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeVolumeThrshLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	laserPrtr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	linePrtr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtObManifestCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtDsrLastCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadPlanInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	smart4RolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sctrBalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrFacInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrFacRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrInboundInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrInboundRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoptDsrTripCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mimsOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mimsRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockOperationsRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundArrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundArrRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	avgOutboundDensity:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	avgOutboundPceVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationAddress {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationAddressEffDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altAddressType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	boxNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryInstructionDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nm1Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nm2Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prmyAddressFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalExtCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlCntr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlEnvs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCodedFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCertifiedFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationNonWeekDay {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nonWorkDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessUnitCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	linehaulInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pndStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationOperationalCalendar {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	calendarDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	revenueDayCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	caNonWeekInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	usNonWeekInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	financialReportDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationReference {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpOfcFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dstFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finScndFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsBgnDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsEndDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffset:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physLocationType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prvtFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicScpType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltPrimarySequence:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltTermAbbrvName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	kronFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lseExpiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ownOrLseCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltStateAbbrv:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffsetInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	slsSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timezoneName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mrcCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldXrefCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mileageFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rgnSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safetyClasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLatd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLngt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpCompanyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpProfitCenterCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpDepartmentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Sic {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
}


