import {SalvageRequestStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class SalvageRequestStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SalvageRequestStatusCd ) {
      this._values.push(SalvageRequestStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SalvageRequestStatusCd {

    for ( const obj in SalvageRequestStatusCd ) {
      if (SalvageRequestStatusCd[obj] === value){
        return SalvageRequestStatusCd[obj] as SalvageRequestStatusCd;
      }
    }
  }
}

const SalvageRequestStatusCdHelper = new SalvageRequestStatusCdHelperEnumHelperClass();
export default SalvageRequestStatusCdHelper;
