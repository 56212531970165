import {CrcAuditCd} from '../';
import {EnumHelper} from './enum-helper';

export class CrcAuditCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CrcAuditCd ) {
      this._values.push(CrcAuditCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CrcAuditCd {

    for ( const obj in CrcAuditCd ) {
      if (CrcAuditCd[obj] === value){
        return CrcAuditCd[obj] as CrcAuditCd;
      }
    }
  }
}

const CrcAuditCdHelper = new CrcAuditCdHelperEnumHelperClass();
export default CrcAuditCdHelper;
