import {MetricPredictionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricPredictionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricPredictionTypeCd ) {
      this._values.push(MetricPredictionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricPredictionTypeCd {

    for ( const obj in MetricPredictionTypeCd ) {
      if (MetricPredictionTypeCd[obj] === value){
        return MetricPredictionTypeCd[obj] as MetricPredictionTypeCd;
      }
    }
  }
}

const MetricPredictionTypeCdHelper = new MetricPredictionTypeCdHelperEnumHelperClass();
export default MetricPredictionTypeCdHelper;
