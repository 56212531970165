import {CarrierLocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CarrierLocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CarrierLocationTypeCd ) {
      this._values.push(CarrierLocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CarrierLocationTypeCd {

    for ( const obj in CarrierLocationTypeCd ) {
      if (CarrierLocationTypeCd[obj] === value){
        return CarrierLocationTypeCd[obj] as CarrierLocationTypeCd;
      }
    }
  }
}

const CarrierLocationTypeCdHelper = new CarrierLocationTypeCdHelperEnumHelperClass();
export default CarrierLocationTypeCdHelper;
