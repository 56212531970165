
export enum OptimizerParameterCd {
	EQPCOSTMULTIPLIER = 'EQPCOSTMULTIPLIER',
	EQPCPH = 'EQPCPH',
	EQPCPHAFTERSOFTENDTM = 'EQPCPHAFTERSOFTENDTM',
	EQPCPHAFTERSOFTMAX = 'EQPCPHAFTERSOFTMAX',
	EQPCPHBFSOFTSTARTTM = 'EQPCPHBFSOFTSTARTTM',
	EQPCPKM = 'EQPCPKM',
	EQPCPUAFTRSOFTMXLOAD = 'EQPCPUAFTRSOFTMXLOAD',
	EQPCSTAFTRSOFTENDTM = 'EQPCSTAFTRSOFTENDTM',
	EQPFXCOST = 'EQPFXCOST',
	EQPLOADFACTOR = 'EQPLOADFACTOR',
	EQPMAXSOFTDURINSS = 'EQPMAXSOFTDURINSS',
	EQPPKUPCAPACITYLB = 'EQPPKUPCAPACITYLB',
	EQPPKUPCAPACITYPUP = 'EQPPKUPCAPACITYPUP',
	EQPRTDURINSS = 'EQPRTDURINSS',
	EQPSOFTENDTM = 'EQPSOFTENDTM',
	EQPSOFTSTARTENDTIME = 'EQPSOFTSTARTENDTIME',
	EQPSOFTSTARTTIME = 'EQPSOFTSTARTTIME',
	EQPSTARTENDTIME = 'EQPSTARTENDTIME',
	EQPTRVLDURMULTIPLE = 'EQPTRVLDURMULTIPLE',
	OPZCSTPERKMNXTPKUP = 'OPZCSTPERKMNXTPKUP',
	OPZDISTANCELIMIT = 'OPZDISTANCELIMIT',
	OPZDISTLMTNXTPKUP = 'OPZDISTLMTNXTPKUP',
	OPZDSTNCLMTCOSTPKM = 'OPZDSTNCLMTCOSTPKM',
	OPZEXPARM_10 = 'OPZEXPARM10',
	OPZGBLENDTM = 'OPZGBLENDTM',
	OPZGBLSTARTTM = 'OPZGBLSTARTTM',
	OPZGRPDELAYDUR_0 = 'OPZGRPDELAYDUR0',
	OPZGRPDELAYDUR_1 = 'OPZGRPDELAYDUR1',
	OPZGRPDELAYDUR_2 = 'OPZGRPDELAYDUR2',
	OPZINCLUDEPICKUPS = 'OPZINCLUDEPICKUPS',
	OPZMAXSEARCHLMT = 'OPZMAXSEARCHLMT',
	OPZPKUPFACTOR = 'OPZPKUPFACTOR',
	OPZPRIMRY = 'OPZPRIMRY',
	OPZTMOUT = 'OPZTMOUT',
	OPZVISITTYPEFXCOST = 'OPZVISITTYPEFXCOST',
	OPZVISITTYPEKMCOST = 'OPZVISITTYPEKMCOST',
	SEQ_VAR_THRESHOLD = 'SEQ_VAR_THRESHOLD',
	SHMCPH_AFTER_STARTTM = 'SHMCPH_AFTER_STARTTM',
	SHMCPH_B_4_STARTTM = 'SHMCPH_B4_STARTTM',
	SHMDLVRSOFTENDTM = 'SHMDLVRSOFTENDTM',
	SHMLOCK_STATCD = 'SHMLOCK_STATCD',
	SHMPKUPSTARTTM = 'SHMPKUPSTARTTM',
	SHMPRPTM = 'SHMPRPTM'}

