import {CustomerAddressValidationCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerAddressValidationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerAddressValidationCd ) {
      this._values.push(CustomerAddressValidationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerAddressValidationCd {

    for ( const obj in CustomerAddressValidationCd ) {
      if (CustomerAddressValidationCd[obj] === value){
        return CustomerAddressValidationCd[obj] as CustomerAddressValidationCd;
      }
    }
  }
}

const CustomerAddressValidationCdHelper = new CustomerAddressValidationCdHelperEnumHelperClass();
export default CustomerAddressValidationCdHelper;
