import {CarrierDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class CarrierDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CarrierDetailCd ) {
      this._values.push(CarrierDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CarrierDetailCd {

    for ( const obj in CarrierDetailCd ) {
      if (CarrierDetailCd[obj] === value){
        return CarrierDetailCd[obj] as CarrierDetailCd;
      }
    }
  }
}

const CarrierDetailCdHelper = new CarrierDetailCdHelperEnumHelperClass();
export default CarrierDetailCdHelper;
