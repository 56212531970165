import {SalvageRequestTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class SalvageRequestTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SalvageRequestTypeCd ) {
      this._values.push(SalvageRequestTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SalvageRequestTypeCd {

    for ( const obj in SalvageRequestTypeCd ) {
      if (SalvageRequestTypeCd[obj] === value){
        return SalvageRequestTypeCd[obj] as SalvageRequestTypeCd;
      }
    }
  }
}

const SalvageRequestTypeCdHelper = new SalvageRequestTypeCdHelperEnumHelperClass();
export default SalvageRequestTypeCdHelper;
