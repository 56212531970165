import {DockOperationsReportTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DockOperationsReportTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DockOperationsReportTypeCd ) {
      this._values.push(DockOperationsReportTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DockOperationsReportTypeCd {

    for ( const obj in DockOperationsReportTypeCd ) {
      if (DockOperationsReportTypeCd[obj] === value){
        return DockOperationsReportTypeCd[obj] as DockOperationsReportTypeCd;
      }
    }
  }
}

const DockOperationsReportTypeCdHelper = new DockOperationsReportTypeCdHelperEnumHelperClass();
export default DockOperationsReportTypeCdHelper;
