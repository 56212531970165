import {CorrectionQueueNameCd} from '../';
import {EnumHelper} from './enum-helper';

export class CorrectionQueueNameCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CorrectionQueueNameCd ) {
      this._values.push(CorrectionQueueNameCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CorrectionQueueNameCd {

    for ( const obj in CorrectionQueueNameCd ) {
      if (CorrectionQueueNameCd[obj] === value){
        return CorrectionQueueNameCd[obj] as CorrectionQueueNameCd;
      }
    }
  }
}

const CorrectionQueueNameCdHelper = new CorrectionQueueNameCdHelperEnumHelperClass();
export default CorrectionQueueNameCdHelper;
