import {TrailerLoadedUnitDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class TrailerLoadedUnitDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TrailerLoadedUnitDetailCd ) {
      this._values.push(TrailerLoadedUnitDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TrailerLoadedUnitDetailCd {

    for ( const obj in TrailerLoadedUnitDetailCd ) {
      if (TrailerLoadedUnitDetailCd[obj] === value){
        return TrailerLoadedUnitDetailCd[obj] as TrailerLoadedUnitDetailCd;
      }
    }
  }
}

const TrailerLoadedUnitDetailCdHelper = new TrailerLoadedUnitDetailCdHelperEnumHelperClass();
export default TrailerLoadedUnitDetailCdHelper;
