import {DirectedLoadingLaneTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DirectedLoadingLaneTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DirectedLoadingLaneTypeCd ) {
      this._values.push(DirectedLoadingLaneTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DirectedLoadingLaneTypeCd {

    for ( const obj in DirectedLoadingLaneTypeCd ) {
      if (DirectedLoadingLaneTypeCd[obj] === value){
        return DirectedLoadingLaneTypeCd[obj] as DirectedLoadingLaneTypeCd;
      }
    }
  }
}

const DirectedLoadingLaneTypeCdHelper = new DirectedLoadingLaneTypeCdHelperEnumHelperClass();
export default DirectedLoadingLaneTypeCdHelper;
