import {ActivityStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ActivityStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ActivityStatusCd ) {
      this._values.push(ActivityStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ActivityStatusCd {

    for ( const obj in ActivityStatusCd ) {
      if (ActivityStatusCd[obj] === value){
        return ActivityStatusCd[obj] as ActivityStatusCd;
      }
    }
  }
}

const ActivityStatusCdHelper = new ActivityStatusCdHelperEnumHelperClass();
export default ActivityStatusCdHelper;
