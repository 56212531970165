import {CustomerNotificationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerNotificationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerNotificationTypeCd ) {
      this._values.push(CustomerNotificationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerNotificationTypeCd {

    for ( const obj in CustomerNotificationTypeCd ) {
      if (CustomerNotificationTypeCd[obj] === value){
        return CustomerNotificationTypeCd[obj] as CustomerNotificationTypeCd;
      }
    }
  }
}

const CustomerNotificationTypeCdHelper = new CustomerNotificationTypeCdHelperEnumHelperClass();
export default CustomerNotificationTypeCdHelper;
