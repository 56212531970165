
export enum EquipmentStatusCd {
	APPOINTMENT = 'Appointment',
	ARRIVAL = 'Arrival',
	AVAILABLE = 'Available',
	CITY = 'City',
	CITY_ARRIVAL = 'CityArrival',
	CITY_LOADING_AT_DOCK = 'CityLoadingAtDock',
	CLOSE = 'Close',
	CLOSED_FOR_DELIVERY = 'ClosedForDelivery',
	DELAYED = 'Delayed',
	DISPATCHED = 'Dispatched',
	DROP = 'Drop',
	EMPTY = 'Empty',
	ENROUTE = 'Enroute',
	HELD = 'Held',
	LOADING_AT_CUSTOMER = 'LoadingAtCustomer',
	LOADING_AT_DOCK = 'LoadingAtDock',
	OUT_OF_SERVICE = 'OutOfService',
	OVERHEAD = 'Overhead',
	PICKUP_AND_DELIVERY_ROUTE = 'PickupAndDeliveryRoute',
	TRAP = 'Trap',
	UNLOADING_AT_CUSTOMER = 'UnloadingAtCustomer',
	UNLOADING_AT_DOCK = 'UnloadingAtDock'}

