
export enum LicenseEquipmentTypeCd {
	CONVERT_GEAR = 'ConvertGear',
	EMPTY = 'Empty',
	HOSTLING_YARD_TRACTOR = 'HostlingYardTractor',
	PASSENGER_CAR = 'PassengerCar',
	SEMI_TRAILER = 'SemiTrailer',
	TRACTOR = 'Tractor',
	TRUCK = 'Truck'}

