import {DeliveryDateTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeliveryDateTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeliveryDateTypeCd ) {
      this._values.push(DeliveryDateTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeliveryDateTypeCd {

    for ( const obj in DeliveryDateTypeCd ) {
      if (DeliveryDateTypeCd[obj] === value){
        return DeliveryDateTypeCd[obj] as DeliveryDateTypeCd;
      }
    }
  }
}

const DeliveryDateTypeCdHelper = new DeliveryDateTypeCdHelperEnumHelperClass();
export default DeliveryDateTypeCdHelper;
