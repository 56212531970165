import {PickupRequestReasonCategoryRefCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupRequestReasonCategoryRefCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupRequestReasonCategoryRefCd ) {
      this._values.push(PickupRequestReasonCategoryRefCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupRequestReasonCategoryRefCd {

    for ( const obj in PickupRequestReasonCategoryRefCd ) {
      if (PickupRequestReasonCategoryRefCd[obj] === value){
        return PickupRequestReasonCategoryRefCd[obj] as PickupRequestReasonCategoryRefCd;
      }
    }
  }
}

const PickupRequestReasonCategoryRefCdHelper = new PickupRequestReasonCategoryRefCdHelperEnumHelperClass();
export default PickupRequestReasonCategoryRefCdHelper;
