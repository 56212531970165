
export enum ScheduleStatusCd {
	ARRIVED = 'Arrived',
	COMPLETED = 'Completed',
	DELAYED = 'Delayed',
	DISPATCHED = 'Dispatched',
	ENROUTE = 'Enroute',
	HELD = 'Held',
	NOT_APPLICABLE = 'NotApplicable',
	PLAN = 'Plan',
	TERMINATED = 'Terminated'}

