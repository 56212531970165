import {ApprovalStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApprovalStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApprovalStatusCd ) {
      this._values.push(ApprovalStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApprovalStatusCd {

    for ( const obj in ApprovalStatusCd ) {
      if (ApprovalStatusCd[obj] === value){
        return ApprovalStatusCd[obj] as ApprovalStatusCd;
      }
    }
  }
}

const ApprovalStatusCdHelper = new ApprovalStatusCdHelperEnumHelperClass();
export default ApprovalStatusCdHelper;
