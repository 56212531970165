import {CmsTripTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CmsTripTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CmsTripTypeCd ) {
      this._values.push(CmsTripTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CmsTripTypeCd {

    for ( const obj in CmsTripTypeCd ) {
      if (CmsTripTypeCd[obj] === value){
        return CmsTripTypeCd[obj] as CmsTripTypeCd;
      }
    }
  }
}

const CmsTripTypeCdHelper = new CmsTripTypeCdHelperEnumHelperClass();
export default CmsTripTypeCdHelper;
