import {CustomerNotificationActionFormatCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerNotificationActionFormatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerNotificationActionFormatCd ) {
      this._values.push(CustomerNotificationActionFormatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerNotificationActionFormatCd {

    for ( const obj in CustomerNotificationActionFormatCd ) {
      if (CustomerNotificationActionFormatCd[obj] === value){
        return CustomerNotificationActionFormatCd[obj] as CustomerNotificationActionFormatCd;
      }
    }
  }
}

const CustomerNotificationActionFormatCdHelper = new CustomerNotificationActionFormatCdHelperEnumHelperClass();
export default CustomerNotificationActionFormatCdHelper;
