import {DirectedLoadingExceptionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DirectedLoadingExceptionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DirectedLoadingExceptionTypeCd ) {
      this._values.push(DirectedLoadingExceptionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DirectedLoadingExceptionTypeCd {

    for ( const obj in DirectedLoadingExceptionTypeCd ) {
      if (DirectedLoadingExceptionTypeCd[obj] === value){
        return DirectedLoadingExceptionTypeCd[obj] as DirectedLoadingExceptionTypeCd;
      }
    }
  }
}

const DirectedLoadingExceptionTypeCdHelper = new DirectedLoadingExceptionTypeCdHelperEnumHelperClass();
export default DirectedLoadingExceptionTypeCdHelper;
