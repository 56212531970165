import {DeliveryShipmentIndexCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeliveryShipmentIndexCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeliveryShipmentIndexCd ) {
      this._values.push(DeliveryShipmentIndexCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeliveryShipmentIndexCd {

    for ( const obj in DeliveryShipmentIndexCd ) {
      if (DeliveryShipmentIndexCd[obj] === value){
        return DeliveryShipmentIndexCd[obj] as DeliveryShipmentIndexCd;
      }
    }
  }
}

const DeliveryShipmentIndexCdHelper = new DeliveryShipmentIndexCdHelperEnumHelperClass();
export default DeliveryShipmentIndexCdHelper;
