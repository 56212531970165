import {RatingQueueClassTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RatingQueueClassTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RatingQueueClassTypeCd ) {
      this._values.push(RatingQueueClassTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RatingQueueClassTypeCd {

    for ( const obj in RatingQueueClassTypeCd ) {
      if (RatingQueueClassTypeCd[obj] === value){
        return RatingQueueClassTypeCd[obj] as RatingQueueClassTypeCd;
      }
    }
  }
}

const RatingQueueClassTypeCdHelper = new RatingQueueClassTypeCdHelperEnumHelperClass();
export default RatingQueueClassTypeCdHelper;
