
export enum TripDetailCd {
	ACTIVITIES = 'Activities',
	CUSTOMER = 'Customer',
	DISPATCH_GROUPS = 'DispatchGroups',
	DRIVER = 'Driver',
	DROP_LOCATIONS = 'DropLocations',
	EQUIPMENT = 'Equipment',
	HANDHELD_USE = 'HandheldUse',
	METRICS = 'Metrics',
	NOTES = 'Notes',
	ROUTES = 'Routes',
	SINGLE_STOP_WITH_EDT = 'SingleStopWithEDT',
	SPECIAL_SERVICES = 'SpecialServices',
	STOPS = 'Stops',
	TRIP = 'Trip',
	TRIP_HIST = 'TripHist',
	WINDOWS = 'Windows'}

