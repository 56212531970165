import {FbdsPrintCountCd} from '../';
import {EnumHelper} from './enum-helper';

export class FbdsPrintCountCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FbdsPrintCountCd ) {
      this._values.push(FbdsPrintCountCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FbdsPrintCountCd {

    for ( const obj in FbdsPrintCountCd ) {
      if (FbdsPrintCountCd[obj] === value){
        return FbdsPrintCountCd[obj] as FbdsPrintCountCd;
      }
    }
  }
}

const FbdsPrintCountCdHelper = new FbdsPrintCountCdHelperEnumHelperClass();
export default FbdsPrintCountCdHelper;
