
export enum DisputeStatusCd {
	AMENDED = 'Amended',
	APPROVED = 'Approved',
	CANCELLED = 'Cancelled',
	CLOSED = 'Closed',
	COLLECTIONS_ESCALATION_UNDER_REVIEW = 'CollectionsEscalationUnderReview',
	CORRECTIONS_UNDER_PROGRESS = 'CorrectionsUnderProgress',
	DECLINED = 'Declined',
	DELETED = 'Deleted',
	DRAFT = 'Draft',
	PENDING_APPROVAL = 'PendingApproval',
	PENDING_DIRECTOR_APPROVAL = 'PendingDirectorApproval',
	PENDING_FINANCE_APPROVAL = 'PendingFinanceApproval',
	PENDING_SUPERVISOR_APPROVAL = 'PendingSupervisorApproval',
	PENDING_SVP_APPROVAL = 'PendingSVPApproval',
	PENDING_VPS_APPROVAL = 'PendingVPSApproval',
	PENDING_VP_APPROVAL = 'PendingVPApproval',
	RERATE_APPROVED = 'RerateApproved',
	SUBMITTED = 'Submitted',
	SUBMITTED_FOR_REVIEW = 'SubmittedForReview',
	UNASSIGNED = 'Unassigned',
	UNDER_REVIEW = 'UnderReview',
	WHAT_IF_ANALYSIS_IN_PROGRESS = 'WhatIfAnalysisInProgress'}

