import {PnDReportTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PnDReportTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PnDReportTypeCd ) {
      this._values.push(PnDReportTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PnDReportTypeCd {

    for ( const obj in PnDReportTypeCd ) {
      if (PnDReportTypeCd[obj] === value){
        return PnDReportTypeCd[obj] as PnDReportTypeCd;
      }
    }
  }
}

const PnDReportTypeCdHelper = new PnDReportTypeCdHelperEnumHelperClass();
export default PnDReportTypeCdHelper;
